/* eslint-disable no-console */
import { AddUserTypes,LocalUserListTypes,LocalUserTableTypes,LocalUserBodyTypes,LocalUserRowTypes,RoleArray } from "./Types";
import React from 'react';
import ConnectorComponent from '../contribute/ConnectorComponent';
import {HeaderRow} from './Tables';
import {usernameIsValidEmail as isValidEmail,passwordInputIsValid} from '../../services/Validation';
import {buildRequest} from '../../services/Fetcher';
import {endpoints} from '../../config/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AddUser extends React.Component
{
    constructor(props) {
        super(props);
        this.state={
            wasValidated:false,
            email:null,
            pwd:null,
            pwdRepeat:null,
            wasUserCreated:null,
            technicalError:null
        };
        this.formElement = null;
        
    }

    isFormValid = () => {
        const isValid = this.formElement.checkValidity();
        this.setState( { isFormValid: isValid, wasValidated: true } );
        return isValid;
    }

    handleFormSubmit = ( event ) =>{
        event.preventDefault();
        event.stopPropagation();
        const {connector} = this.props;
        const localUser ={
            identification:this.state.email,
            email:this.state.email,
            password:this.state.pwd
        };
        const {pwdRepeat}= this.state;
         
        const isValid = this.isFormValid();
        if ( isValid === true ) {
            if (isValidEmail(localUser.email)) {
                if (passwordInputIsValid(localUser.password,pwdRepeat)) {
                    connector.send( buildRequest( endpoints.administration.localUser.create.url(), endpoints.administration.localUser.create.method(), true,localUser ),
                    ( json ) => {
                        if ( this.props.connector.is200() ){
                            this.setState({wasUserCreated:true,user:json,technicalError:null});
                        }
                        else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                          this.setState({wasUserCreated:false});
                          console.log(json);
                          if (json && json["userDto.password"]) { this.setState({ technicalError: json["userDto.password"] }); }
                          if (json && json["userDto.identification"]) { this.setState({ technicalError: json["userDto.identification"] }); }
                        } 
                    });
                }
                else{
                    this.setState({technicalError:"Passwörter stimmen nicht überein"});    
                }
            }
            else{
                this.setState({technicalError:"Email nicht valide"});
            }
        }

    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.id;
        const state = name;
        const value = target.value;
        this.setState({[state]:value});
    }
    renderError = (error) => {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>{this.props.t('userManagement.localuser.error')}:</strong> {error}
            </div>
        );
    }
    renderResults=(t)=>
    {
        return(
            <div>
                <div className="row">
                    <div className="col-md-3" >{t('userManagement.localuser.adduser.added')}</div>
                </div>
            </div>
        );
    }
   
    render() {
        const { t,connector } = this.props;
        const {wasValidated,isFormValid,wasUserCreated,technicalError} = this.state;
        const formCss = wasValidated === true && isFormValid === false ? "was-validated" : "";
        
        return (
            <div>
            <div className="card">
                <div className="card-body">
                    <h6 className="card-subtitle mb-2">{t('userManagement.localuser.adduser.header')}</h6>
                    <form ref={ (form) => this.formElement = form } onSubmit={ this.handleFormSubmit } noValidate className={formCss}>
                        <div className="form-group row">
                        <label className="col-md-2 col-form-label" htmlFor="email" >{t('userManagement.emailaddress')}:</label>
                            <div className="col-md-6">
                                <input type="email" className="form-control" id="email" onChange={this.handleInputChange} />
                            </div>
                        </div>

                        <div className="form-group row">
                        <label className="col-md-2 col-form-label" htmlFor="pwd" >{t('userManagement.password')}:</label>
                            <div className="col-md-6">
                                <input type="password" className="form-control" id="pwd" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        
                        <div className="form-group row">
                        <label className="col-md-2 col-form-label" htmlFor="pwdRepeat" >{t('userManagement.passwordrepeat')}:</label>
                            <div className="col-md-6">
                                <input type="password" className="form-control" id="pwdRepeat" onChange={this.handleInputChange}/>
                            </div>
                            <div className="col-md-4">
                                <button type="submit" className="btn btn-primary md-2 mb-3">{t('userManagement.submit')}</button>
                                <FontAwesomeIcon icon="info-circle" tabIndex="0" className="md-2 ml-2 mt-1" data-toggle="popover" data-trigger="focus" data-content={t('userManagement.localuser.adduser.tip')} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {technicalError ? this.renderError(technicalError) : null}
            {
                
                wasUserCreated===null?<div></div>:
                <ConnectorComponent t={t} connector={connector} isRenderChildren={()=>wasUserCreated===true}>
                    <div className="card mt-2">
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2">{t('userManagement.localuser.adduser.results.header')}</h6>
                            {this.renderResults(t)}
                        </div>
                    </div>
                </ConnectorComponent>
            }
            </div>
        );
    }
}
class LocalUserRow extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.value.id,
            identification: this.props.value.identification,
            role: this.props.value.role,
            email: this.props.value.email,
            isActivated:this.props.value.isActivated
        };
    }
    handleInputChange = (event, state) => {
        const target = event.target;
        //const name = target.id;
        //const state = name;
        
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [state]: value });
    }
    createLocalUserObject = () => {
        const user = {
            id: this.state.id,
            identification: this.state.identification,
            role: this.state.role,
            email: this.state.email,
            isActivated:this.state.isActivated
        };
        return user;
    }
    render() {
        const user = this.createLocalUserObject();
        const t = this.props.t;
        return (
            <tr>
                <td scope="col">
                    <input type="text" value={user.identification} disabled />
                </td>
                <td scope="col">
                    {//<select value={permission.kind} onChange={(event) => this.handleInputChange(event, 'kind')}>
                     //   {PermissionKindArray.map((it) => <option key={it} value={it}>{it}</option>)}
                    //</select>
                }
                <input type="text" value={user.email} onChange={(event) => this.handleInputChange(event, 'email')} />
                </td>
                <td scope="col">
                    <select value={user.role} onChange={(event) => this.handleInputChange(event, 'role')}>
                        {RoleArray.map((it) => <option key={it} value={it}>{t("userManagement." + it)}</option>)}
                    </select>
                </td>
                <td scope ="col">
                   <div align="center"> <input type="checkbox" onChange={(event) => this.handleInputChange(event, 'isActivated')} checked={user.isActivated===true}/></div>
                </td>
                <td scope="col">
                    <button type="button" onClick={() => this.props.update(user)} className="btn btn-warning btn-xs">
                        <FontAwesomeIcon icon="redo" />
                    </button>
                </td>
            </tr>
        );
    }
}
class LocalUserBody extends React.Component{
    constructor(props) {
        super(props);
    }

    onUpdate = (localuser) => {
        this.props.onLocalUserChanged(localuser, 'edit');
    }

    render() {

        const users = this.props.localUsers;
        
        const body = [];
        
        users.forEach(user => {
            body.push(
                <LocalUserRow key={user.id} value={user} t={this.props.t} update={this.onUpdate} />
            );
        });

        return (
            <tbody>
                {body}
            </tbody>
        );
    }
}
class LocalUserTable extends React.Component{
    constructor(props) {
        super(props);
    }
    changeRow = (user, change) => {
        this.props.onDataTableChange(user, change);
    }
    render() {
        return (
            <div>
                <table className="table table-striped" id={this.props.name}>
                    <HeaderRow row={localUserHeader} t={this.props.t}></HeaderRow>
                    <LocalUserBody t={this.props.t} localUsers={this.props.data} onLocalUserChanged={this.changeRow} />
                </table>
            </div>);
    }
}

class LocalUserList  extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            localusers:null,
            technicalError:null
        };
    }
    getLocalUsers=()=>
    {
        const {connector} = this.props;
        connector.send( buildRequest( endpoints.administration.localUser.url(), endpoints.administration.localUser.method(), true),
        ( json ) => {
            if ( this.props.connector.hasJson() && this.props.connector.is200() )
            {
                this.setState({localusers:json,dataAcquired:true});
            }
            else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
              this.setState({localusers:null,dataAcquired:false});
            } 
        });
    }

    renderError = (error) => {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>{this.props.t('userManagement.localuser.error')}:</strong> {error}
            </div>
        );
    }

    onDataTableChange = (row, change) => {
        const { connector } = this.props;

        let url, method, includeCredentials, id;
        switch (change) {
            //add entry
            //change entry
            case 'edit':
                url = endpoints.administration.updateUser.url;
                id = row.id;
                method = endpoints.administration.updateUser.method;
                includeCredentials = true;
                break;
            default:
        }
        connector.send(buildRequest(url(id), method(), includeCredentials, row),
            (json) => {
                if (this.props.connector.is200()) {console.log(json); this.getLocalUsers();this.setState({ technicalError:null }); }
                else if (connector.hasError() || (connector.hasResponse() && !connector.is200())) {
                    console.log(json);
                    //something went utterly wrong :-(
                    if (json && json["userDto.email"]) { this.setState({ technicalError: json["userDto.email"] }); }
                }
            }
        );
        }
    componentDidMount() {
        this.getLocalUsers();
        //this.prepareDataTable();
    }
    // prepareDataTable()
    // {
    //     $('#localusers').DataTable(
    //         {paging:true,
    //             data:this.state.localusers
    //         }
    //     );
    // }
   
    render()
    {
        const {t,connector} = this.props;
        const {localusers,dataAcquired,technicalError} = this.state;
        const tablename ="localuserlist";
        return (
            <div className="card">
            <div className="card-body col-md-6">
                <h6 className="card-subtitle mb-2">{t('userManagement.localuser.table.header')}</h6>
                {technicalError ? this.renderError(technicalError) : null}
                <ConnectorComponent t={t} connector={connector} isRenderChildren={()=>dataAcquired===true}>
                        <LocalUserTable data={localusers} name={tablename} onDataTableChange={this.onDataTableChange} t={t} />
                </ConnectorComponent>
                </div>
            </div>
        );
    }
}
const localUserHeader = ['identification', 'email', 'role','isActivated'];
LocalUserList.propTypes = LocalUserListTypes;
AddUser.propTypes = AddUserTypes;
LocalUserTable.propTypes = LocalUserTableTypes;
LocalUserBody.propTypes = LocalUserBodyTypes;
LocalUserRow.propTypes = LocalUserRowTypes;
export{
    AddUser,
    LocalUserList
};