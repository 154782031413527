/**
 * basic react module dependencies
 */
import React from 'react'
import { Row, Col } from 'reactstrap'

import { Connector, buildRequest } from '../../services/Fetcher'
import { endpoints } from '../../config/endpoints'

// language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
//import $ from 'jquery';
/**
 * custom cis related module dependencies
 */
import InterculturalExperienceCardBoard from './InterculturalExperienceCardBoard'
import { CategorizationData } from '../../domain/CategorizationData'
import SearchField from './Search'
import {FilterField,AppliedFilterField,FilterKind,FilterCategory} from './Filter'
import { buildQueryDto } from '../../domain/DataTransferObjects'
import { AuthHoc} from "../../services/AuthService"
/**
 * The component provides the functionality to show an overview of (all) intercultural experience.
 * 
 * @version 0.1.0
 * @since 1.0.0
 */

 
export class FilterExpression{
  constructor(category,values,kind)
  {
    this.category =category
    this.values = values
    this.kind = kind
  }
  
  hasValue(value)
  {
    return this.values.includes(value);
  }
  removeValue(value)
  {
    if(this.hasValue(value))
    {
      this.values.splice(this.values.indexOf(value),1);
    }
  }
}

export class Overview extends React.Component {

  /**
   * default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props)
    this.state = {
      connector: new Connector(),
      categorization: undefined,
      hits:undefined,
      //TODO: refactor to explicit object?
      isAuthor:undefined,
      isContributor:undefined,
      isTranscribed:undefined,
      mustBeTranscribed:false,
      isCheckRightAssignment:undefined,
      withReflection:undefined,
      mustBeWithReflection:false,
      mustBeWithIndividualCategorization:false,
      withIndividualCategorization:undefined,
      search:undefined,
      filterExpressions:[],
      isFilterOptionVisible:false,
      results:undefined,
      selectedCategory:FilterCategory[0],
      // stores errors in case categorization or experience to be edit could not be fetched from backend
      fetchError: [],
      user: {
        role: ''
      }
    }
  }

  componentDidMount() {
    // TODO: called both async functions after each other causing us trouble to display all errors that might happen in a consistent way!
    this.fetchCategorization()
    this.props.authService.addRoleChangeListener( this.reloadCategorization )
  }

  reloadCategorization=()=>{
    this.setState( { categorization: undefined }, this.fetchCategorization )
  }
  fetchCategorization() {
    this.state.connector.send(
        buildRequest( endpoints.categorization.url(), endpoints.categorization.method(), true ),
        ( json ) => {
            // async callback function to save the categorization data as part of the state
            const { connector, fetchError } = this.state
            if ( connector.hasJson() && connector.is200() ) {
                // update state to render active contribute step and perform validation if necessary or possible
                this.setState( { categorization: new CategorizationData( json ) }  )
            } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                // render occured errors
                this.setState( { fetchError: fetchError.concat( { code: connector.code(), isCategorization: true } ) } )
            }
        }
    )
  }

  /** Abort data fetching from backend or saving to backend. */
  componentWillUnmount() {
    if ( this.state.connector !== null ) this.state.connector.cancel()
      this.props.authService.removeRoleChangeListener( this.reloadCategorization )
  }
  
  // represents a search request, for title, story, reflexion
  handleSearchClicked = (searchParameters, event)=>  {
    this.setState({search:searchParameters},this.updateHitRequest);
    
  }
  
  handleAuthorChange =(isAuthor)=>
  {
    this.setState({isAuthor:isAuthor},this.updateHitRequest)
  }

  handleContributorChange = (isContributor)=>
  {
    this.setState({isContributor:isContributor},this.updateHitRequest)
  }

  handleTranscribeChange=(isTranscribed)=>
  {
    this.setState({isTranscribed:isTranscribed},this.updateHitRequest)
  }
  handleMustBeTranscribeChange=(mustBeTranscribed)=>
  {
    this.setState({mustBeTranscribed:mustBeTranscribed},this.updateHitRequest)
  }
  handleWithReflectionChange=(withReflection)=>{
    this.setState({withReflection:withReflection},this.updateHitRequest)
  }
  handleMustBeWithReflectionChange=(mustBeWithReflection)=>{
    this.setState({mustBeWithReflection:mustBeWithReflection},this.updateHitRequest)
  }
  handleMustBeWithIndividualCategorizationChange=(mustBeWithIndividualCategorization)=>{
    this.setState({mustBeWithIndividualCategorization:mustBeWithIndividualCategorization},this.updateHitRequest)
  }
  handleIndividualCategorizationChange=(withIndividualCategorization)=>{
    this.setState({withIndividualCategorization:withIndividualCategorization},this.updateHitRequest)
  }
  handleCheckRightAssignmentFilterChange=(isCheckRightAssignment)=>{
    this.setState({isCheckRightAssignment:isCheckRightAssignment},this.updateHitRequest)
  }

  // represents a filter request, right now only useful for simple expressions
  updateHitRequest=()=>{
    const dto = buildQueryDto(this.state)
    const {connector} = this.state;
    connector.send(
      buildRequest(endpoints.experiences.query.url(true),endpoints.experiences.query.method(false),true,dto),
      (json)=>{
        if ( connector.hasJson() && connector.is200() ) {
          this.setState({hits:json.count})
        }
        else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
          //TODO:handle error
          this.setState({hits:0})
        }
      }
    )
  }

  //there should be only one filterexpression for each filterable category
  handleNewFilter=(filterCategory,filterValues,filterKind)=>{
    let filterExpressions=[];
    let expression = this.state.filterExpressions.find(filterexpression=>filterexpression['category']===filterCategory);
    //no filter of this category?
    if(expression===undefined)
    {
      filterExpressions = this.state.filterExpressions.concat(new FilterExpression(filterCategory,filterValues,filterKind));
    }
    else
    {
      filterExpressions = this.state.filterExpressions.map(expression=>
        {
          switch(expression.kind)
          {
            case FilterKind.MultiValue:
              //append the value if not existent
              if(filterCategory===expression.category)
              {
                const difference = filterValues.filter(a=>!expression.values.find(b=>(a.id===b.id)
                  ))
                expression.values = expression.values.concat(difference)
              }
              
            break;
            case FilterKind.Actor:
              
              if(filterCategory===expression.category)
               {
                expression.values = expression.values.concat(filterValues)
               }
              break;
            case FilterKind.SingleValue:
              //replace the value
              if(filterCategory===expression.category)
              {
                expression.values = filterValues;
              }
            break;
            case FilterKind.Date:
              if(filterCategory===expression.category)
                {
                  expression.values = filterValues;
                }
            break;
            default:
              console.log("handleNewFilter caused unexpected behavior: shouldnt get here");
            break;
          }
          return expression;
          
        })
    }
    this.setState({filterExpressions:filterExpressions,selectedCategory:filterCategory[0]},this.updateHitRequest);
  }

  //executes the post request of search/filter
  applyRequest=()=>{
    
    const requestDto = buildQueryDto(this.state);
    const {connector} = this.state;
    connector.send(
      buildRequest(endpoints.experiences.query.url(),endpoints.experiences.query.method(false),true,requestDto),
      (json)=>{
        if ( connector.hasJson() && connector.is200() ) {
          this.setState({results:json})
        }
        else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
          //TODO: handle error
          console.log(connector.state)
        }
      }
    )
  }
  // getMoreExperiences=(offset)=>
  // {
  //   this.applyRequest(offset)
  // }
  //remove specific filter
  handleRemoveFilter=(category,values)=>{
    let filterExpressions = this.state.filterExpressions.map(expression=>
      {
        if(expression.category===category)
        {
          expression.values=values;
        }
        return expression
      })
      this.setState({filterExpressions:filterExpressions},this.updateHitRequest)
  }

  toggleFilterOptions=()=>
  {
    const result=!this.state.isFilterOptionVisible
    this.setState({isFilterOptionVisible:result})
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t } = this.props
    const {hits,filterExpressions} = this.state
    
    return (
      <div className='cisComponent'>
        <div className="container-fluid">
          <div className="cisComponentHeader">
            <div className="row">
              <div className="col">
                <h1>{t('overview.header')}</h1>
              </div>
            </div>
          </div>

          <div className="container-fluid cisComponentBody">
            <div className="card">
              <div className="card-body">

                {this.props.authService.isGuest() ? <span></span> : (
                  <span>
                    <SearchField t={t} onSearchClick={this.handleSearchClicked}></SearchField>
                    <div className="input-group">
                      <AppliedFilterField t={t} filterExpressions={filterExpressions} onValuesChange={this.handleRemoveFilter}></AppliedFilterField>
                    </div>

                    <div className="input-group">
                      <div className="form-control">
                        {hits === undefined ?
                          t('overview.search.undefined') : hits === 0 ?
                            t('overview.search.zero') :
                            this.state.hits + " " + t('overview.search.hits')
                        }
                      </div>
                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary" onClick={this.applyRequest}>{t('overview.search.applyRequest')}</button>
                        <button className="btn btn-outline-secondary" data-toggle="collapse" data-target="#filterOptions" aria-expanded="false" aria-controls="filterOptions" onClick={this.toggleFilterOptions}>{t('overview.filter.toggle')}</button>
                      </div>
                    </div>

                    <div className="collapse" id="filterOptions">
                      <FilterField t={t} onAuthorFilterChange={this.handleAuthorChange} onTranscribedFilterChange={this.handleTranscribeChange} onMustBeTranscribedChange={this.handleMustBeTranscribeChange} 
                        onWithReflectionChange={this.handleWithReflectionChange} onMustBeWithReflectionChange={this.handleMustBeWithReflectionChange} onMustBeWithIndividualCategorizationChange={this.handleMustBeWithIndividualCategorizationChange}
                        onWithIndividualCategorizationChange={this.handleIndividualCategorizationChange} onCheckRightAssignmentFilterChange={this.handleCheckRightAssignmentFilterChange} onApplyFilterClick={this.handleNewFilter} onContributorFilterChange={this.handleContributorChange} selectedCategory={this.state.selectedCategory} categorization={this.state.categorization} isAdmin={this.props.authService.isAdmin()}></FilterField>
                    </div>
                  </span>
                )}
              </div>
            {
              // the respective experiences
            }
            <div className="row">
              <div className="col">
                <InterculturalExperienceCardBoard results={this.state.results}/>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

//Possible calender impl
//"use strict";

//import React, { PropTypes } from 'react';

// class Calender extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             date : "2016-01-15T01:06"
//         };
//     }

//     render() {
//         return (
//             <input type="datetime-local" ref={(date) => {this.dateRef = date;}} value={this.state.date} onChange={this._onDateChange.bind(this)}/>
//         );
//     }

//     _onDateChange(e) {
//         let state = this.state;
//         state['date'] = e.target.value;
//         // Or (you can use below method to access component in another method)
//         state['date'] = this.dateRef.value;
//         this.setState(state);
//     }

// }

//export default Calender;

export default AuthHoc( LocalizableHoc()(Overview))