/**
 * MultiValueInput.js
 * @description Provides an input group with label and input field for multi value inputs including suggestion list and validation.
 * 
 * @since 1.5
 */

// npm package dependencies: react, popper, font awesome
import React from 'react'
import Popper from 'popper.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// services: language + translation service
import { LocalizableHoc } from '../../services/LanguageService'

// view + utilities
import { buildTextInputItem, buildReferenceLocalizedItemDto } from '../../domain/DataTransferObjects'


export class MultiValueInput extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            input: buildTextInputItem(),
            suggestions: [],
            index: 0,
            isSuggestDialogOpen: false,
            isDialogAutomaticallyControlled: true,
            // indicates if suggestions are calculated and displayed to the user
            isSuggestionDisabled: false,
        }

        this.inputFieldRef = React.createRef()
        this.currentSuggestItemRef = React.createRef()
        this.reference = undefined
        this.popup = undefined
        this.popper = undefined

        this.buildSuggestions = this.buildSuggestions.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.closeSuggestionDialog = this.closeSuggestionDialog.bind(this)
    }

    componentDidMount() {
        if ( this.popup !== undefined ) this.popper = new Popper( this.reference, this.popup, { placement: 'bottom-end', trigger: 'click focus' } )
        document.addEventListener( 'mousedown', this.closeSuggestionDialog )
    }

    componentWillUnmount() {
        if ( this.popper !== undefined) this.popper.destroy()
        document.removeEventListener( 'mousedown', this.closeSuggestionDialog )
    }

    componentDidUpdate() {
        if ( this.state.isSuggestDialogOpen === true &&  this.currentSuggestItemRef.current !== undefined && this.currentSuggestItemRef.current !== null ) {
            this.currentSuggestItemRef.current.scrollIntoView( { block:'center' } )
        }
    }

    closeSuggestionDialog( event ) {
        const { isSuggestDialogOpen } = this.state
        if ( isSuggestDialogOpen === true ) {
            const { target } = event
            // the suggest dialog stays open if the user clicks on the input field or an element within the suggestion dialog
            if ( !this.inputFieldRef.current.contains( target ) && !(this.popup.contains( target ) ) ) {
                this.setState( { isSuggestDialogOpen: false } )
            }
        }
    }

    buildSuggestions( input, minLength ) {
        if ( this.props.datalist === undefined || this.props.datalist.length === 0 ) return []
        if ( minLength === undefined ) minLength = 0
        const value = String( input ).trim().toLowerCase()
        if ( value.length < minLength ) return []
        if ( value.length === 0 ) return this.props.datalist
        // TODO: add configuration to switch between starts with or contains and the number of characters suggestions appear
        // TODO: remove already chosen values from suggestion list
        const result = this.props.datalist.filter( (it) => String( it.label ).toLowerCase().startsWith( value ) )
        return result
    }

    handleInputChange( event ) {
        const target = event.target
        const propname = target.name
        // use case: on an open suggestion dialog the enter key is pressed and thus the currently selected item is added to the values
        if ( event.type === 'keydown' && event.key === 'Enter' && this.state.isSuggestDialogOpen === true ) {
            // note: prevent any form submit button to be triggered
            event.preventDefault()
            const value = this.state.suggestions[this.state.index]
            if ( value === undefined ) return
            // avoid empty and duplicate values
            const { index } = this.state.input
            if ( this.props.values.some( (it, i) => i !== index && it.label.trim() === value.label.trim() ) ) {
                const values = this.props.values.filter( (it, i) => i !== index )
                this.props.onValuesChange( values )
            } else {
                // instead of putting the clicked item into the input field, we add the clicked item to the values
                // replace the current edit item in the values array with the clicked one or add the clicked item to the values array
                const item = buildReferenceLocalizedItemDto( value.id, value.label )
                const values = this.props.values[ index ] === undefined
                        ? this.props.values.slice().concat( item )
                        : this.props.values.map( (it, i) => i === index ? item : it )
                this.props.onValuesChange( values )
            }
            // reset the input field
            this.setState( { input: buildTextInputItem(), suggestions: [], index: 0, isSuggestDialogOpen: false } )
            // old behaviour: put currently selected item of the suggestion list into input field + flag the current input with official categorization flag
            // this.setState( { input: buildTextInputItem( value.id, value.label, true ), suggestions: [], index: 0, isSuggestDialogOpen: false } )
        // use case: the enter key was pressed in the input field or the add button was clicked and thus the input is finished and the field should be reset
        } else if ( ( event.type === 'keydown' && event.key === 'Enter' ) || ( event.type === 'click' && propname === 'conclude' ) ) {
            // note: prevent any form submit button to be triggered
            event.preventDefault()
            // avoid empty and duplicate values
            const { input } = this.state
            if ( input.label.trim().length === 0 || this.props.values.some( (it, i) => i !== input.index && it.label.trim() === input.label.trim() ) ) {
                const values = this.props.values.filter( (it, i) => i !== input.index )
                this.props.onValuesChange( values )
            }
            // just reset the input field as value is already added to the values array
            this.setState( { input: buildTextInputItem(), suggestions: [], index: 0, isSuggestDialogOpen: false } )
            this.inputFieldRef.current.focus()
        // use case: click to edit a value from the multi value list or click the current edit value to deactivate editing
        } else if ( event.type === 'click' && propname === 'edit' ) {
            // discard current edit item and create a new one for the clicked value of the multi values array
            const index = Number.parseInt( target.dataset.index )
            if ( index === undefined || Number.isNaN( index ) || this.props.values[ index ] === undefined ) return
            const item = this.props.values[ index ]
            // check if editing should be activated or deactivated for the clicked item
            if ( index === this.state.input.index ) {
                // avoid empty and duplicate values
                if ( item.label.trim().length === 0 || this.props.values.some( (it, i) => i !== index && it.label.trim() === item.label.trim() ) ) {
                    const values = this.props.values.filter( (it, i) => i !== index )
                    this.props.onValuesChange( values )
                }
                // reset input field to deactivate editing
                this.setState( { input: buildTextInputItem(), suggestions: [], index: 0, isSuggestDialogOpen: false } )
            } else {
                // note: need to check datalist as suggestions are optional to this component
                const isOfficial = this.props.datalist === undefined ? false : this.props.datalist.find( (it) => it.id === item.id ) ? true : false
                const state = { input: buildTextInputItem( item.id, item.label, isOfficial, index ) }
                // update suggestion list
                if ( this.state.isSuggestionDisabled === false ) {
                    state.suggestions = this.buildSuggestions( state.input.label, 1 )
                    state.index = 0
                    state.isSuggestDialogOpen = state.suggestions.length > 0
                    if ( state.isSuggestDialogOpen === true ) {
                        state.isDialogAutomaticallyControlled = true
                        this.popper.scheduleUpdate()
                    } else {
                        // in case the dialog was automatically closed due to no suggestions, we enable the automatic show
                        state.isDialogAutomaticallyControlled = true
                    }
                }
                this.setState( state )
            }
            this.inputFieldRef.current.focus()
        // use case: click to remove a value from the multi value list
        } else if ( event.type === 'click' && ( propname === 'remove' || target.localName === 'svg' || target.localName === 'path' ) ) {
            // note: in case click on the icon, the target is not the svg element but the path element inside the svg element!
            const index = propname === 'remove' || target.localName === 'span' || target.localName === 'svg' 
                    ? Number.parseInt( target.dataset.index ) 
                    : target.parentElement ? Number.parseInt( target.parentElement.dataset.index ) : undefined
            if ( index === undefined || Number.isNaN( index ) ) return
            // remove the clicked item from values
            const values = this.props.values.filter( (it, i) => i !== index )
            this.props.onValuesChange( values )
            // check if a current edit item exists and if its edit index is changed because of this removal
            const { input } = this.state
            if ( input.index !== undefined && Number.isInteger( input.index ) ) {
                if ( index === input.index ) {
                    // reset the input
                    this.setState( { input: buildTextInputItem(), suggestions: [], index: 0, isSuggestDialogOpen: false } )
                } else if ( index < input.index && input.index > 0 ) {
                    // adjust the current edit item index
                    this.setState( { input: buildTextInputItem( input.id, input.label, input.isOfficial, input.index -1 ) } )
                }
                // else leave state unchanged ()
            }
            this.inputFieldRef.current.focus()
        // use case: click on an item in the suggestion list and thus the value is added to the values
        } else if ( event.type === 'click' && propname === 'choose' ) {
            // click on item in suggestion list
            const item = this.state.suggestions[ target.dataset.index ]
            if ( item === undefined ) return
            // avoid duplicate values (note: empty values does not occur here)
            const { index } = this.state.input
            if ( this.props.values.some( (it, i) => i !== index && it.label.trim() === item.label.trim() ) ) return
            // replace the current edit item in the values array with the clicked one or add the clicked item to the values array
            const value = buildReferenceLocalizedItemDto( item.id, item.label )
            const values = this.props.values[ index ] === undefined
                    ? this.props.values.slice().concat( value )
                    : this.props.values.map( (it, i) => i === index ? value : it )
            this.props.onValuesChange( values )
            // reset input field but if open leave the suggestion dialog open with the current item selected for further selections
            if ( this.state.isSuggestDialogOpen === true ) {
                const suggestions = this.buildSuggestions( '', 0 )
                const index = suggestions.findIndex( (it) => it.id === item.id )
                this.setState( { input: buildTextInputItem(), suggestions: suggestions, index: index >= 0 ? index : 0 } )
            } else {
                this.setState( { input: buildTextInputItem(), suggestions: [], index: 0 } )
            }
            // old behavior: put the clicked item of the suggestion list into input field and flag it with official categorization flag
            // this.setState( { input: buildTextInputItem( value.id, value.label, true ), isSuggestDialogOpen: false } )
        // use case: click on the suggestion list button to toggle the suggestion list and thus it is shown or hidden
        } else if ( event.type === 'click' && propname === 'toggle' ) {
            // disable suggestions or manually show the current suggestions, possibly all values in case if input is empty
            const state = {}
            if ( this.state.isSuggestDialogOpen === true ) {
                state.isSuggestDialogOpen = false
                if ( this.state.isDialogAutomaticallyControlled === true ) {
                    state.isDialogAutomaticallyControlled = false
                    state.isSuggestionDisabled = true
                }
            } else {
                state.isSuggestionDisabled = false
                state.suggestions = this.buildSuggestions( this.state.input.label, 0 )
                state.index = 0
                state.isSuggestDialogOpen = state.suggestions.length > 0
                if ( state.isSuggestDialogOpen === true ) this.popper.scheduleUpdate()
            }
            this.setState( state )
            this.inputFieldRef.current.focus()
        // use case: the suggestion list is shown and the up or down key is pressed and thus the previous or next item of the suggestion list is selected
        // note: this is working in case the input field has the focus - i don't know why a button event handler cannot receive such events (in firefox and chrome as well)
        } else if ( (event.type === 'keydown' && event.key === 'ArrowDown') || (event.type === 'keydown' && event.key === 'ArrowUp') ) {
            // select previous or next item of the suggestion list
            event.preventDefault()
            let index = this.state.index + ( event.key === 'ArrowDown' ? +1 : -1 )
            if ( index === -1 ) {
                index = this.state.suggestions.length -1
            } else if ( index === this.state.suggestions.length ) {
                index = 0
            }
            this.setState( { index: index } )
        // use case: text is entered in the input field and thus the local state is updated
        } else if ( event.type === 'change') {
            // update the local input state and the suggestions if enabled
            // reset the id of the current input in case of an official categorization item
            const state = { input: buildTextInputItem( this.state.input.isOfficial === true ? undefined : this.state.input.id, target.value, false, this.state.input.index ) }
            // preserve the edit index of the values array or determine a new index for a new value (at the end of the array)
            if ( state.input.index === undefined ) state.input.index = this.props.values.length
            // update suggestion list
            if ( this.state.isSuggestionDisabled === false ) {
                state.suggestions = this.buildSuggestions( state.input.label, 1 )
                state.index = 0
                state.isSuggestDialogOpen = state.suggestions.length > 0
                if ( state.isSuggestDialogOpen === true ) {
                    state.isDialogAutomaticallyControlled = true
                    this.popper.scheduleUpdate()
                } else {
                    // in case the dialog was automatically closed due to no suggestions, we enable the automatic show
                    state.isDialogAutomaticallyControlled = true
                }
            }
            // add or update the value in the values array
            const value = buildReferenceLocalizedItemDto( state.input.id, state.input.label )
            const values = this.props.values[state.input.index] === undefined 
                    ? this.props.values.slice().concat( value ) 
                    : this.props.values.map( (it, i) => i === state.input.index ? value : it )
            this.props.onValuesChange( values )
            this.setState( state )
        }
    }

    render() {
        const { values, datalist, id, isRequired, placeholder, buttonText, hintText, ariaRemoveLabel, ariaEditLabel, ariaToggleSuggestions } = this.props
        const { input, suggestions, index, isSuggestDialogOpen } = this.state
        const isAbleToSuggest = datalist !== undefined && datalist.length > 0 ? true : false
        // note: as this input component fires on value changes only after button push or enter key press, the browser validation doesn't work as expected
        // as the text input may contain input data, but the values property is still empty in case data was input but without button push or enter key press!
        return (
            <div>
                <div className="input-group">
                    <input className="form-control" type="text" name="input" autoComplete="off" id={id} value={input.label} required={isRequired} placeholder={placeholder}
                            autoFocus={true} ref={this.inputFieldRef} onChange={this.handleInputChange} onKeyDown={this.handleInputChange} />
                    <div className="input-group-append">
                        { isAbleToSuggest === false ? null : 
                            <button className="btn btn-outline-secondary rounded-0 dropdown-toggle dropdown-toggle-split" type="button" name="toggle" aria-haspopup="true" aria-expanded="false" 
                                ref={ (elem) => this.reference = elem } onClick={this.handleInputChange}>
                            <span className="sr-only">{ariaToggleSuggestions}</span>
                        </button> }
                        <button className="btn btn-outline-secondary" type="button" name="conclude" onClick={this.handleInputChange}>{buttonText}</button>
                    </div>
                </div>
                { isAbleToSuggest === false ? null :
                    <div className={isSuggestDialogOpen === false ? "dropdown-menu" : "dropdown-menu d-block"} style={ { maxHeight: '20rem', overflowY: 'auto' } }
                            ref={ (elem) => this.popup = elem }>
                        { suggestions.map( (it, i) => <button className="dropdown-item" type="button" name="choose" 
                                style={ i === index ? { cursor: 'pointer', backgroundColor: '#dae0e5' } : { cursor: 'pointer' } }
                                ref={ i === index ? this.currentSuggestItemRef : undefined }
                                key={it.id} data-index={i} onClick={this.handleInputChange}>{ it.label }</button> ) }
                </div> }
                { hintText === undefined ? null : <div className="d-block" style={ {fontSize: '80%', marginTop: '0.25rem'} }>{ hintText }</div> }
                <div className="btn-toolbar my-1" role="toolbar">
                    { values.map( (it, i) => 
                        <div key={it.label} className="btn-group mr-2 mb-2" role="group">
                            <button type="button" className={"btn btn-sm btn-outline-" + (i !== values.findIndex( t => it.label === t.label ) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                                    name="edit" data-index={i} onClick={this.handleInputChange} aria-label={ariaEditLabel}>
                                { it.label }
                            </button>
                            <button type="button" className={"btn btn-sm btn-outline-" + (i !== values.findIndex( t => it.label === t.label ) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                                    name="remove" data-index={i} onClick={this.handleInputChange} aria-label={ariaRemoveLabel}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} data-index={i} />
                            </button>
                        </div>
                    ) }
                </div>
            </div>
        )
    }
}
//<button key={it.label} type="button" className="btn btn-sm btn-outline-secondary my-1 mr-2" name="remove" data-index={i} onClick={this.handleInputChange} aria-label={ariaRemoveLabel}>
//<span data-index={i}>{ it.label }&nbsp;</span>
//<FontAwesomeIcon icon={['far', 'trash-alt']} data-index={i} />
//</button>

export default LocalizableHoc()(MultiValueInput)
