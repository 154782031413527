/**
 * AuthService.js
 * @description Provides authentication and authorization services for the app.
 * @since 2.0
 */

import React from 'react'

import { UserRole } from '../domain/UserRole'
import { Connector, buildRequest } from './Fetcher'
import { endpoints } from '../config/endpoints'
import InsufficientPermission from '../views/InsufficientPermission'

/**
 * Provides authentication and authorization services.
 */
class AuthService {
    constructor() {
        // note: currently we don't use JWT but sessions, thus we cannot rely on local storage but must check on server! (session timeout!)
        let lookup = localStorage.getItem( 'role' )
        this.role = UserRole.valueOf( lookup )
        this.authenticated = false
        this.authfailed = false
        this.roleChangeListeners = []
        this.connector = new Connector()
        this.timestamp = undefined
        this.handler = ( isSuccessful, isAuthenticated, role ) => {
            // TODO: we cannot distinct between authenticated guest users and unauthenticated guest users!
            // TODO: until we switch to JWT, we treat guest users as unauthenticated!
            this.authfailed = isSuccessful === false
            let hasChanged = this.authenticated !== isAuthenticated || this.role !== role
            if ( hasChanged === true ) {
                this.role = role
                this.authenticated = isAuthenticated === false ? role !== UserRole.GUEST : true
                this.timestamp = role === UserRole.GUEST ? undefined : new Date()
            }
            if ( hasChanged === true || isSuccessful === false) {
                localStorage.setItem( 'role', this.role )
                this.roleChangeListeners.forEach( (it) => it() )
            }
        }
        this.check()
    }

    /** Adds the given callback to the list of permission change listeners. */
    addRoleChangeListener( callback ) {
        this.roleChangeListeners.push( callback )
    }

    /** Removes the given callback from the list of permission change listeners. */
    removeRoleChangeListener( callback ) {
        let it = this.roleChangeListeners.indexOf( callback )
        if ( it !== -1 ) this.roleChangeListeners.splice( it, 1 )
    }

    /** Perform an async login attempt on the backend. */
    login( identification, credentials ) {
        this.authfailed = false
        let request = buildRequest( endpoints.authentication.login.url(), endpoints.authentication.login.method(), true, 
            { identification: identification, credentials: credentials } )
        this.connector.send( request, ( json ) => {
            // login is successful when we have a 200 and a valid json object with the user role
            if ( this.connector.hasJson() ) {
                this.handler( true, true, UserRole.valueOf( json.role ) )
            } else if ( this.connector.hasError() || ( this.connector.hasResponse() && !this.connector.isOk() ) ) {
                this.handler( false, false, UserRole.GUEST )
            }
        } )
    }

    /** Performs an async logout attempt on the backend. */
    logout() {
        this.authfailed = false
        let request = buildRequest( endpoints.authentication.logout.url(), endpoints.authentication.logout.method(), true )
        this.connector.send( request, ( json ) => {
            // logout is supposed to be successful when response code is 200
            if ( this.connector.hasResponse() && this.connector.is200() ) {
                this.handler( true, false, UserRole.GUEST )
            } else if ( this.connector.hasResponse() || this.connector.hasError() ) {
                this.handler( false, false, UserRole.GUEST )
            }
        } )
    }

    /** Performs an async permission check attempt on the backend. */
    check() {
        this.authfailed = false
        let request = buildRequest( endpoints.authentication.check.url(), endpoints.authentication.check.method(), true )
        this.connector.send( request, ( json ) => {
            if ( this.connector.hasJson() ) {
                // note: we cannot distinct authenticated guest users from unauthenticated here, thus all guest users are unauthenticated until we switch to JWT!
                this.handler( true, UserRole.GUEST !== json.role, json.role )
            } else if ( this.connector.hasError() || ( this.connector.hasResponse() && !this.connector.is200() ) ) {
                this.handler( false, false, UserRole.GUEST )
            }
        } )
    }

    // connector() {
    //     // TODO: just for evaluation of connector response codes and errors to notify users with appropriate messages
    //     return this.connector
    // }

    /** Returns tue if the current user is authenticated, otherwise false. */
    isAuthenticated() {
        return this.authenticated
    }

    /** Returns true if an authentication request to the server was made and an error occured. */
    isFailed() {
        return this.authfailed
    }

    /** Returns true if the current user has enough permissions to use functionality for the given user role. */
    hasPermission( role ) {
        if ( role === undefined || role === null ) return false
        switch ( role ) {
            case UserRole.ADMIN: return this.isAdmin()
            case UserRole.AUTHOR: return this.isAuthor() || this.isAdmin()
            // note: we don't distinct between unauthenticated users and authenticated with user role guest
            case UserRole.GUEST:
            default: 
                return true
        }
    }

    /** Returns the user role of the current authorization status. */
    permission() {
        // TODO: check time elapsed and trigger check again after 30min (until we switch to JWT)
        return this.role
    }

    /** Returns true if the currently authenticated or not authenticated user has guest permissions. */
    isGuest() {
        return this.role === UserRole.GUEST
    }

    /** Returns true if the currently authenticated user has author permissions. */
    isAuthor() {
        return this.role === UserRole.AUTHOR
    }

    /** Returns true if the currently authenticated user has admin permissions. */
    isAdmin() {
        return this.role === UserRole.ADMIN
    }
}

/** The singleton authentication service used to authenticate users in the frontend and check their user role. */
export const authService = new AuthService()

/**
 * A higher order function used to wrap react components to which access is restricted to certain user roles.
 * The config parameter is a json object which supports the following keys:
 * - require: a user role which is required to render the wrapped component
 *            ( defaults to UserRole.GUEST )
 * - injectAuthService: true or false specifying if the authService property is injected into the wrapped component
 *                      ( defaults to true, i. e. the authService is injected )
 * - hasEmptyReplacement: true or false specifying if nothing (null) is rendered on insufficient permissions or an alert notifying the user
 *                      ( defaults to false, i. e. the alert is displayed )
 */
export const AuthHoc = ( WrappedComponent, config ) => {
    class AuthCheck extends React.Component {
        constructor( props ) {
            super( props )
            this.state = {
                // permission: authService.permission(),
                // isAuthenticated: authService.isAuthenticated()
            }
            this.updateWrappedComponent = this.updateWrappedComponent.bind(this)
        }

        /** Forces the update and thus a re-render of the wrapped component. */
        updateWrappedComponent() {
            this.forceUpdate()
        }

        /** Adds the wrapped component as listener to authorization and authentication changes. */
        componentDidMount() {
            authService.addRoleChangeListener( this.updateWrappedComponent )
        }

        /** Remoes the wrapped component as listener to authorization and authentication changes. */
        componentWillUnmount() {
            authService.removeRoleChangeListener( this.updateWrappedComponent )
        }

        /** Rendes the wrapped component with the authService injected as property for easy access. */
        render() {
            // note: refs are currently not forwarded to the wrapped component and thus not supported by the hoc!
            // const { require, ...passThroughProps } = this.props
            const require = config === undefined || config.require === undefined ? UserRole.GUEST : config.require
            const isInjecting = config === undefined || config.injectAuthService === undefined ? true : config.injectAuthService === true
            const isRenderingPermissionHint = config === undefined || config.hasEmptyReplacement === undefined ? true : config.hasEmptyReplacement === false
            if ( authService.hasPermission( require ) ) {
                if ( isInjecting === true ) {
                    return <WrappedComponent authService={authService} {...this.props} />
                } else {
                    return <WrappedComponent {...this.props} />
                }
            } else if ( isRenderingPermissionHint === true ) {
                return <InsufficientPermission authService={authService} {...this.props} />
            } else {
                return null
            }
        }
    }
    return AuthCheck
}
