import {UserRole} from '../domain/UserRole.js';

/**
 * Checks the respective User Role and returns a boolean
 * @param {*} userRole respective Role to check for
 */
export class UserRoleChecker{
    static isAdmin(userRole){
        return (userRole === UserRole.ADMIN ? true : false);
    }
    static isAuthor(userRole){
        return (userRole === UserRole.AUTHOR ? true : false);
    }
}
  /**
   * checks is the given username is a valid e-mail adress
   * @param {*} username the input username from the login form
   */
  export function usernameIsValidEmail(username) {
    
    var result = false;
    if(!username)
    {
      return result;
    }
    var mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (username.match(mailFormat)) {
      result = true;
    }

    return result;
  }
  export function passwordInputIsValid(password,passwordRepeat) {
    let result = false;

    if (password !== '') {
      if (password === passwordRepeat) {
        result = true;
      }
    }
    return result;
}

  /**
   * parses a String for User role to get the respective enum representation
   * @param {*} userRole input string
   * @returns UserRole enum
   */
  export function parseUserRole(userRole){
    const result = UserRole[userRole];
    if(result===undefined)
    {
        return UserRole.GUEST;
    }
    return result;
  }