/**
 * LanguageService.js
 * @description Provides the language and localization services for the app and wraps (hides) third party library APIs.
 * @since 2.0
 */

// localization is done using i18next library and the react specific bindings
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next, withTranslation } from 'react-i18next'

// global configuration of i18next and react-i18next
export default i18n
    .use( Backend )
    .use( LanguageDetector )
    .use( initReactI18next )
    .init( {
        fallbackLng: 'de',
        ns: ['translations'],
        defaultNS: 'translations',
        saveMissing: false,
        debug: false,
        interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
        wait: true,
        nsMode: 'fallback'
    }
} )

/**
 * Service providing the current language as well as the possibility to switch the current language.
 */
class LanguageService {
    // TODO: currently unclear if we implement an API where the frontends gets the supported language from the backend based on the browser language preferences (accept-language header)
    switchLanguage( language ) {
        if ( language !== undefined && language !== null ) {
            // TODO: change language returns a promise - we should check for any errors and in case reset the language to the last one!
            i18n.changeLanguage( language )
        }
    }

    language() {
        // note: i18n.init() function returns a promise and as long as it doesn't finish async operations, the access to i18n.language returns undefined
        let language = i18n.language
        return language === undefined || language === null || String(language).trim().length === 0 ? 'de' : language
    }
}

/** All supported languages by the web application frontend. */
export const supportedLanguages = [ 'de', 'en' ]

/** The language service used to change the language of the web application frontend. */
export const languageService = new LanguageService()

/** A higher order function used to wrap react components and inject the t function for translations. */
export const LocalizableHoc = withTranslation
