import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CategorizationData,Category } from '../../domain/CategorizationData';
import {MVF as MultiValueFilter,SVF as SingleValueFilter, DateFilter, ActorFilter} from './FilterValueInput'
import{SingleValueInput} from '../contribute/SingleValueInput'
import {formArray} from '../../domain/DataTransferObjects'
import {cloneObject} from '../../domain/DataTransferObjects'
import {buildTimeIntervallString,buildLabelString,buildAgeString} from '../../services/Aggregator'
//represents an input field + search button

//filter can be applied to
//contributor, author,transcribed (bool)
//experienceType, DataOrigin,dataKind,narrativePerspective, Place,Country as single Values
//contactDomains,Hotspots,InteractionMedia,commounicationDomains,textLanguages as multi Values
//actors and timespans 

const FilterCategory=
[
     "none",
     "individualCategory",
     "experienceType","dataOrigin","dataKind","narrativePerspective","particularPlace","country",
     "contactDomain","hotspot","interactionMedium","communicationDomain","language"//textLanguage
     ,"actor"
     ,"date"

]
const FilterCategoryAdmin=
[
     "none",
     "individualCategory",
     "experienceType","dataOrigin","dataKind","narrativePerspective","particularPlace","country",
     "contactDomain","hotspot","interactionMedium","communicationDomain","language"//textLanguage
     ,"actor"
     ,"date"
     ,"rightOfUse"
]

const IndividualCategory=[
        "CONTACT_DOMAIN",
		"HOTSPOT",
		"NATIONALITY",
		"ACTOR_LANGUAGE",
		"MEDIA_LANGUAGE",
		"COUNTRY",
		"LOCATION_INDICATION",
		"REFLECTION_KEYWORD"
]
// respective enum for the filterKind
const FilterKind=
{
    SingleValue:0,
    MultiValue:1,
    Actor:2,
    Date:3
}
class FilterField extends React.Component {
    constructor(props){
        super(props);
        this.state={
            filter: this.props.filter,
            isContributor : this.props.isContributor,
            isAuthor: this.props.isAuthor,
            isCheckRightAssignment: this.props.isCheckRightAssignment,
            withReflection:this.props.withReflection,
            withIndividualCategorization:this.props.withIndividualCategorization,
            categoryData:undefined,
            categoryFilter:this.props.selectedCategory,
            mustBeTranscribed:this.props.mustBeTranscribed,
            mustBeWithReflection:this.props.mustBeWithReflection,
            mustBeWithIndividualCategorization:this.props.mustBeWithIndividualCategorization,
            filterKind:undefined,
            filterValues:[],
        };
        this.handleIsContributorChange  = this.handleIsContributorChange.bind(this);
        this.handleIsAuthorChange       = this.handleIsAuthorChange.bind(this);
        this.onApplyFilterClick         = this.onApplyFilterClick.bind(this);
        //refactor those bool state update to more dense code
        this.handleIsTranscribed        = this.handleIsTranscribed.bind(this);
        this.handleCheckRightAssignment = this.handleCheckRightAssignment.bind(this);
        this.handleWithReflection       = this.handleWithReflection.bind(this);
        this.handleWithIndividualCategorization = this.handleWithIndividualCategorization.bind(this)
        this.handleMustBeTranscribed = this.handleMustBeTranscribed.bind(this)
        this.handleMustBeWithReflection = this.handleMustBeWithReflection.bind(this)
        this.handleMustBeWithIndividualCategorization = this.handleMustBeWithIndividualCategorization.bind(this)
    }

    handleIsTranscribed(event){
        this.setState({
            isTranscribed:event.target.checked
        });
        if(this.props.onTranscribedFilterChange)
        {
            this.props.onTranscribedFilterChange(event.target.checked,event);
        }
    }

    handleMustBeTranscribed(event){
        this.setState({
            mustBeTranscribed:event.target.checked
        });
        if(this.props.onMustBeTranscribedChange)
        {
            this.props.onMustBeTranscribedChange(event.target.checked,event);
        }
    }

    handleIsContributorChange(event){
        this.setState({
            isContributer:event.target.checked
        });
        if(this.props.onContributorFilterChange)
        {
            this.props.onContributorFilterChange(event.target.checked,event);
        }
    }

    handleIsAuthorChange(event){
        this.setState({
            isAuthor:event.target.checked
        });
        if(this.props.onAuthorFilterChange)
        {
            this.props.onAuthorFilterChange(event.target.checked,event);
        }
    }

    handleWithReflection(event){
        this.setState({
            withReflection:event.target.checked
        });
        if(this.props.onWithReflectionChange)
        {
            this.props.onWithReflectionChange(event.target.checked,event);
        }
    }

    handleMustBeWithReflection(event){
        this.setState({
            mustBeWithReflection:event.target.checked
        });
        if(this.props.onMustBeWithReflectionChange)
        {
            this.props.onMustBeWithReflectionChange(event.target.checked,event);
        }
    }

    handleMustBeWithIndividualCategorization(event){
        this.setState({
            mustBeWithIndividualCategorization:event.target.checked
        });
        if(this.props.onMustBeWithIndividualCategorizationChange)
        {
            this.props.onMustBeWithIndividualCategorizationChange(event.target.checked,event);
        }
    }

    handleWithIndividualCategorization(event){
        this.setState({
            withIndividualCategorization:event.target.checked
        });
        if(this.props.onWithIndividualCategorizationChange)
        {
            this.props.onWithIndividualCategorizationChange(event.target.checked,event);
        }
    }

    handleCheckRightAssignment(event)
    {
        this.setState({
            isCheckRightAssignment:event.target.checked
        });
        if(this.props.onCheckRightAssignmentFilterChange)
        {
            this.props.onCheckRightAssignmentFilterChange(event.target.checked,event);
        }
    }

    onApplyFilterClick(event){
        if(this.props.onApplyFilterClick)
        {
            this.props.onApplyFilterClick(this.state.categoryFilter,this.state.filterValues,this.state.filterKind);
        }
        //reset the filterValues
        //if(this.state.filterKind!==FilterKind.SingleValue)
        {
            this.setState({filterValues:[],categoryData:undefined,categoryFilter:FilterCategory[0],filterKind:undefined});
        }
    }

    buildIndividualCategories() {
        let counter = 0;
        const localizedIndividualCategories = IndividualCategory.map((enumValue) => {

            const entry = {
                id: counter,
                value: enumValue,
                label: this.props.t("overview.filter." + enumValue)
            }
            counter = counter + 1;
            return entry
        })
        return localizedIndividualCategories
    }

    handleFilterValuesChange=(filterValues)=>
    {
        this.setState({filterValues:filterValues})
    }

    handleFilterCategoryChange = (event)=>
    {
        //reset filterValues, update ui respective to filterkind
        const categoryFilter = event.target.value;
        let filterKind, categoryData;
        switch(categoryFilter){
            case "date":
                filterKind=FilterKind.Date;
                break;
            case "actor":
                filterKind=FilterKind.Actor
                categoryData = this.props.categorization;
                break;
            case "experienceType": case "dataOrigin": case "dataKind": case "narrativePerspective": case "particularPlace": case "country":
                filterKind=FilterKind.SingleValue;
                categoryData = this.props.categorization[categoryFilter]();
                break;
            case "contactDomain": case "hotspot": case "interactionMedium": case "communicationDomain": case "language": case "rightOfUse"://textLanguage
                filterKind=FilterKind.MultiValue;
                categoryData = this.props.categorization[categoryFilter]();
                break;
            case "individualCategory":
                filterKind=FilterKind.MultiValue;
                categoryData = this.buildIndividualCategories();
                break;
            case "none":
                filterKind=undefined;
                categoryData=undefined;
                break;
            default:
                console.log("handleFilterCategoryChange caused unexpected behavior: souldnt get here");
        }
        this.setState({categoryData:categoryData,categoryFilter:categoryFilter,filterValues:[],filterKind:filterKind})
    }

    render(){
        const t = this.props.t;
        const categoryData = this.state.categoryData;
        const categoryFilter = this.state.categoryFilter;
        const filterValues = this.state.filterValues;
        const filterKind = this.state.filterKind;
        return (
            <div className="row">
                <div className="form-group col-3">

                    <div className="form-check">
                        <input className="form-check-input" id="checkAuthor"
                            onChange={this.handleIsAuthorChange}
                            type="checkbox"
                            value={this.state.isAuthor}
                        />
                        <label className="form-check-label" htmlFor="checkAuthor">{t('overview.filter.author')}</label>

                    </div>

                    <div className="form-check">
                        <input className="form-check-input" id="checkContributor"
                            onChange={this.handleIsContributorChange}
                            type="checkbox"
                            value={this.state.isContributor}
                        />
                        <label className="form-check-label" htmlFor="checkContributor">{t('overview.filter.contributor')}</label>

                    </div>
                    <div className="form-row">
                        <div className="form-check">
                            <input className="form-check-input" id="checkTranscribed"
                                onChange={this.handleIsTranscribed}
                                type="checkbox"
                                value={this.state.isTranscribed}
                            />
                            <label className="form-check-label" htmlFor="checkTranscribed">{t('overview.filter.transcribed')}</label>
                        </div>
                        {this.state.isTranscribed ? <div className="custom-control custom-switch">
                            <input type="checkbox" value={this.state.mustBeTranscribed} onChange={this.handleMustBeTranscribed} className="custom-control-input" id="customSwitch1" />
                            <label className="custom-control-label" htmlFor="customSwitch1"></label>
                        </div> :
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" disabled id="customSwitch1" />
                                <label className="custom-control-label" htmlFor="customSwitch1"></label>
                            </div>
                        }

                    </div>
                    <div className="form-row">
                        <div className="form-check">
                            <input className="form-check-input" id="withReflection"
                                onChange={this.handleWithReflection}
                                type="checkbox"
                                value={this.state.withReflection}
                            />
                            <label className="form-check-label" htmlFor="withReflection">{t('overview.filter.withReflection')}</label>
                        </div>
                        {this.state.withReflection ? <div className="custom-control custom-switch">
                            <input type="checkbox" value={this.state.mustBeWithReflection} onChange={this.handleMustBeWithReflection} className="custom-control-input" id="customSwitch2" />
                            <label className="custom-control-label" htmlFor="customSwitch2"></label>
                        </div> :
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" disabled id="customSwitch2" />
                                <label className="custom-control-label" htmlFor="customSwitch2"></label>
                            </div>
                        }
                    </div>
                    <div className="form-row">
                        <div className="form-check">
                            <input className="form-check-input" id="withIndividualCategorization"
                                onChange={this.handleWithIndividualCategorization}
                                type="checkbox"
                                value={this.state.withIndividualCategorization}
                            />
                            <label className="form-check-label" htmlFor="withIndividualCategorization">{t('overview.filter.withIndividualCategorization')}</label>
                        </div>
                        {this.state.withIndividualCategorization ? <div className="custom-control custom-switch">
                            <input type="checkbox" value={this.state.mustBeWithIndividualCategorization} onChange={this.handleMustBeWithIndividualCategorization} className="custom-control-input" id="customSwitch3" />
                            <label className="custom-control-label" htmlFor="customSwitch3"></label>
                        </div> :
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" disabled id="customSwitch3" />
                                <label className="custom-control-label" htmlFor="customSwitch3"></label>
                            </div>
                        }
                    </div>
                    {this.props.isAdmin ? (
                        <span>
                            <div className="form-row">
                                <div className="form-check">
                                    <input className="form-check-input" id="checkRightAssignment"
                                        onChange={this.handleCheckRightAssignment}
                                        type="checkbox"
                                        value={this.state.isCheckRightAssignment}
                                    />
                                    <label className="form-check-label" htmlFor="checkRightAssignment">{t('overview.filter.checkRightAssignment')}</label>
                                </div>
                            </div>
                        </span>
                    ) : <span></span>}
                </div>
                <div className="form-group col-9">

                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="filterCategoryText">{t('overview.filter.category')}</span>
                        </div>
                        <select className="form-control"
                            onChange={(event) => this.handleFilterCategoryChange(event)} value={categoryFilter} >
                            {this.props.isAdmin ?
                                (FilterCategoryAdmin.map(category => <option key={category} value={category}>{t("overview.filter." + category)}</option>))
                                : (FilterCategory.map(category => <option key={category} value={category}>{t("overview.filter." + category)}</option>))
                            }
                        </select>
                    </div>
                    {categoryData !== undefined || this.state.categoryFilter !== undefined ?
                        <div>
                            <CategoryFilterValue t={t} filterValues={filterValues} filterValuesChanged={this.handleFilterValuesChange} filterData={categoryData} applyFilter={this.onApplyFilterClick} filterKind={filterKind}></CategoryFilterValue>
                        </div>
                        : <div></div>}
                </div>
            </div>
        )
    }
}

//renders the respective UI element based on the filterKind
class CategoryFilterValue extends React.Component{
    constructor( props ) {
        super( props )
        this.handleInputChange = this.handleInputChange.bind( this )
    }

    handleInputChange( filterValues ) {
        let result=[]
        if ( Array.isArray( filterValues ) === false )
        {
            result.push(filterValues);
            
        } 
        else{
            result=filterValues
        }
        this.props.filterValuesChanged(result);
    }

    render(){
        const {t,filterValues,filterKind}=this.props;
        let UIElement;
        switch(filterKind)
            {
                case FilterKind.MultiValue:
                    UIElement = <MultiValueFilter applyFilter={this.props.applyFilter} description={t('overview.filter.value')} values={filterValues} datalist={this.props.filterData} id="filterValues"
                    placeholder={t('overview.filter.placeholder')} buttonText={t('overview.filter.action.conclude')} 
                    // ariaRemoveLabel={t('contribute.steps.contactdomain.aria.remove')} ariaToggleSuggestions={t('contribute.steps.contactdomain.aria.toggle')}
                    //hintText={t('overview.filter.hint')}
                    onValuesChange={this.handleInputChange} />;
                break;
                case FilterKind.SingleValue:
                    UIElement = <SingleValueFilter applyFilter={this.props.applyFilter} description={t('overview.filter.value')} value={filterValues[0]} id="filterValue" datalist={this.props.filterData}
                    placeholder={t('overview.filter.placeholder')}
                    onValueChange={this.handleInputChange} />
                break;
                case FilterKind.Date:
                    UIElement = <DateFilter applyFilter={this.props.applyFilter} description={t('overview.filter.interval')} value={filterValues} id="filterDate"
                    placeholderBefore={t('overview.filter.before')} placeholderAfter={t('overview.filter.after')}
                    onValuesChange = {this.handleInputChange} />
                break;
                case FilterKind.Actor:
                    UIElement = <ActorFilter t={t} applyFilter={this.props.applyFilter} description={t('overview.filter.actor')} values={filterValues} 
                    id="actorFilter" onValuesChange={this.handleInputChange} filterData={this.props.filterData} placeholder={t('overview.filter.placeholder')}
                    ></ActorFilter>
                    break;
                default:
                    UIElement=<div></div>
                    break;
            }
        return(
            <span>
            {UIElement}
            </span>
        )
    }
}

// shows the applied filters for the request. gives the user the possibility to delete single expressions
class AppliedFilterField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actorID: 0
        }
    }

    parseFilterExpression = (filterExpression, t) => {
        //this is no real clone :(
        const clone = [...filterExpression]
        let actorcounter = 0;
        const result = clone.map((filter) => {
            if (filter.category === "date" && filter.values.length > 0) {
                if (filter.values[0].id) {
                    return filter
                }
                const before = filter.values[0].before ? filter.values[0].before : ""
                const after = filter.values[0].after ? filter.values[0].after : ""

                filter.values[0] = {
                    id: 1,
                    label: buildTimeIntervallString(after, before, t),
                    before: before,
                    after: after
                }
            }
            else if (filter.category === "actor" && filter.values.length > 0) {

                const actors = filter.values.map((actor) => {
                    actorcounter = actorcounter + 1
                    if (!actor.id) {
                        const minAge = actor.ageLowerBound == "" ? undefined : actor.ageLowerBound;
                        const maxAge = actor.ageUpperBound == "" ? undefined : actor.ageUpperBound;
                        let groupeSizeStr = (actor.groupSize !== "" || actor.groupSizeUpperBound) ? t("overview.filter.groupsize") : ""
                        if (actor.groupSize == "" && actor.groupSizeUpperBound !== "") {
                            groupeSizeStr += " " + actor.groupSizeUpperBound
                        }
                        if (actor.groupSize !== "" && actor.groupSizeUpperBound == "") {
                            groupeSizeStr += " " + actor.groupSize
                        }
                        if (actor.groupSize !== "" && actor.groupSizeUpperBound !== "") {
                            groupeSizeStr += " " + actor.groupSize + " - " + actor.groupSizeUpperBound
                        }
                        const agestr = buildAgeString(minAge, maxAge, t)
                        let lbl = buildLabelString(actor.actorType) + " " + buildLabelString(actor.languages) + " " + agestr +
                            " " + groupeSizeStr + " " + buildLabelString(actor.gender) + " " + buildLabelString(actor.nationalities) + " "
                        actor.id = actorcounter
                        actor.label = lbl
                    }
                    return actor
                })
                filter.values = actors
            }
            return filter;
        })
        return result
    }

    handleInputChange = (event, category) => {
        const target = event.target
        const propname = target.name

        if (event.type === 'click' && (propname === 'remove' || target.localName === 'svg' || target.localName === 'path')) {

            // note: in case click on the icon, the target is not the svg element but the path element inside the svg element!
            const index = propname === 'remove' || target.localName === 'span' || target.localName === 'svg'
                ? Number.parseInt(target.dataset.index)
                : target.parentElement ? Number.parseInt(target.parentElement.dataset.index) : undefined
            if (index === undefined || Number.isNaN(index)) return
            // remove the clicked item from values

            const exp = this.props.filterExpressions.find(expression => expression.category === category)
            const values = exp.values.filter((it, i) => i !== index)
            this.props.onValuesChange(category, values)
            // check if a current edit item exists and if its edit index is changed because of this removal
            // const { input } = this.state
            // if ( input.index !== undefined && Number.isInteger( input.index ) ) {
            //     if ( index === input.index ) {
            //         // reset the input
            //         this.setState( { input: buildTextInputItem(), suggestions: [], index: 0, isSuggestDialogOpen: false } )
            //     } else if ( index < input.index && input.index > 0 ) {
            //         // adjust the current edit item index
            //         this.setState( { input: buildTextInputItem( input.id, input.label, input.isOfficial, input.index -1 ) } )
            //     }
            //     // else leave state unchanged ()
            // }
        }
    }
    render() {
        const t = this.props.t
        const expressions = this.props.filterExpressions
        const filterExpressions = this.parseFilterExpression(expressions, t)

        const input = "primary"
        return (
            <div className="btn-toolbar my-1" role="toolbar">
                {filterExpressions.map((filter) =>
                    <div key={filter.category}>
                        {filter.values.map((it, i) =>

                            <div key={filter.category + it.label + it.id} className="btn-group mr-2 mb-2" role="group">
                                <button type="button" className={"btn btn-sm btn-outline-" + (i !== filter.values.findIndex(t => it.label === t.label) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                                    name="edit" data-index={i} onClick={this.handleInputChange} >
                                    {t('overview.filter.' + filter.category) + " " + it.label}
                                </button>
                                <button type="button" className={"btn btn-sm btn-outline-" + (i !== filter.values.findIndex(t => it.label === t.label) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                                    name="remove" data-index={i} onClick={(event) => this.handleInputChange(event, filter.category)}>
                                    <FontAwesomeIcon icon={['far', 'trash-alt']} data-index={i} />
                                </button>
                            </div>

                        )}
                    </div>

                )}
                {/* {filterExpressions.forEach((filter)=>
            <div>{console.log(filter.values)}
            {filter.values.map((it,i)=>
        
            <div key={filter.category + it.label} className="btn-group mr-2 mb-2" role="group">
            <button type="button" className={"btn btn-sm btn-outline-" + (i !== filter.values.findIndex( t => it.label === t.label ) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                    name="edit" data-index={i} onClick={this.handleInputChange} >
                { filter.category + " " + it.label }
            </button>
            <button type="button" className={"btn btn-sm btn-outline-" + (i !== filter.values.findIndex( t => it.label === t.label ) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                    name="remove" data-index={i} onClick={this.handleInputChange}>
                <FontAwesomeIcon icon={['far', 'trash-alt']} data-index={i} />
            </button>
            </div>
        
                )}
           </div> */}


                {/* <div className="btn-toolbar my-1" role="toolbar">
                    { values.map( (it, i) => 
                        <div key={it.label} className="btn-group mr-2 mb-2" role="group">
                            <button type="button" className={"btn btn-sm btn-outline-" + (i !== values.findIndex( t => it.label === t.label ) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                                    name="edit" data-index={i} onClick={this.handleInputChange} aria-label={ariaEditLabel}>
                                { it.label }
                            </button>
                            <button type="button" className={"btn btn-sm btn-outline-" + (i !== values.findIndex( t => it.label === t.label ) ? 'danger' : i === input.index ? 'primary' : 'secondary')}
                                    name="remove" data-index={i} onClick={this.handleInputChange} aria-label={ariaRemoveLabel}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} data-index={i} />
                            </button>
                        </div>
                    ) }
                    </div> */}
            </div>
        )
    }
}
export { FilterField, AppliedFilterField, FilterKind, FilterCategory };