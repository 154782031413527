
import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
// icons used in contribute
import { faExclamationTriangle, faTimes, faPlus, faCheck, faSquare } from '@fortawesome/free-solid-svg-icons'
import { faEdit, faCheckSquare, faHandPointLeft, faTrashAlt, faCheckCircle as farCheckCircle, faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons'
// icons used in user + rights management
import { faInfoCircle, faRedo, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
// icons used in unused sources
import { faEdit as fasEdit, faTrash, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

//icons used in overview for search and filters
import { faSearch,faFilter } from '@fortawesome/free-solid-svg-icons'
// services: authentication + authorization, language + translation service
import { languageService, supportedLanguages, LocalizableHoc } from './services/LanguageService'
import { AuthHoc } from './services/AuthService'
import { Link4Guest, Link4Author, Link4Admin } from './views/Link'

/**
 * custom cis react components module imports 
 */
import Start from './views/Start'
import Login from './views/profile/Login'
import Overview from './views/experience/Overview'
import Contribute from './views/contribute/Contribute'
import UserManagement from './views/usermngt/UserManagement';
import DataManagement from './views/datamngt/DataManagement'
import Privacy from './views/Privacy'
import Impress from './views/Impress'
import Contact from './views/Contact'


/**
 * The component represents the main app component for the cis frontend
 * 
 * @version 1.3.0
 * @since 1.0.0
 */
export class App extends Component {

  /**
   * Default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props);

    this.state = {
      isLoginDialogVisible: false,
      isLogoutDialogVisible: false,
      isErrorNotificationVisible: false
    }
    
    this.showDialog = this.showDialog.bind(this)
    this.hideDialog = this.hideDialog.bind(this)
    this.hideNotificationError = this.hideNotificationError.bind(this)
    this.handleLanguageChange = this.handleLanguageChange.bind(this)
    this.handleLoginAttempt = this.handleLoginAttempt.bind(this)
    this.handleLogout = this.handleLogout.bind(this)

    // initialize font awesome library
    library.add( faExclamationTriangle, faTimes, faPlus, faCheck, faSquare )
    library.add( faEdit, faCheckSquare, faHandPointLeft, faTrashAlt )
    library.add( faInfoCircle, faRedo, faPlusCircle, faTimesCircle )
    library.add( fasEdit, faTrash, faPlusSquare )
    library.add( farCheckCircle, farTimesCircle )
    library.add( faSearch,faFilter )
  }

  hideNotificationError() {
    this.setState( {
      isErrorNotificationVisible: false
    } )
  }

  handleLoginAttempt() {
    this.setState( {
      isErrorNotificationVisible: true
    } )
  }

  showDialog() {
    this.setState( {
      isLoginDialogVisible: this.props.authService.isAuthenticated() === false ? true : false,
      isErrorNotificationVisible: false,
      isLogoutDialogVisible: this.props.authService.isAuthenticated() === true ? true : false
    } )
  }

  hideDialog() {
    this.setState( {
      isLoginDialogVisible: false,
      isLogoutDialogVisible: false
    } )
  }

  handleLogout() {
    this.hideDialog()
    this.props.authService.logout()
  }

  handleLanguageChange( event ) {
    languageService.switchLanguage( event.target.value )
  }

  /**
   * Main function to render the component elements (into the parent component or root DOM)
   */
  render() {

    const { t, authService } = this.props
    
    const language = languageService.language()
    const languageOptions = supportedLanguages.map( ( it ) => <option key={it} value={it}>{ t('languages.' + it) }</option> )
    const authButtonLocalizationKey = authService.isAuthenticated() === true ? 'profile.logout' : 'profile.login'
    
    const { isLoginDialogVisible, isLogoutDialogVisible, isErrorNotificationVisible } = this.state
    const isLoginVisible = isLoginDialogVisible === true && authService.isAuthenticated() === false ? true : false
    const isLogoutVisible = isLogoutDialogVisible === true && authService.isAuthenticated() === true ? true : false
    const isErrorVisible = isErrorNotificationVisible === true && authService.isFailed() === true ? true : false

    // TODO: replace reactstrap with plain bootstrap when the modal dialog is replaced! the modal dialog for login without rectstrap is hard to get right
    // TODO: (because of dialog state which is indirect derived from authentication success)

    return (
      <div>
        <Router>
          <div>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top system-font navigation-background">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-main" aria-controls="navbar-main"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div id='navbar-main' className='collapse navbar-collapse'>
                <ul className='navbar-nav mr-auto'>
                  <li className='nav-item'><Link4Guest className='nav-link' to='/' >{t('navigation.start')}</Link4Guest></li>
                  <li className='nav-item'><Link4Guest className='nav-link' to='/overview' >{t('navigation.overview')}</Link4Guest></li>
                  <li className='nav-item'><Link4Author className='nav-link' to='/contribute' >{t('navigation.contribute')}</Link4Author></li>
                  <li className='nav-item'><Link4Admin className='nav-link' to='/data-management'>{t('navigation.dataManagement')}</Link4Admin></li>
                  <li className='nav-item'><Link4Admin className='nav-link' to='/user-management' >{t('navigation.userManagement')}</Link4Admin></li>
                  <li className='nav-item'><Link4Guest className='nav-link' to='/privacy' >{t('navigation.privacy')}</Link4Guest></li>
                  <li className='nav-item'><Link4Guest className='nav-link' to='/impress' >{t('navigation.impress')}</Link4Guest></li>
                </ul>
                <ul className="navbar-nav">
                  <li className='nav-item'><Input type="select" name="language" id="language" value={language} onChange={this.handleLanguageChange}>{languageOptions}</Input></li>
                  <li className="nav-item">
                    <button className="btn btn-info navigation-button" onClick={this.showDialog} data-toggle="modal" href="#dlgLogin">{ t( authButtonLocalizationKey ) }</button>
                  </li>
                </ul>
              </div>
            </nav>
            <div className='container-fluid cisAppBody'>
              <div className='row'>
                <div className='col-12'>
                  <Route exact path='/' component={Start} />
                  <Route exact path='/overview' component={Overview} />
                  <Route exact path='/contribute' component={Contribute} />
                  <Route exact path='/contribute/:experienceId' component={Contribute} />
                  <Route exact path='/data-management' component={DataManagement} />
                  <Route exact path='/user-management' component={UserManagement} />
                  <Route exact path='/privacy' component={Privacy} />
                  <Route exact path='/impress' component={Impress} />
                </div>
              </div>
            </div>
            <Contact />
          </div>
        </Router>

        <Modal isOpen={isLoginVisible} toggle={this.hideDialog} className={this.props.className}>
          <ModalHeader toggle={this.hideDialog} className=" modalHeaderBg">
            {t('navigation.login.header')}
          </ModalHeader>
          <ModalBody>
            <Login handleLoginAttempt={this.handleLoginAttempt} />
          </ModalBody>
          <ModalFooter className="modalFooterBg">
            <Alert color="danger" isOpen={isErrorVisible} toggle={this.hideNotificationError}>
              <div className="text-center">{t('navigation.login.notification.error')}</div>
            </Alert>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isLogoutVisible} toggle={this.hideDialog} className={this.props.className}>
          <ModalHeader toggle={this.hideDialog} className="modalHeaderBg">
            {t('navigation.logout.header')}
          </ModalHeader>
          <ModalBody className="text-center">
            {t('navigation.logout.text')}
          </ModalBody>
          <ModalFooter className="modalFooterBg">
            <div className="container-fluid text-center">
              <Button color="success" className="modal-delete-btn" onClick={this.handleLogout}>{t('navigation.logout.confirm')}</Button>{' '}
              <Button color="danger" className="modal-delete-btn" onClick={this.hideDialog}>{t('navigation.logout.cancel')}</Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default AuthHoc( LocalizableHoc()(App) )
