import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'

// import { MediaType } from '../../domain/MediaType'
import { UserRole } from '../../domain/UserRole'
import { buildReferenceItemDto, formValue, propertyId } from '../../domain/DataTransferObjects'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'
import MultiValueInput from './MultiValueInput'


class StepBasics extends React.Component {
    constructor( props ) {
        super( props )
        this.handleLanguagesChange = this.handleLanguagesChange.bind( this )
        this.handleInputChange = this.handleInputChange.bind( this )
    }

    handleLanguagesChange( languages ) {
        if ( Array.isArray( languages ) ) {
            const textStory = Object.assign( {}, this.props.contribution.textStory, { languages: languages } )
            this.props.callbacks.onTextStoryChange( textStory )
        }
    }

    handleInputChange( event ) {
        const target = event.target
        const name = target.name
        // map the name used in the form to the name in the data transfer object
        const propname = name === 'dataOrigin' ? 'origin' : name === 'dataKind' ? 'kind' : name === 'mediaLanguage' ? 'language' : name
        // map the string value of the input into the appropriate value for the data transfer object
        // note: media type is send as text, suggested languages are send as integer, own languages are send as text
        const value = name === 'mediaType' ? target.value : buildReferenceItemDto( target.value )
        // create a shallow copy of the data transfer object with the property value changed + fire on change for dto
        if ( name === 'mediaType' ) {
            // TODO: handle media type correctly when supported
        } else if ( name === 'dataOrigin' || name === 'collection' ) {
            const experience = Object.assign( {}, this.props.contribution.experience, { [propname]: value } )
            this.props.callbacks.onExperienceChange( experience )
        } else {
            const textStory = Object.assign( {}, this.props.contribution.textStory, { [propname]: value } )
            this.props.callbacks.onTextStoryChange( textStory )
        }
    }
    
    render() {
        const { t, navigation, categorization, wasValidated, authService } = this.props
        const { experience, textStory } = this.props.contribution
        // extract the necessary properties to be edited: mediaType, mediaLanguage, data origin, data kind, collection
        // const { mediaType } = formValue( this.props )
        const dataOrigin = formValue( propertyId( experience.origin ) )
        // const collection = formValue( propertyId( experience.collection ) )
        const dataKind = formValue( propertyId( textStory.kind ) )
        const languages = textStory.languages
        // note: browser form validation marks the input elements with css pseudo class :invalid and due to the inline rendering of the radio buttons
        // and css selectors we are unable to display the invalid-feedback text based on the css pseudo classes :valid or :invalid of the input elements
        // (input elements are child elements of an invalid-feedback sibling -> not possible with css selectors)
        // see: https://github.com/twbs/bootstrap/issues/25540
        // const showInvalidFeedbackMediaType = wasValidated === true && !( mediaType !== undefined && MediaType.values().includes( mediaType ) )
        const showInvalidFeedbackLanguage = wasValidated === true && authService.isAuthor() && !( Array.isArray( languages ) && languages.some( it => it.id !== undefined || it.label !== undefined ) )
        const showInvalidFeedbackDataOrigin = wasValidated === true && !( dataOrigin !== undefined && categorization.dataOrigin().some( (it) => it.id === dataOrigin ) )
        const showInvalidFeedbackDataKind = wasValidated === true && authService.isAuthor() && !( dataKind !== undefined && categorization.dataKind().some( (it) => it.id === dataKind ) )
        return (
            <div>
                <div className="card">
                    <h6 className="card-header">{ t('contribute.steps.basics.data.header') }</h6>
                    <div className="card-body">
                        {/* Note: we hide the media type as currently only text is allowed and supported; no multi media like audio or video or images
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.basics.data.label.type') }</legend>
                                <div className="col-md-9">
                                    { MediaType.values().map( (item, i) =>
                                        <div className="form-check form-check-inline" key={item}>
                                            <input className="form-check-input" type="radio" id={'mediaType' +item} name="mediaType" value={item} required={true} disabled={MediaType.Text === item ? false : true} checked={mediaType === item} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={'mediaType' +item}>{ t('contribute.steps.basics.data.label.' +item) }</label>
                                        </div>
                                    ) }
                                    { showInvalidFeedbackMediaType === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.basics.data.validation.mediatype') }</div> : null }
                                </div>
                            </div>
                        </fieldset> */}
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="mediaLanguage">{ t('contribute.steps.basics.data.label.languages') }</label>
                            <div className="col-md-9">
                                <MultiValueInput values={languages} isRequired={ authService.isAuthor() && languages.length === 0 } datalist={ categorization.language() }
                                        id="mediaLanguage" placeholder={t('contribute.steps.basics.data.placeholder.languages')} 
                                        buttonText={t('contribute.steps.basics.data.action.conclude')}
                                        ariaToggleSuggestions={t('contribute.steps.basics.data.aria.toggleLanguages')}
                                        ariaRemoveLabel={t('contribute.steps.basics.data.aria.remove')}
                                        hintText={t('contribute.steps.basics.data.hint.languages')}
                                        onValuesChange={this.handleLanguagesChange} />
                                { showInvalidFeedbackLanguage === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.basics.data.validation.languages') }</div> : null }
                            </div>
                        </div>
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.basics.data.label.origin') }</legend>
                                <div className="col-md-9">
                                    { categorization.dataOrigin().map( (it, i) => 
                                        <div key={it.id} className=" form-check form-check-inline">
                                            <input className="form-check-input" type="radio" id={ 'dataOrigin' + it.id } name="dataOrigin" value={ it.id } required={true} checked={dataOrigin === it.id} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={ 'dataOrigin' + it.id }>{ it.label }</label>
                                        </div>
                                    ) }
                                    { showInvalidFeedbackDataOrigin === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.basics.data.validation.dataorigin') }</div> : null }
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.basics.data.label.kind') }</legend>
                                <div className="col-md-9">
                                    { categorization.dataKind().map( (it, i) => 
                                        <div key={it.id} className=" form-check form-check-inline">
                                            <input className="form-check-input" type="radio" id={ 'dataKind' + it.id } name="dataKind" value={ it.id } required={ authService.isAuthor() } checked={dataKind === it.id} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={ 'dataKind' + it.id }>{ it.label }</label>
                                        </div>
                                    ) }
                                    { showInvalidFeedbackDataKind === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.basics.data.validation.datakind') }</div> : null }
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                {/* Note: we hide the collection card as it is not supported by the frontend to create and modify collections
                    the backend has basic support and an API but some details regarding the access/view of experiences in collections needs attention
                <div className="card mt-3">
                    <h6 className="card-header">{ t('contribute.steps.basics.collection.header') }</h6>
                    <div className="card-body">
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="collection">{ t('contribute.steps.basics.collection.label') }</label>
                            <div className="col-md-9">
                                <select className="form-control" id="collection" name="collection" value={collection} onChange={this.handleInputChange}>
                                    <option value="">{ categorization.collection().length === 0 ? t('contribute.steps.basics.collection.empty') : t('contribute.steps.basics.collection.choose') }</option>
                                    { categorization.collection().map( (it) => <option key={it.id} value={it.id}>{ it.label }</option> ) }
                                </select>
                            </div>
                        </div>
                    </div>
                </div> */}
                <BackNextNavigation t={t} navigation={navigation} />
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(StepBasics), { require: UserRole.AUTHOR } )
