/* eslint-disable no-console */

import React from 'react';
import { PermissionBodyTypes, PermissionRowTypes, PermissionListTypes, PermissionMappingTableTypes,TempPermissionRowTypes,RoleArray } from "./Types";
import { HeaderRow } from './Tables';
import ConnectorComponent from '../contribute/ConnectorComponent';
import { buildRequest } from '../../services/Fetcher';
//import datatables from 'datatables.net';
import { endpoints } from '../../config/endpoints';
//import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class TempPermissionRow extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            //id: this.props.value.id,
            kind: this.props.value.kind,
            role: this.props.value.role,
            specification: this.props.value.specification,
        };
    }
    handleInputChange = (event, state) => {
        const target = event.target;
        const value = target.value;
        this.setState({ [state]: value });
    }
    createPermissionObject = () => {
        const permission = {
            //id: this.state.id,
            kind: this.state.kind,
            role: this.state.role,
            specification: this.state.specification
        };
        return permission;
    }
    render() {
        const permission = this.createPermissionObject();
        const t = this.props.t;
        return (
            <tr>
                <td scope="col">
                    <input type="text" value={permission.specification} onChange={(event) => this.handleInputChange(event, 'specification')} />
                </td>
                <td scope="col">
                    <select value={permission.kind} onChange={(event) => this.handleInputChange(event, 'kind')}>
                        {PermissionKindArray.map((it) => <option key={it} value={it}>{t("userManagement." + it)}</option>)}
                    </select>
                </td>
                <td scope="col">
                    <select value={permission.role} onChange={(event) => this.handleInputChange(event, 'role')}>
                        {RoleArray.map((it) => <option key={it} value={it}>{t("userManagement." + it)}</option>)}
                    </select>
                </td>
                <td scope="col">
                    <button type="button" onClick={() => this.props.add(permission)} className="btn btn-warning btn-xs">
                        <FontAwesomeIcon icon="redo" /></button>
                </td>
            </tr>
        );
    }
}

class PermissionRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.value.id,
            kind: this.props.value.kind,
            role: this.props.value.role,
            specification: this.props.value.specification,
        };
    }
    handleInputChange = (event, state) => {
        const target = event.target;
        //const name = target.id;
        //const state = name;
        const value = target.value;
        this.setState({ [state]: value });
    }
    createPermissionObject = () => {
        const permission = {
            id: this.state.id,
            kind: this.state.kind,
            role: this.state.role,
            specification: this.state.specification
        };
        return permission;
    }
    render() {
        const permission = this.createPermissionObject();
        const t = this.props.t;
        return (
            <tr>
                <td scope="col">
                    <input type="text" value={permission.specification} onChange={(event) => this.handleInputChange(event, 'specification')} />
                </td>
                <td scope="col">
                    <select value={permission.kind} disabled>
                        {PermissionKindArray.map((it) => <option key={it} value={it}>{t("userManagement." + it)}</option>)}
                    </select>
                </td>
                <td scope="col">
                    <select value={permission.role} onChange={(event) => this.handleInputChange(event, 'role')}>
                        {RoleArray.map((it) => <option key={it} value={it}>{t("userManagement." + it)}</option>)}
                    </select>
                </td>
                <td scope="col">
                    <button type="button" onClick={() => this.props.update(permission)} className="btn btn-warning btn-xs">
                        <FontAwesomeIcon icon="redo" /></button>
                    <button type="button" onClick={() => this.props.delete(permission)} className="btn btn-danger btn-xs">
                        <FontAwesomeIcon icon="times-circle" />
                    </button>
                </td>
            </tr>
        );
    }
}

class PermissionBody extends React.Component {
    constructor(props) {
        super(props);
    }

    onUpdate = (permission) => {
        this.props.onPermissionChanged(permission, 'edit');
    }

    onDelete = (permission) => {
        this.props.onPermissionChanged(permission, 'remove');
    }
    onAdd = (permission)=>{
        this.props.onPermissionChanged(permission, 'add');
    }


    render() {

        const permissions = this.props.permissions;
        const temporaryPermission = this.props.temporaryPermission;
        const body = [];
        if(temporaryPermission)
        {
        body.push(
                <TempPermissionRow key={temporaryPermission.id} t={this.props.t} value={temporaryPermission} add={this.onAdd} />
            );
        
        }
        permissions.forEach(permission => {
            body.push(
                <PermissionRow key={permission.id} t={this.props.t} value={permission} update={this.onUpdate} delete={this.onDelete} />
            );
        });

        return (
            <tbody>
                {body}
            </tbody>
        );
    }
}

class PermissionMappingTable extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            temporaryPermission:null
        };
    }

    //TODO:deprecated method to force state update due to rerendering
    componentDidUpdate(prevProps)
    {
        if(this.props!==prevProps)
        {this.setState({temporaryPermission:null});}
    }
    //TODO: injection point for the datatable initialization
    componentDidMount() {
{
       // $('#' + this.props.name).DataTable({
       //     dom: '<"data-table-wrapper"t>',
       //     paging: true,
       //     autoWidth: true,
            //data: this.props.data,
            //columns:[
            //     {
            //         title:'id',
            //        // data:'id',
            //         visible:false
            //     },
            // {

            //     title:'specification',
            //     //data:'specification'
            // },
            // {
            //     title:'kind',
            //    // data:'kind'
            // },
            // {
            //     title:'role',
            //    // data:'role'
            // }
            //],
            //             columnDefs:[
            // {
            //     targets:1,
            //     render:function(data,type,full,meta)
            //     {
            //         const input='<input type"text" value="' + data + '"  />';
            //         return input;
            //     },

            // },
            // {
            //     targets:2,
            //     render:function(data,type,full,meta)
            //     {
            //         const link='<a onclick="this.Myfunc(' + data +')"><i class="fa fa-edit"></i></a>';
            //         return link;
            //     }
            // }
            //             ],

       //});
        // $("#" + this.props.name + " div.buttonarea").html('Add row<button type="button" data-func="dt-add" class="btn btn-success btn-xs dt-add"<span class="glyphicon glyphicon-plus" aria-hidden="true"></span></button>')
        // $('.dt-add').each(function () {
        //     $(this).on('click', function(){
        //         const table = $('#' + this.props.name).DataTable();
        //         //Create some data and insert it
        //         const rowDataObject ={
        //             specification:"new entry",
        //             kind:"ORGANIZATIONAL_UNIT",
        //             role:"GUEST"
        //         };
        //         table.row.add(rowDataObject).draw(false);
        // var table = $('#example').DataTable();
        // //Store next row number in array
        // var info = table.page.info();
        // rowData.push('New Entry');
        // //Some description
        // rowData.push('New Order');
        // //Random date
        // var date1 = new Date(2016,01,01);
        // var date2 = new Date(2018,12,31);
        // var rndDate = new Date(+date1 + Math.random() * (date2 - date1));//.toLocaleDateString();
        // rowData.push(rndDate.getFullYear()+'/'+(rndDate.getMonth()+1)+'/'+rndDate.getDate());
        // //Status column
        // rowData.push('NEW');
        // //Amount column
        // rowData.push(Math.floor(Math.random() * 2000) + 1);
        // //Inserting the buttons ???
        // rowData.push('<button type="button" class="btn btn-primary btn-xs dt-edit" style="margin-right:16px;"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></button><button type="button" class="btn btn-danger btn-xs dt-delete"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></button>');
        // //Looping over columns is possible
        // //var colCount = table.columns()[0].length;
        // //for(var i=0; i < colCount; i++){			}

        // //INSERT THE ROW
        // table.row.add(rowData).draw( false );
        //  });
        //});
}
    }

    changeRow = (permission, change) => {
        this.props.onDataTableChange(permission, change);
    }

    addRow = () => {
        //const table = $('#' + this.props.name).DataTable();
        //Create some data and insert it
        const newPermission = {
            id:"nAn",
            specification: "",
            kind: "ORGANIZATIONAL_UNIT",
            role: "GUEST"
        };
        if(this.state.temporaryPermission===null)
        {
            this.setState({temporaryPermission:newPermission});
        }
        //table.row.add(rowDataObject).draw(false);

    }

    //TODO:refactor to datatable, update data by this method see commented section
    // eslint-disable-next-line no-unused-vars
    shouldComponentUpdate(nextProps)
    {
        
        //if (nextProps !== this.props) {
            return true;
        //}
        //     const table = $('.data-table-wrapper').find('table').DataTable();
        //     table.clear();
        //     table.rows.add(nextProps.data);
        //     table.draw();
        // }
        //return false;
    }

    render() {
        return (
            <div>
                <table className="table table-striped" id={this.props.name}>
                    <HeaderRow row={permissionHeader} addRow={this.addRow} t={this.props.t}></HeaderRow>
                    <PermissionBody t={this.props.t} permissions={this.props.data} onPermissionChanged={this.changeRow} temporaryPermission={this.state.temporaryPermission}></PermissionBody>
                </table>
            </div>
            );
    }
}

class PermissionList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            permissions: null,
            technicalError: null,
            
        };
    }

    getPermissions = () => {
        const { connector } = this.props;
        connector.send(buildRequest(endpoints.administration.permission.url(), endpoints.administration.permission.method(), true),
            (json) => {
                if (this.props.connector.hasJson() && this.props.connector.is200()) {
                    //console.log(json);
                    this.setState({ permissions: json, dataAcquired: true });
                }
                else if (connector.hasError() || (connector.hasResponse() && !connector.is200())) {
                    // render occured errors (error information is part of the state of the connector)
                    //console.log(error);

                    this.setState({ permissions: null, dataAcquired: false });
                }
            });
    }

    onDataTableChange = (row, change) => {
        const { connector } = this.props;

        let url, method, includeCredentials, id;
        switch (change) {
            //add entry
            case 'add':
                url = endpoints.administration.permission.create.url;
                method = endpoints.administration.permission.create.method;
                includeCredentials = true;
                break;
            //change entry
            case 'edit':
                url = endpoints.administration.permission.update.url;
                id = row.id;
                method = endpoints.administration.permission.update.method;
                includeCredentials = true;
                break;
            case 'remove':
                url = endpoints.administration.permission.delete.url;
                id = row.id;
                method = endpoints.administration.permission.delete.method;
                includeCredentials = true;
                break;
            default:
        }
        connector.send(buildRequest(url(id), method(), includeCredentials, row),
            (json) => {
                if (this.props.connector.is200()) {console.log(json); this.getPermissions();this.setState({ technicalError:null }); }
                else if (connector.hasError() || (connector.hasResponse() && !connector.is200())) {
                    console.log(json);
                    //something went utterly wrong :-(
                    if (json && json["permissionMappingDto.specification"]) { this.setState({ technicalError: json["permissionMappingDto.specification"] }); }
                }
            }
        );
    }

    renderError = (error) => {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>{this.props.t('userManagement.permission.error')}:</strong> {error}
            </div>
        );
    }

    componentDidMount() {
        this.getPermissions();
    }

    render() {

        const { t, connector } = this.props;
        const { permissions, dataAcquired, technicalError } = this.state;
        const tablename = 'permissionlist';
        return (
            <div className="card">
                <div className="card-body">
                    <h6 className="card-subtitle mb-2">{t('userManagement.permission.header')}</h6>
                    {technicalError ? this.renderError(technicalError) : null}
                    <ConnectorComponent t={t} connector={connector} isRenderChildren={() => dataAcquired === true}>
                        <PermissionMappingTable data={permissions} name={tablename} onDataTableChange={this.onDataTableChange} t={t} />
                    </ConnectorComponent>
                </div>
            </div>
        );
    }
}

const PermissionKindArray = ['EMAIL', 'DEPARTMENT_NUMBER', 'ORGANIZATIONAL_UNIT'];
const permissionHeader = ['specification', 'kind', 'role'];
//const CUDchange =['add','edit','remove'];

PermissionList.propTypes = PermissionListTypes;
PermissionMappingTable.propTypes = PermissionMappingTableTypes;
PermissionBody.propTypes = PermissionBodyTypes;
PermissionRow.propTypes = PermissionRowTypes;
TempPermissionRow.propTypes = TempPermissionRowTypes;
export {
    PermissionBody,
    PermissionRow,
    PermissionMappingTable,
    PermissionList
};