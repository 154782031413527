/**
 * basic react module dependencies
 */
import React from 'react';
import { Redirect } from 'react-router-dom';

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'
import {UserRole} from '../../domain/UserRole'

import {AddUser,LocalUserList} from './LocalUser';
import {IdmUserList,IdmQuery} from './Idm';
import {PermissionList} from './Permission';
/** 
 * ui resources related module dependencies
 */
import {UserManagementTypes} from './Types';
import {UserRoleChecker } from '../../services/Validation';
import {Connector} from '../../services/Fetcher';

import $ from 'jquery';
//import datatables from 'datatables.net';
//import Popper from 'popper.js';
const tabs={
    localusers:1,
    idmusers:2,
    permissions:3,
    addlocal:4,
    idmquery:5
};

export class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actualUserRole : UserRole.ADMIN,
            connector: new Connector(),
            activeTab:tabs.localusers
            //idmUsers: [{ 'id': '2', 'identification': 'wog12btq', 'isActivated': 'aktiv', 'role': 'ADMIN', 'email':'wg@fh.de'}],    
        };
    }

    componentDidMount() {
        // TODO: decide to use a react popper or tooltip library or build an own react component or externalize these functions?
        // use jquery to turn on popper tooltips from bootstrap
        $('[data-toggle="popover"]').popover();
    }

    componentWillUnmount() {
        $('[data-toggle="popover"]').popover('dispose');
    }

    componentWillUpdate() {
        $('[data-toggle="popover"]').popover('dispose');
    }

    componentDidUpdate() {
        // use jquery to turn on popper tooltips from bootstrap
        $('[data-toggle="popover"]').popover();
    }

    activateTab=(tabname)=>
    {
        this.setState({activeTab:tabname});
    }

    render() {
       
        const { t } = this.props;
        const {activeTab} = this.state;
        
        if(!UserRoleChecker.isAdmin(this.state.actualUserRole)){
            return (
                <div>
                    <Redirect to='/' /> 
                </div>
              );
        }
        return(
            <div className="cisComponent">
                <div className="container-fluid">
                    <div className="cisComponentHeader">
                        <div className="row">
                            <div className="col">
                                <h1>{t('userManagement.header')}</h1>
                            </div>
                        </div>
                    </div>
                    <React.StrictMode>
                        <div className="container-fluid cisComponentBody">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col">
                                            <ul className="nav nav-tabs" id="UsermanagementTabs" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="localuser-tab" data-toggle="tab" href="#localuser" role="tab" aria-controls="localuser" aria-selected="true" onClick={()=>this.activateTab(tabs.localusers)}>{t('userManagement.tabs.localuser')}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="idmuser-tab" data-toggle="tab" href="#idmuser" role="tab" aria-controls="idmuser" aria-selected="false" onClick={()=>this.activateTab(tabs.idmusers)}>{t('userManagement.tabs.idmuser')}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="permission-tab" data-toggle="tab" href="#permission" role="tab" aria-controls="permission" aria-selected="false" onClick={()=>this.activateTab(tabs.permissions)}>{t('userManagement.tabs.permissions')}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="adduser-tab" data-toggle="tab" href="#adduser" role="tab" aria-controls="adduser" aria-selected="false" onClick={()=>this.activateTab(tabs.addlocal)}>{t('userManagement.tabs.addlocal')}</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="idmquery-tab" data-toggle="tab" href="#idmquery" role="tab" aria-controls="idmquery" aria-selected="false" onClick={()=>this.activateTab(tabs.idmquery)}>{t('userManagement.tabs.idmquery')}</a>
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content row" id="UsermanagementContent">
                                        <div className="tab-pane fade show active col" id="localuser" role="tabpanel" aria-labelledby="localuser-tab">{activeTab===tabs.localusers ? <LocalUserList t={t} connector={this.state.connector} />:null}</div>
                                        <div className="tab-pane fade col" id="idmuser" role="tabpanel" aria-labelledby="idmuser-tab">{activeTab===tabs.idmusers ? <IdmUserList t={t} connector={this.state.connector} />:null}</div>
                                        <div className="tab-pane fade col" id="permission" role="tabpanel" aria-labelledby="permission-tab">{activeTab===tabs.permissions ? <PermissionList t={t} connector={this.state.connector} />:null}</div>
                                        <div className="tab-pane fade col" id="adduser" role="tabpanel" aria-labelledby="adduser-tab" >{activeTab===tabs.addlocal ? <AddUser t={t} connector={this.state.connector} />:null}</div>
                                        <div className="tab-pane fade col" id="idmquery" role="tabpanel" aria-labelledby="idmquery-tab" >{activeTab===tabs.idmquery ? <IdmQuery t={t} connector={this.state.connector} />:null }</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.StrictMode>
                </div>
            </div>
        );
    }
}
UserManagement.propTypes = UserManagementTypes;

// const PERMISSIONS = [{id:'1',specification:'toh@fh-zwickau.de',idmattribute:'email',role:'ADMIN'}
//         ,{id:'2',specification:'SPR',idmattribute:'organization',role:'AUTHOR'},
//         {id:'3',specification:'17356',idmattribute:'departmentnumber',role:'GUEST'}];

//const localUserHeader = ['username','email','status','role'];
export default AuthHoc( LocalizableHoc()(UserManagement), { require: UserRole.ADMIN } )

//available @v8.0.0 or higher
//export const UserManagementWith18n = withI18n()(UserManagement)
