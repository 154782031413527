
// npm package dependencies: react, popper, font awesome
import React from 'react'

// services: language + translation service, connector states
import { LocalizableHoc } from '../../services/LanguageService'
import { ConnectorState } from '../../services/Fetcher'


/**
 * ConnectorComponent.js
 * @description Provides user notifications based on the state of the connector used to fetch or send data asynchronously.
 * @since 1.5
 */
class ConnectorComponent extends React.Component {
    render() {
        const { t, connector, isRenderChildren, details, isPending } = this.props
        let notification
        let message
        const progressbar = <div className="progress mb-3">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
        switch ( connector.state.value ) {
            case ConnectorState.initialized:
            case ConnectorState.pending:
                notification = progressbar
                break
            case ConnectorState.error:
                if ( connector.hasResponse() === false ) {
                    // no response: usually server not available or timeout or something
                    message = t('connector.connection.serverNotAvailable') + ' (' + connector.error().message + ')'
                } else if ( connector.isOk() ) {
                    // error + successful response: error occured during accessing or parsing the response body
                    message = t('connector.connection.responseBodyError') + connector.code() + ' (' + connector.error().message + ')'
                } else {
                    // error + unsuccessful response: should be a server defined http status code
                    message = t('connector.connection.code.' + connector.code() )
                }
                const translation = connector.hasResponse() === true ? 'connector.connection.errorWithCode' : 'connector.connection.error'
                notification =
                    <div className="alert alert-danger" role="alert">
                        <strong>{ t(translation, { code: connector.code() } ) }</strong> { message }
                        { details !== undefined || details !== null ? details : null }
                    </div>
                break
            case ConnectorState.cancelled:
                notification = 
                    <div className="alert alert-warning" role="alert">
                        <strong>{ t('connector.connection.warning') }</strong> { t('connector.connection.cancelled') }
                    </div>
                break
            case ConnectorState.response:
            case ConnectorState.json:
            default:
                message = t('connector.connection.code.' + connector.code() )
                if ( message.trim().length === 0 ) message = t('connector.connection.code.xyz')
                // note: in case we have details, we render them, despite the connector code
                notification = !details && connector.isOk() === true ? null : 
                    <div className="alert alert-danger" role="alert">
                        { connector.isOk() === true ? null : <span><strong>{ t('connector.connection.errorWithCode', { code: connector.code() } ) }</strong> { message }</span> }
                        { details !== undefined || details !== null ? details : null }
                    </div>
        }
        const children = isRenderChildren( connector ) === true ? this.props.children : null
        return (
            <div>
                { isPending === true && connector.state.value !== ConnectorState.pending ? progressbar : null }
                { notification }
                { children }
            </div>
        )
    }
}

export default LocalizableHoc()(ConnectorComponent)
