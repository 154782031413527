/**
 * basic react module dependencies
 */
import React from 'react'

// language + translation service
import { LocalizableHoc } from '../services/LanguageService'

/**
 * the component provides the functionality to show general privacy related information.
 * 
 * @version 0.0.1
 * @since 1.3.0
 */
export class Privacy extends React.Component {

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() { 
    const { t } = this.props
    return (
      <div className='cisComponent'>
        <div className="container-fluid">
          <div className="cisComponentHeader">
            <div className="row">
              <div className="col">
                <h1>{t('privacy.header')}</h1>
              </div>
            </div>
          </div>

          <div className="cisComponentBody">

            <div className="row">
              <div className="col">
                <p>
                  Wir, ein Team aus Geisteswissenschaftlerinnen und Informatikern der <a href="https://www.fh-zwickau.de/">Westsächsischen Hochschule Zwickau (WHZ)</a>, 
                  entwickelten eine Wissensplattform die hier betrieben wird. 
                  Sie dient einer wissenschaftlich innovativen Aufbereitung interkultureller Erlebnisse für Forschungs-, Lehr- und Lernzwecke. 
                  Unser Ziel ist kulturelle Stereotypen zu hinterfragen und eine interkulturelle Sensibilisierung durch verschiedene Perspektiven auf Missverständnisse zu erzielen.
			</p>

                <div className="cisParagraphHeader">
                  <h3>Name und Anschrift</h3>
                </div>
                <div className="cisParagraphBody">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                      <h5 id="responsible">Datenschutzrechtlich Verantwortlich</h5>
                      <p>
                        Prof. Dr. phil. Doris Fetscher<br />
                        Telefon: +49 375 536 3501<br />
                        E-Mail: <a href="mailto:doris.fetscher@fh-zwickau.de">Doris.Fetscher@fh-zwickau.de</a>
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                      <h5 id="representative"><a href="https://www.fh-zwickau.de/hochschule/gremien/beauftragte-der-whz/">Datenschutzbeauftragte*r der WHZ</a></h5>
                      <p>
                      E-Mail: <a href="mailto:datenschutz@fh-zwickau.de">datenschutz@fh-zwickau.de</a>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                      <h5>Besucheranschrift</h5>
                      <p>
                        Westsächsische Hochschule Zwickau<br />
                        Scheffelstraße 39, Haus 1, Zimmer S1217<br />
                        08066 Zwickau
					</p>
                    </div>
                    <div className="col-auto">
                      <h5>Postanschrift</h5>
                      <p>
                        Westsächsische Hochschule Zwickau<br />
                        Postfach 20 10 37<br />
                        08012 Zwickau
					</p>
                    </div>
                  </div>
                </div>
                <div className="cisParagraphHeader">
                  <h3>Zugriff auf unseren Webauftritt</h3>
                </div>
                <div className="cisParagraphBody">
                  <p>
                    Bei jedem Aufruf unseres Webauftritts erhält unser System automatisch Informationen über das aufrufende (d. h. Ihr) System. Diese Daten werden kommunikationsbedingt übertragen und <em>nicht</em> von unserem System angefragt. Einige dieser Informationen, die folgend aufgelistet sind, werden durch unseren Webserver in sogenannten Log-Dateien für jeden Aufruf gespeichert:
			</p>
                  <ul>
                    <li>IP-Adresse des aufrufenden bzw. Ihres Systems</li>
                    <li>Datum und Uhrzeit des Aufrufs</li>
                    <li>Web-Adresse (URL bzw. Link) die aufgerufen wird</li>
                    <li>Kennung des aufrufenden bzw. ihres Systems: diese beinhaltet typischerweise den Webbrowser mit Versionsnummer sowie das Betriebssystem mit Versionsnummer</li>
                    <li>Status-Code sowie Speichergröße der Antwort unseres Systems zu ihrem Aufruf</li>
                  </ul>
                  <p>
                    Bei der Verbindung mit unserem System erzwingen wir eine verschlüsselte Verbindung nach aktuellem Stand der Technik, sodass kein Dritter Zugriff auf die übertragenen Daten hat. Wir Speichern und Verarbeiten diese Daten nur in dem oben angegebenen Umfang. Mit weiteren personenbezogenen Daten werden diese Daten <em>nicht</em> verknüpft. Wir erstellen auch keine Nutzerprofile aus diesen Daten.
			</p>
                  <dl className="row">
                    <dt className="col-md-3">Zweck der Datenverarbeitung</dt>
                    <dd className="col-md-9">Wir verwenden diese Daten zur Wahrung der technischen Sicherheit und Funktionsfähigkeit sowie für eine unterbrechungsfreie Nutzung unseres Webauftritts.</dd>
                    <dt className="col-md-3">Dauer der Speicherung</dt>
                    <dd className="col-md-9">Nach 4 Wochen werden die Daten gelöscht.</dd>
                    <dt className="col-md-3">Rechtsgrundlage</dt>
                    <dd className="col-md-9">Der Speicherung dieser Daten liegt unser berechtigtes Interesse nach <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. f DSGVO</a> zugrunde.</dd>
                  </dl>
                </div>
                <div className="cisParagraphHeader">
                  <h3 id="account">Erstellung eines Nutzer-Accounts</h3>
                </div>
                <div className="cisParagraphBody">
                  <p>
                    Möchten Sie aktiv zu unserer Wissensplattform beitragen, wird ein Nutzer-Account benötigt. Dieser kann auf unterschiedliche Arten erstellt werden: direkte Registierung oder erstmalige Anmeldung mit einem Hochschul-Account einer unterstützten Hochschule. Bei der Erstellung eines Nutzer-Accounts speichern wir die folgenden Daten von Ihnen ab:
			</p>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                      <h5>Direkte Registierung</h5>
                      <ul>
                        <li>Anmeldekürzel</li>
                        <li>E-Mail-Adresse</li>
                        <li>Datum der Registierung in unserem System</li>
                        <li>verschlüsseltes Passwort</li>
                      </ul>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-6">
                      <h5>Erstmalige Anmeldung mit Hochschul-Account</h5>
                      <ul>
                        <li>Anmeldekürzel</li>
                        <li>E-Mail-Adresse</li>
                        <li>Datum der Registierung in unserem System</li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    Diese Daten werden <em>nicht</em> öffentlich dargestellt sondern dienen nur der internen Zuordnung.
			Erfassen Sie interkulturelle Erlebnisse verknüpfen wir die oben genannten Daten mit denen Ihrer <a href="#incident">interkulturellen Erlebnisse</a>.
			</p>
                  <dl className="row">
                    <dt className="col-md-3">Zweck der Datenverarbeitung</dt>
                    <dd className="col-md-9">Ermöglichen der Anmeldung und der damit verbundenen Nutzung unserer Wissensplattform sowie der Möglichkeit zur Kontaktaufnahme.</dd>
                    <dt className="col-md-3">Dauer der Speicherung</dt>
                    <dd className="col-md-9">Solange Sie registrierter Nutzer unserer Wissensplattform sind.</dd>
                    <dt className="col-md-3">Rechtsgrundlage</dt>
                    <dd className="col-md-9">Der Speicherung dieser Daten liegt ihre Einwilligung gemäß <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. a DSGVO</a> zugrunde.</dd>
                  </dl>
                </div>
                <div className="cisParagraphHeader">
                  <h3>Anmeldung an unserem Webauftritt</h3>
                </div>
                <div className="cisParagraphBody">
                  <p>
                    Unser Webauftritt stellt angemeldeten Nutzern entsprechend ihrer Berechtigung weitere Funktionalität zur Verfügung. Dazu ist es notwendig zu erkennen, ob ein Zugriff auf unseren Webauftritt durch einen angemeldeten Nutzer erfolgt oder nicht. Technisch wird dies über eine zufällig generierte Zeichenfolge realisiert. Diese wird bei erfolgreicher Anmeldung von unserem Webauftritt generiert und im Webbrowser in Form eines <a href="https://de.wikipedia.org/wiki/HTTP-Cookie">Cookies</a> gespeichert. Bei weiteren Aufrufen unseres Webauftritts wird diese Zeichenfolge vom Webbrowser automatisch mit übertragen. Dadurch kann unser System die Aufrufe genau einem Webbrowser zuordnen und diesem die erweiterte Funktionalität bereitstellen.
			</p>
                  <dl className="row">
                    <dt className="col-md-3">Zweck der Datenverarbeitung</dt>
                    <dd className="col-md-9">Technisch notwendige Zuordnung der Aufrufe unseres Webauftritts zu einem Nutzer zur Bereitstellung der Funktionalität entsprechend seiner Berechtigung.</dd>
                    <dt className="col-md-3">Dauer der Speicherung</dt>
                    <dd className="col-md-9">
                      Erfolgt 5h lang kein Aufruf unseres Webauftritts wird die zufällig generierte Zeichenfolge auf dem Server gelöscht und eine Zuordnung zu einem Webbrowser ist nicht mehr möglich. Der Nutzer ist dadurch automatisch vom System abgemeldet.<br />
                      Das Cookie im Webbrowser, dass die zufällig generierte Zeichenfolge ebenso enthält, wird bei einem Neustart des Webbrowsers automatisch gelöscht. Weiterhin bietet jeder Webbrowser eine Funktionalität zum Anzeigen und Löschen von Cookies an.</dd>
                    <dt className="col-md-3">Rechtsgrundlage</dt>
                    <dd className="col-md-9">Der Speicherung dieser Daten liegt unser berechtigtes Interesse nach <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. f DSGVO</a> zugrunde.</dd>
                  </dl>
                  <p>Es werden keine Nutzerprofile auf Basis des Cookies erstellt. Weitere Cookies werden nicht eingesetzt.</p>
                </div>
                <div className="cisParagraphHeader">
                  <h3 id="incident">Interkulturelle Erlebnisse</h3>
                </div>
                <div className="cisParagraphBody">
                  <p>
                    Entscheiden Sie sich Ihre interkulturellen Erlebnisse unserer Wissensplattform zur Verfügung zu stellen, speichern wir die folgenden Daten und verknüpfen diese intern mit Ihrem <a href="#account">Nutzer-Profil</a>:
			</p>
                  <ul>
                    <li>Geschlecht, Altersbereich sowie Nationalitäten des Autors des Erlebnisses</li>
                    <li>Geschlecht, Altersbereich sowie Nationalitäten der Akteure die ebenfalls am Erlebnis beteiligt waren</li>
                    <li>Beschreibung von technischen Systeme, die am Erlebnis beteiligt waren</li>
                    <li>Beschreibung des Erlebnisses als Text, Audioaufnahme, Videoaufnahme oder mit Bildern</li>
                    <li>Ort, wo das Erlebnis stattfindet</li>
                    <li>Sprachen in denen kommuniziert wird</li>
                    <li>Kontaktfelder, in welchen das Ereignis passiert sowie zuordenbare Hotspots</li>
                  </ul>
                  <p>
                    Die meisten dieser Daten gehören zu den besonderen Kategorien personenbezogener Daten, die eines speziellen Schutzes bedürfen. Daher müssen Sie jeglichen Verarbeitungszweck dieser Daten ausdrücklich zustimmen.
			</p>
                  <dl className="row">
                    <dt className="col-md-3">Zweck der Datenverarbeitung</dt>
                    <dd className="col-md-9">Qualitative und quantitative Forschung an interkulturellen Erlebnissen und Missverständnissen sowie die Evaluation unserer Wissensplattform.</dd>
                    <dt className="col-md-3">Dauer der Speicherung</dt>
                    <dd className="col-md-9">Solange Sie Ihre Einwilligung nicht <a href="#revocation">widerrufen</a>. Im Falle der Evaluation unserer Wissensplattform werden mit Abschluss der Evaluation die Daten gelöscht.</dd>
                    <dt className="col-md-3">Rechtsgrundlage</dt>
                    <dd className="col-md-9">Der Speicherung dieser Daten liegt ihre Einwilligung gemäß <a href="https://dsgvo-gesetz.de/art-9-dsgvo/">Art. 9 Abs. 2 lit. a DSGVO</a> zugrunde.</dd>
                  </dl>
                  <p>
                    Die Auswertung und Darstellung dieser Daten erfolgt stets anonymisiert. Die Daten Ihres <a href="#account">Nutzer-Profils</a> werden nie öffentlich dargestellt. Eine Weitergabe der Daten an Dritte erfolgt nicht.
			</p>

                </div>
                <div className="cisParagraphHeader">
                  <h3>Ihre Rechte</h3>
                </div>
                <div className="cisParagraphBody">
                  <p>
                    Sie können sich über die Sie betreffenden personenbezogenen Daten und deren Verarbeitung durch uns gerne bei uns informieren. Ansprechpartner ist der <a href="#responsible">datenschutzrechtlich Verantwortliche</a>. Rechtlich haben Sie zumindest die folgenden Rechte. Um die folgenden Formulierungen zu vereinfachen wird <em>Sie betreffende personenbezogene Daten</em> oft mit <em>die Daten</em> abgekürzt.
			</p>

                  <h5 id="inquiry">Auskunftsrecht</h5>
                  <p>
                    Von dem <a href="#responsible">datenschutzrechtlich Verantwortlichen</a> können Sie eine Bestätigung darüber verlangen,
                    ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. Ist dem so, können Sie Auskunft zu folgenden
                    Informationen verlangen:
			</p>
                  <ol>
                    <li>die Zwecke, zu denen Ihre personenbezogenen Daten verarbeitet werden</li>
                    <li>die Kategorien der verarbeiteten personenbezogenen Daten</li>
                    <li>die Empfänger bzw. Kategorien von Empfängern, gegenüber denen die Daten offengelegt wurden oder noch offengelegt werden</li>
                    <li>die geplante Dauer der Speicherung oder Kriterien für die Festlegung der Speicherdauer, sofern keine konkreten Angaben möglich sind</li>
                    <li>das Bestehen eines Rechts auf Berichtigung oder Löschung, eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchrechts gegen diese Verarbeitung</li>
                    <li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde</li>
                    <li>alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben wurden</li>
                    <li>das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling (gemäß <a href="https://dsgvo-gesetz.de/art-22-dsgvo/">Art. 22 Abs. 1 und 4 DSGVO</a>) und,
				zumindest in diesen Fällen, aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie</li>
                  </ol>
                  <p>
                    Sie können ebenso Auskunft darüber verlangen, ob die Daten in ein Drittland oder eine internationale Organisation übermittelt werden. In Zusammenhang mit einer solchen Übermittlung können Sie eine Unterrichtung über die geeigneten Garantien (gem. <a href="https://dsgvo-gesetz.de/art-46-dsgvo/">Art. 46 DSGVO</a>) verlangen.
			</p>

                  <h5>Recht auf Berichtigung</h5>
                  <p>
                    Sind Ihre personenbezogenen Daten nicht richtig oder unvollständig, können Sie eine unverzügliche Berichtigung bzw. Vervollständigung durch den <a href="#responsible">Verantwortlichen</a> verlangen. Sollte ihr Wunsch unsere Forschungzwecke ernsthaft beeinträchtigen, werden wir versuchen gemeinsam mit Ihnen eine andere Lösung zu finden. Dies kann bei historischen und statistischen Forschungszwecken auftreten.
			</p>


                  <h5>Recht auf Löschung</h5>
                  <p>
                    Sie können eine unverzügliche Löschung ihrer personenbezogenen Daten von unserem <a href="#responsible">Verantwortlichen</a> verlangen, sofern einer der folgenden Gründe zutrifft:
			</p>
                  <ol>
                    <li>die Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig</li>
                    <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung stützt (gemäß <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. a</a> oder <a href="https://dsgvo-gesetz.de/art-9-dsgvo/">Art. 9 Abs. 2 lit. a DSGVO</a>) und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung</li>
                    <li>Sie legen Widerspruch gegen die Verarbeitung ein (gemäß <a href="https://dsgvo-gesetz.de/art-21-dsgvo/">Art. 21 Abs. 1 DSGVO</a> wenn keine vorrangigen berechtigten Gründe für die Verarbeitung vorliegen oder gemäß <a href="https://dsgvo-gesetz.de/art-21-dsgvo/">Art. 21 Abs. 2 DSGVO</a>)</li>
                    <li>die Daten wurden unrechtmäßig verarbeitet</li>
                    <li>der <a href="#responsible">Verantwortliche</a> unterliegt einer rechtlichen Verpflichtung nach dem EU-Recht oder dem Recht der Mitgliedstaaten zur Löschung der Daten</li>
                    <li>die Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft erhoben (gemäß <a href="https://dsgvo-gesetz.de/art-8-dsgvo/">Art. 8 Abs. 1 DSGVO</a>)</li>
                  </ol>
                  <p>
                    Allerdings besteht kein Recht auf Löschung, sofern die Verarbeitung aus einem der folgenden Gründe erforderlich ist:
			</p>
                  <ol>
                    <li>Ausübung des Rechts auf freie Meinungsäußerung und Information</li>
                    <li>Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der EU oder Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde</li>
                    <li>Gründe des öffentlichen Interesses im Bereich der öffentlichen Gesundheit (gemäß <a href="https://dsgvo-gesetz.de/art-9-dsgvo/">Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO</a>)</li>
                    <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungzwecke oder für statistische Zwecke (gemäß <a href="https://dsgvo-gesetz.de/art-89-dsgvo/">Art. 89 Abs. 1 DSGVO</a>), sofern die Löschung die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt</li>
                    <li>Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen</li>
                  </ol>
                  <p>
                    Haben wir Ihre personenbezogenen Daten öffentlich gemacht und sind wir zur Löschung verpflichtet (gemäß <a href="https://dsgvo-gesetz.de/art-17-dsgvo/">Art. 17 Abs. 1 DSGVO</a>), so treffen wir unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um darüber zu informieren, dass Sie die Löschung aller Links, Kopien und Replikationen der Daten verlangt haben.
			</p>

                  <h5>Recht auf Unterrichtung</h5>
                  <p>
                    Sofern Sie ihr Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung geltend gemacht haben, sind wir verpflichtet allen Empfängern, denen diese Daten offengelegt wurden, dies ebenso mitzuteilen. Ausnahmen sind nur bei unverhältnismäßigen Aufwand oder Unmöglichkeit möglich.
			</p>
                  <p>
                    Beachten Sie auch Ihr <a href="#inquiry">Recht auf Auskunft</a> zu diesen Empfängern.
			</p>

                  <h5>Recht auf Datenübertragbarkeit</h5>
                  <p>
                    Sie können die Daten in einem strukturieten, gängigen und maschinenlesbaren Format erhalten.
                    Unter den Voraussetzungen, dass
			</p>
                  <ol>
                    <li>die Verarbeitung auf einer Einwilligung oder einem Vertrag beruht (gemäß <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. a DSGVO</a> oder <a href="https://dsgvo-gesetz.de/art-9-dsgvo/">Art. 9. Abs. 2. lit. a DSGVO</a> bzw. <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. b DSGVO</a>) und</li>
                    <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt</li>
                  </ol>
                  <p>
                    haben Sie das Recht, die Daten einem anderen Verantwortlichen zu übermitteln. Unser <a href="#responsible">Verantwortlicher</a> darf dies nicht behindern. Soweit es technisch machbar ist, haben Sie darüber hinaus das Recht zu erwirken, dass diese Übermittlung direkt von uns zu einem anderen Verantwortlichen erfolgt. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
			</p>
                  <p>
                    Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung, die:
			</p>
                  <ol>
                    <li>für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt, oder</li>
                    <li>in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde</li>
                  </ol>

                  <h5>Recht auf Widerspruch</h5>
                  <p>
                    Sie können aus Gründen ihrer besonderen Situation jederzeit Widerspruch gegen die Verarbeitung der Daten oder Profiling einlegen, die:
			</p>
                  <ul>
                    <li>zur Wahrung einer Aufgabe im öffentlichen Interesse erforderlich ist oder die in Ausübung öffentlicher Gewalt (gemäß <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. e</a>) oder</li>
                    <li>aufgrund eines berechtigten Interesses (gemäß <a href="https://dsgvo-gesetz.de/art-6-dsgvo/">Art. 6 Abs. 1 lit. f</a>)</li>
                  </ul>
                  <p>
                    erfolgt. Der <a href="#responsible">Verantwortliche</a> kann die Daten dann nur noch verarbeiten, sofern er zwingende schutzwürdige Gründe für die Verarbeitung nachweisen kann. Diese könnten sich ergeben, wenn unsere Forschungs- und Statistikzwecke davon ernsthaft beeinträchtigt werden.
			</p>
                  <p>
                    Sie haben die Möglichkeit ihren Widerspruch mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
			</p>

                  <h5 id="revocation">Recht auf Widerruf</h5>
                  <p>
                    Sie können ihre datenschutzrechtliche Einwilligungserklärung jederzeit widerrufen.
			</p>


                  <h5>Recht auf Beschwerde bei einer Aufsichtsbehörde</h5>
                  <p>
                    Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, können Sie bei einer Aufsichtsbehörde Beschwerde einreichen. Dieses Recht steht Ihnen unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs zu. Die Beschwerde erfolgt im Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes. Hier die Anlaufstellen in unserem Fall:
			</p>
                  <ul>
                    <li><a href="#responsible">unser datenschutzrechtlich Verantwortliche</a></li>
                    <li><a href="#representative">Datenschutzbeauftragte*r der Westsächsischen Hochschule Zwickau</a></li>
                    <li><a href="https://www.saechsdsb.de/">Datenschutzbeauftragte*r des Bundeslandes Sachsen</a></li>
                  </ul>
                  <p>
                    Die Aufsichtsbehörde, bei der Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach <a href="https://dsgvo-gesetz.de/art-78-dsgvo/">Art. 78 DSGVO</a>.
			</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LocalizableHoc()(Privacy)