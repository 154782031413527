
/**
 * Enumeration for all user roles used for authorization (based on the backend model).
 */
export const UserRole = {
    ADMIN: 'ADMIN',
    AUTHOR: 'AUTHOR',
    GUEST: 'GUEST',

    values() {
        return [ UserRole.GUEST, UserRole.AUTHOR, UserRole.ADMIN ]
    },
    valueOf( role ) {
        switch ( role ) {
            case UserRole.ADMIN: return UserRole.ADMIN
            case UserRole.AUTHOR: return UserRole.AUTHOR
            default: return UserRole.GUEST
        }
    }
}
