
import React from 'react'

// language + translation service
import { LocalizableHoc } from '../../services/LanguageService'

// authentication service
import { AuthHoc } from '../../services/AuthService'

/**
 * Provides the login form for user authentication.
 * 
 * @version 0.1.0
 * @since 1.0.0
 */
export class Login extends React.Component {

  /**
   * Default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props);

    this.state = {
      identification: '',
      credentials: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  /**
   * sets a specific state value based on the event related ui component
   * @param {*} event the input event from a ui component
   */
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  /**
   * Handles the login submit events and tries to authenticate the system at the backend service
   * @public
   */
  handleSubmit = event => {
    event.preventDefault();

    // note: we use a callback to indicate that errors should be displayed!
    this.props.handleLoginAttempt()
    this.props.authService.login( this.state.identification, this.state.credentials )
    this.resetInputFields()
  }

  /**
   * reset the state values and the input fields for the login form
   */
  resetInputFields() {
    this.setState({
      // identification: '',
      credentials: ''
    })
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t } = this.props
    return (
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="identification"><strong>{t('navigation.login.input.username')}</strong></label>
            <input className="form-control" autoFocus type="text" name="identification" id="identification" placeholder={t('navigation.login.placeholder.username')} onChange={this.handleInputChange} value={this.state.identification} />
          </div>
          <div className="form-group" >
            <label htmlFor="credentials" ><strong>{t('navigation.login.input.password')}</strong></label>
            <input type="password" className="form-control" name="credentials" id="credentials" placeholder={t('navigation.login.placeholder.password')} onChange={this.handleInputChange} value={this.state.credentials} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-secondary">{t('navigation.login.input.submit')}</button>
          </div>
        </form>
    )
  }
}

export default AuthHoc( LocalizableHoc()(Login) )
