
/**
 * Enumeration for all media types.
 * TODO: currently not supported by backend and thus not available in categorization data
 */
export const MediaType = {
    Text: 'TEXT',
    Audio: 'AUDIO',
    Video: 'VIDEO',

    values() {
        return [ MediaType.Text, MediaType.Audio, MediaType.Video ]
    }
}
