import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'

import { UserRole } from '../../domain/UserRole'
import { buildReferenceItemDto, formValue, propertyId } from '../../domain/DataTransferObjects'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'


class StepTitleAndStory extends React.Component {
    constructor( props ) {
        super( props )
        this.handleInputChange = this.handleInputChange.bind( this )
    }
    
    handleInputChange( event ) {
        const target = event.target
        const name = target.name
        // map the name used in the form to the name in the data transfer object
        const propname = name === 'narrativePerspective' ? 'perspective' : name
        // map the string value of the input into the appropriate value for the data transfer object
        // map the string values to: title + story are send as text, is transcribed as boolean + narrative perspective as integer
        const value = name === 'title' || name === 'story' ? target.value : name === 'narrativePerspective' ? buildReferenceItemDto( target.value ) : target.checked
        // create a shallow copy of the data transfer object with the property value changed + fire on change for dto
        if ( name === 'title' ) {
            const experience = Object.assign( {}, this.props.contribution.experience, { [propname]: value } )
            this.props.callbacks.onExperienceChange( experience )
        } else {
            const textStory = Object.assign( {}, this.props.contribution.textStory, { [propname]: value } )
            this.props.callbacks.onTextStoryChange( textStory )
        }
    }
    
    render() {
        const { t, navigation, categorization, wasValidated, authService } = this.props
        const { experience, textStory } = this.props.contribution
        // extract editable properties of this step
        const title = formValue( experience.title )
        const narrativePerspective = formValue( propertyId( textStory.perspective ) )
        const isTranscribed = textStory.isTranscribed
        const story = formValue( textStory.story )
        // see step one for why we need to switch on invalid feedback manually
        const showInvalidFeedbackNarrativePerspective = wasValidated === true && authService.isAuthor() && !( narrativePerspective !== undefined && categorization.narrativePerspective().some( (it) => it.id === narrativePerspective ) )
        return (
            <div>
                <div className="card">
                    <h6 className="card-header">{ t('contribute.steps.titleAndStory.title.header') }</h6>
                    <div className="card-body">
                        <div className="row form-group">
                            <div className="col">
                                <input className="form-control" type="text" id="title" name="title" autoComplete="off" required={true} placeholder={t('contribute.steps.titleAndStory.title.placeholder.title')} value={title} onChange={this.handleInputChange} />
                                <div className="invalid-feedback">{ t('contribute.steps.titleAndStory.title.validation.title') }</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <h6 className="card-header">{ t('contribute.steps.titleAndStory.text.header') }</h6>
                    <div className="card-body">
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.titleAndStory.text.label.perspective') }</legend>
                                <div className="col-md-9">
                                    { categorization.narrativePerspective().map( (it, i) =>
                                        <div key={it.id} className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" id={'narrativePerspective' +it.id} name="narrativePerspective" value={it.id} required={ authService.isAuthor() } checked={narrativePerspective === it.id} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={'narrativePerspective' +it.id}>{ it.label }</label>
                                        </div>
                                    ) }
                                    { showInvalidFeedbackNarrativePerspective === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.titleAndStory.text.validation.perspective') }</div> : null }
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.titleAndStory.text.label.transcribed') }</legend>
                                <div className="col-md-9">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id={'isTranscribed'} name="isTranscribed" checked={isTranscribed === true} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor={'isTranscribed'}>{ t('contribute.steps.titleAndStory.text.label.isTranscribed') }</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div className="row form-group">
                            <div className="col">
                                <textarea className="form-control" rows="10" name="story" autoComplete="off" required={ authService.isAuthor() } placeholder={t('contribute.steps.titleAndStory.text.placeholder.story')} value={story} onChange={this.handleInputChange} />
                                <div className="invalid-feedback">{ t('contribute.steps.titleAndStory.text.validation.story') }</div>
                            </div>
                        </div>
                    </div>
                </div>
                <BackNextNavigation t={t} navigation={navigation} />
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(StepTitleAndStory), { require: UserRole.AUTHOR } )
