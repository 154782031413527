/**
 * basic react module dependencies
 */
import React from 'react'
import ReactDOM from 'react-dom'

/**
 * custom cis related module dependencies
 */
import App from './App'
import registerServiceWorker from './registerServiceWorker'

/**
 * basic (npm-based) module related stylesheet dependencies
 */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-table/react-table.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

/**
 * custom cis related stylesheet imports
 */
import './App.css'

/**
 * basic (npm-based) module related javascript libary dependencies 
 */
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'jquery/dist/jquery.min.js'

/**
 * rendering the main app component into the DOM
 */
ReactDOM.render(<App />, document.getElementById('root'))
registerServiceWorker()
