
/**
 * Enumeration with all categories used in the reference data dto from the backend.
 */
export const Category = {
    ActorType: 'ACTOR_TYPE',
    Gender: 'GENDER',
    Nationality: 'NATIONALITY',
    Country: 'COUNTRY',
    Language: 'LANGUAGE',
    ExperienceType: 'EXPERIENCE_TYPE',
    InteractionMedium: 'INTERACTION_MEDIUM',
    DataKind: 'DATA_KIND',
    NarrativePerspective: 'NARRATIVE_PERSPECTIVE',
    ContactDomain: 'CONTACT_DOMAIN',
    Hotspot: 'HOTSPOT',
    CommunicationDomain: 'COMMUNICATION_DOMAIN',
    FamiliarityLevel: 'FAMILIARITY_LEVEL',
    RightOfUse: 'RIGHT_OF_USE',
    License: 'LICENSE',
    DataOrigin: 'DATA_ORIGIN',
    ParticularPlace: 'PARTICULAR_PLACE',
    Collection: 'COLLECTION'
}

/**
 * Enumeration with all actor types of the domain model.
 */
export const ActorTypes = {
    Human: 'human',
    System: 'system',
    Group: 'group'
}

/**
 * Enumeration with yes and no literals.
 */
export const YesNoEnum = {
    Yes: 'YES',
    No: 'NO',

    values() {
        return [ YesNoEnum.Yes, YesNoEnum.No ]
    }
}

/**
 * Enumeration with all scopes for the right of use.
 */
export const RightOfUseScope = {
    CONTRIBUTOR: 'contributor',
    ADMIN: 'admin'
}

/** Enumeration with frondend related right of use domain identifiers. */
export const RightOfUse = {
    PRIVACY_POLICY: 'contributor_acceptance_privacy_policy',
    PUBLICITY: 'admin_allow_view_by_public'
}

/** Enumeration with frontend related particular place domain identifiers. */
export const ParticularPlace = {
    Country: 'country'
}

/** Enumeration with all familiarity level domain identifiers. */
export const FamiliarityLevel = {
    Familiar: 'familiar',
    Unfamiliar: 'unfamiliar'
}


/**
 * A wrapper class for the reference data dto from the backend with all categrization data.
 */
export class CategorizationData {
    constructor( json ) {
        this.state = {
            data: json
        }
    }
    actorType = () => this.state.data[0][ Category.ActorType ]
    gender = () => this.state.data[1][ Category.Gender ]
    nationality = () => this.state.data[2][ Category.Nationality ]
    country = () => this.state.data[3][ Category.Country ]
    language = () => this.state.data[4][ Category.Language ]
    experienceType = () => this.state.data[5][ Category.ExperienceType ]
    interactionMedium = () => this.state.data[6][ Category.InteractionMedium ]
    dataKind = () => this.state.data[7][ Category.DataKind ]
    narrativePerspective = () => this.state.data[8][ Category.NarrativePerspective ]
    contactDomain = () => this.state.data[9][ Category.ContactDomain ]
    hotspot = () => this.state.data[10][ Category.Hotspot ]
    communicationDomain = () => this.state.data[11][ Category.CommunicationDomain ]
    familiarityLevel = () => this.state.data[12][ Category.FamiliarityLevel ]
    rightOfUse = () => this.state.data[13][ Category.RightOfUse ]
    license = () => this.state.data[14][ Category.License ]
    dataOrigin = () => this.state.data[15][ Category.DataOrigin ]
    particularPlace = () => this.state.data[16][ Category.ParticularPlace ]
    collection = () => this.state.data[17][ Category.Collection ]


    /** Returns the categorization item referenced from the given property (reference item dto) of the lookup array or undefined if not found. */
    item( array, property ) {
        if ( !property || !property.id ) return undefined
        const it = array.find( (it) => it.id === property.id )
        if ( !it ) return undefined
        return it
    }

    /** Returns the label property of the categorization item in the array which is identified by the given property id or undefined. */
    label( array, property ) {
        const result = this.item( array, property )
        return result === undefined ? undefined : result.label
    }

    /** Returns the specification property of the categorization item in the array which is identified by the given property id or undefined. */
    specification( array, property ) {
        const result = this.item( array, property )
        return result === undefined ? undefined : result.specification
    }

    /** Returns the actor type specification for the given actor type reference item dto. */
    actorTypeByProperty( property ) {
        return this.specification( this.actorType(), property )
    }

    /** Returns true if the given actor type property denotes the given actor type, otherwise false. */
    isActorType( property, type = ActorTypes.Human ) {
        return type === this.actorTypeByProperty( property )
    }

    /** Returns true if the given property (reference item dto) references the given familiarity level, otherwise false. */
    isFamiliarityLevel( property, type = FamiliarityLevel.Familiar ) {
        return type === this.specification( this.familiarityLevel(), property )
    }

    /** Returns true if the given property (reference item dto) references the given right of use, otherwise false. */
    isRightOfUse( property, type = RightOfUse.PRIVACY_POLICY ) {
        return type === this.specification( this.rightOfUse(), property )
    }

    /** Returns true if the given place id denotes that the experience happend in a country and thus the country is required, otherwise false. */
    isCountryRequired( placeId ) {
        return placeId === undefined 
            ? false 
            : this.particularPlace().find( (it) => it.id === placeId && it.specification === ParticularPlace.Country ) !== undefined
    }

    /** Returns true if the privacy policy is accepted, otherwise false. */
    isPrivacyPolicyAccepted( grants ) {
        if ( grants === undefined || grants === null || ( Array.isArray( grants ) && grants.length === 0 ) ) {
            return false
        }
        const privacy_accept = this.rightOfUse().find( it => it.specification === RightOfUse.PRIVACY_POLICY )
        return grants.some( (it) => it.id === privacy_accept.id )
    }
}
