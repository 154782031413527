import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//represents an input field + search button
//creates a string with empty space as seperator 
export class SearchField extends React.Component {
    constructor(props){
        super(props);
        this.state = {value: ''};
        this.handleChange = this.handleChange.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
    }
    //TODO:realtime state update is not so clever
    handleChange(event){
        //const keyWordList = event.target.value.split(" ");
        this.setState({
            value:event.target.value
        });
    }
    onSearchClick(event)
    {
        if(this.props.onSearchClick)
        {
            this.props.onSearchClick(this.state.value,event);
            
        }
    }
    onFocusLost=(event)=>
    {
        this.onSearchClick(event);
    }

    render() {
        const { t } = this.props;
        return (
            <div className="input-group">
                <div className="input-group-prepend"> <span className="input-group-text" id="search-title">{t('overview.search.title')}</span></div>

                <input
                    onChange={this.handleChange} onBlur={this.onFocusLost}
                    type="text"
                    className="form-control" placeholder={t('overview.search.placeholder')} aria-label="search-input" aria-describedby="search-title"
                    value={this.state.value}
                />

                <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={this.onSearchClick}>
                    <FontAwesomeIcon icon="search" />
                </button>
            </div>
        )
    }
}
export default SearchField;