/**
 * config.js
 * @description contains several config values for frontend functions (e.g. backend fetch parameters)
 * @version 1.0.0
 * @since 1.2.3
 */

/**
 * global config object with all necessary parameters for several react components (e.g. carousel style)
 */
export const config = {
    carousel: {
        arrows: false,
        centerMode: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 7000,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1
    },
    fetchData: {
        offset: 0,
        quantity: 5
    },
    readmore: {
        lines: 3
    },
    columns: {
        localUser: {

        },
        idmUser: {

        }
    }
};