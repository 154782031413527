/**
 * endpoints.js
 * @description provides api endpoint information of the backend rest interface
 * @since 1.5
 */

/** api roots for rest backend */
const sessionRoot = process.env.REACT_APP_BACKEND_API_ROOT + 'session/';
const experienceRoot = process.env.REACT_APP_BACKEND_API_ROOT + 'experience/';
const actorRoot = process.env.REACT_APP_BACKEND_API_ROOT + 'actor/';
const collectionRoot = process.env.REACT_APP_BACKEND_API_ROOT + 'collection/';
const categorizationRoot = process.env.REACT_APP_BACKEND_API_ROOT + 'categorization/';
const administrationRoot = process.env.REACT_APP_BACKEND_API_ROOT + 'administration/';
const userRoot = 'user/';

/** Config object with api endpoint urls and methods of the backend service. */
export const endpoints = {
    authentication: {
        login: {
            url: () => sessionRoot + 'authenticate/',
            method: () => 'POST'
        },
        logout: {
            url: () => sessionRoot,
            method: () => 'DELETE'
        },
        check: {
            url: () => sessionRoot,
            method: () => 'GET'
        }
    },
    administration: {
        localUser: {
            url:()=>administrationRoot + userRoot + 'local/',
            method:()=>'GET',
            create:
            {
                url:()=>administrationRoot + userRoot + 'add/',
                method:()=>'POST',
            } 
        },
        idmUser: {
            url:()=>administrationRoot + userRoot +'idm/',
            method:()=>'GET',
            query:
            {
                url:()=>administrationRoot + 'idmquery/',
                method:()=>'POST'
            }
        },
        //requires id for url
        updateUser:{
            url:(id)=>administrationRoot + userRoot + id,
            method:()=>'PUT'
        },
        permission:{ 
            url:()=> administrationRoot + 'permission/',
            method:()=>'GET',
            create:
            {
                url:()=> administrationRoot + 'permission/',
                method:()=>'POST',
            },
            update:
            {
                url:(id)=> administrationRoot + 'permission/' + id,
                method:()=>'PUT',
            },
            //requires id for url
            delete:
            {
                url:(id)=> administrationRoot + 'permission/' + id,
                method:()=>'DELETE',
            }
        }
    },
    experiences: {
        all: experienceRoot,
        single: experienceRoot,
        query:{
            url:(isRequestingCount)=> isRequestingCount===true?experienceRoot + 'count/':experienceRoot + 'query/',
            method:(isRequestingCount)=>isRequestingCount===true?'GET':'POST'
        },
        //query: experienceRoot + 'query/',
        mediacheck: experienceRoot + 'mediacheck/',
        url: (id, isEdit) => id === undefined ? experienceRoot : isEdit === true ? experienceRoot + id + '/edit' : experienceRoot + id,
        method: (id, isEdit) => id === undefined ? 'POST' : isEdit === true ? 'GET' : 'PUT'
    },
    textStory: {
        url: (id) => experienceRoot + id + '/story',
        method: () => 'PUT'
    },
    author: {
        url: (id) => experienceRoot + id + '/author',
        method: () => 'PUT'
    },
    reflection: {
        url: (id) => experienceRoot + id + '/reflection',
        method: () => 'PUT'
    },
    location: {
        url: (id) => experienceRoot + id + '/location',
        method: () => 'PUT'
    },
    rightsOfUse: {
        url: (id) => experienceRoot + id + '/grants',
        method: () => 'PUT'
    },
    actors: {
        url: (experienceId, id) => id === undefined ? experienceRoot + experienceId + '/actors' : actorRoot + id,
        method: (id) => id === undefined ? 'POST' : 'PUT'
    },
    collections: {
        all: collectionRoot,
        contributable: collectionRoot + 'contributable/'
    },
    categorization: {
        all: categorizationRoot,
        url: () => categorizationRoot,
        method: () => 'GET'
    }
};
