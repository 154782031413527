/**
 * basic react module dependencies
 */
import React from 'react'
import { Col, Row, Container } from 'reactstrap'
import Slider from 'react-slick'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../services/LanguageService'
import { AuthHoc } from '../services/AuthService'

/**
 * custom cis related helper dependencies
 */
import { config } from '../config/config'

/**
 * ui resources related module dependencies
 */
import imgRead from '../resources/images/start-carousel-read.jpg'
import imgCapture from '../resources/images/start-carousel-capture.jpg'
import imgDiscuss from '../resources/images/start-carousel-discuss.jpg'


/**
 * The component provides the functionality to show system and project information for the visiting user
 * 
 * @version 0.1.0
 * @since 1.0.0
 */
export class Start extends React.Component {

  /**
   * default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props);

    this.state = {
      sliderSettings: config.carousel
    }
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {

    const { t } = this.props
    const sliderSettings = this.state.sliderSettings

    return (
      <div className="landingGlobal">

        <div className="cisComponentHeader">
          <Row>
            <Col>
              <h1 className="text-center">{t('start.application.title')} – {t('start.application.subtitle')}</h1>
            </Col>
          </Row>
        </div>
        <div className="contentContainer">
          <div className='component-main'>
            <div >
              <Container>
                <Row>
                  <Col>
                    <Slider {...sliderSettings}>
                      <Container className=" topGap">
                        <Row>
                          <Col className="col-sm-12 col-md-6 col-lg-7 col-xl-7 mt-auto mb-auto">
                            <h3 className="slider-header">{t('start.carousel.slideOne.header')}</h3>
                            <p>{t('start.carousel.slideOne.description')}</p>
                          </Col>
                          <Col className="col-sm-12 col-md-6 col-lg-5 col-xl-5 mt-auto mb-auto">
                            <div className="polaroid-images">
                              <a href="" title={t('start.carousel.slideOne.photoTitle')}><img src={imgCapture} className="img-fluid" alt={t('start.carousel.slideOne.photoAlternativeText')} /></a>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Container className="topGap">
                        <Row>
                          <Col className="col-md-7 mt-auto mb-auto">
                            <h3 className="slider-header">{t('start.carousel.slideTwo.header')}</h3>
                            <p>{t('start.carousel.slideTwo.description')}</p>
                            <p className="slider-line">{t('start.carousel.slideTwo.tagline')}</p>
                          </Col>
                          <Col className="col-sm-12 col-md-6 col-lg-5 col-xl-5 mt-auto mb-auto">
                            <div className="polaroid-images">
                              <a href="" title={t('start.carousel.slideTwo.photoTitle')}><img src={imgRead} className="img-fluid" alt={t('start.carousel.slideTwo.photoAlternativeText')} /></a>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <Container className="topGap">
                        <Row>
                          <Col className="col-md-7 mt-auto mb-auto">
                            <h3 className="slider-header">{t('start.carousel.slideThree.header')}</h3>
                            <p>{t('start.carousel.slideThree.description')}</p>
                            <p className="slider-line">{t('start.carousel.slideThree.tagline')}</p>
                          </Col>
                          <Col className="col-sm-12 col-md-6 col-lg-5 col-xl-5 mt-auto mb-auto">
                            <div className="polaroid-images">
                              <a href="" title={t('start.carousel.slideThree.photoTitle')}><img src={imgDiscuss} className="img-fluid" alt={t('start.carousel.slideThree.photoAlternativeText')} /></a>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Slider>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="aboutContainer">
              <Container>
                <Row>
                  <Col>
                    <div className="cisParagraph">
                      <div className="cisParagraphHeader">
                        <h3>{t('start.about.header')}</h3>
                      </div>
                      <div className="cisParagraphBody">
                        <p>{t('start.about.paragraphOne')}</p>
                        <p>{t('start.about.paragraphTwo')}</p>
                        <p>{t('start.about.paragraphThree')}</p>
                        <p>{t('start.about.paragraphFour')}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthHoc( LocalizableHoc()(Start) )
