/**
 * SingleValueInput.js
 * @description Provides an input group with label and input field for single value inputs including suggestion list and validation.
 * 
 * @since 1.5
 */

// npm package dependencies: react, popper, font awesome
import React from 'react'
import Popper from 'popper.js'

// services: language + translation service
import { LocalizableHoc } from '../../services/LanguageService'

// view + utilities
import { initTextInputItem, buildTextInputItem, formObject } from '../../domain/DataTransferObjects'


export class SingleValueInput extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            input: initTextInputItem( this.props.value, this.props.datalist ),
            suggestions: [],
            index: 0,
            isSuggestDialogOpen: false,
            isDialogAutomaticallyControlled: true,
            // indicates if suggestions are calculated and displayed to the user
            isSuggestionDisabled: false,
        }

        this.reference = undefined
        this.popup = undefined
        this.popper = undefined

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        if ( this.popup !== undefined ) this.popper = new Popper( this.reference, this.popup, { placement: 'bottom-end' } )
    }

    componentWillUnmount() {
        if ( this.popper !== undefined) this.popper.destroy()
    }

    buildSuggestions( input, minLength ) {
        if ( this.props.datalist === undefined || this.props.datalist.length === 0 ) return []
        if ( minLength === undefined ) minLength = 0
        const value = String( input ).trim().toLowerCase()
        if ( value.length < minLength ) return []
        if ( value.length === 0 ) return this.props.datalist
        // TODO: add configuration to switch between starts with or contains and the number of characters suggestions appear
        // TODO: remove already chosen values from suggestion list
        const result = this.props.datalist.filter( (it) => String( it.label ).toLowerCase().startsWith( value ) )
        return result
    }

    handleInputChange( event ) {
        const target = event.target
        const propname = target.name
        const { input } = this.state
        if ( event.type === 'keydown' && event.key === 'Enter' && this.state.isSuggestDialogOpen === true ) {
            // note: prevent any form submit button to be triggered
            event.preventDefault()
            // put the value of the currently selected item in the suggestion list into the text field
            const value = this.state.suggestions[this.state.index]
            if ( value === undefined ) return
            // fire value change + close suggest dialog + reset suggestions
            const result = buildTextInputItem( value.id, value.label, true )
            this.props.onValueChange( result )
            this.setState( { input: result, suggestions: [], index: 0, isSuggestDialogOpen: false } )
        } else if ( event.type === 'click' && propname === 'choose' ) {
            // put the clicked item of the suggestion list into input field
            const value = this.state.suggestions[ target.dataset.index ]
            if ( value === undefined ) return
            // fire value change + close suggest dialog + reset suggestions
            const result = buildTextInputItem( value.id, value.label, true )
            this.props.onValueChange( result )
            this.setState( { input: result, suggestions: [], index: 0, isSuggestDialogOpen: false } )
        } else if ( event.type === 'click' && propname === 'toggle' ) {
            // disable suggestions or manually show the current suggestions, possibly all values in case if input is empty
            const state = {}
            if ( this.state.isSuggestDialogOpen === true ) {
                state.isSuggestDialogOpen = false
                if ( this.state.isDialogAutomaticallyControlled === true ) {
                    state.isDialogAutomaticallyControlled = false
                    state.isSuggestionDisabled = true
                }
            } else {
                state.isSuggestionDisabled = false
                state.suggestions = this.buildSuggestions( input.label, 0 )
                state.index = 0
                state.isSuggestDialogOpen = state.suggestions.length > 0
                if ( state.isSuggestDialogOpen === true ) this.popper.scheduleUpdate()
            }
            this.setState( state )
        } else if ( event.type === 'keydown' && ( event.key === 'ArrowDown' || event.key === 'ArrowUp' ) ) {
            // select previous or next item of the suggestion list
            event.preventDefault()
            let index = this.state.index + ( event.key === 'ArrowDown' ? +1 : -1 )
            if ( index === -1 ) {
                index = this.state.suggestions.length -1
            } else if ( index === this.state.suggestions.length ) {
                index = 0
            }
            this.setState( { index: index } )
        } else if ( event.type === 'change') {
            // update the suggestions if enabled
            // reset the id of the current input in case of an official categorization item
            const state = {}
            if ( this.state.isSuggestionDisabled === false ) {
                state.suggestions = this.buildSuggestions( target.value, 1 )
                state.index = 0
                state.isSuggestDialogOpen = state.suggestions.length > 0
                if ( state.isSuggestDialogOpen === true ) {
                    state.isDialogAutomaticallyControlled = true
                    this.popper.scheduleUpdate()
                } else {
                    // in case the dialog was automatically closed due to no suggestions, we enable the automatic show
                    state.isDialogAutomaticallyControlled = true
                }
            }
            // fire value change with the id reset to undefined in case of offical categorization items + close suggest dialog + reset suggestions
            state.input = buildTextInputItem( input.isOfficial === true ? undefined : input.id, target.value, false )
            this.props.onValueChange( state.input )
            this.setState( state )
        }
    }

    render() {
        const { value, datalist, id, isRequired, placeholder, ariaToggleSuggestions } = this.props
        const { suggestions, index, isSuggestDialogOpen } = this.state
        const fieldValue = formObject( value )
        const isAbleToSuggest = datalist !== undefined && datalist.length > 0 ? true : false
        // note: as this input component fires on value changes only after button push or enter key press, the browser validation doesn't work as expected
        // as the text input may contain input data, but the values property is still empty in case data was input but without button push or enter key press!
        return (
            <div>
                <div className="input-group">
                    <input className="form-control" type="text" name="input" autoComplete="off" id={id} value={fieldValue.label} required={isRequired} placeholder={placeholder}
                            onChange={this.handleInputChange} onKeyDown={this.handleInputChange}/>
                    <div className="input-group-append">
                        { isAbleToSuggest === false ? null : 
                            <button className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" type="button" name="toggle" aria-haspopup="true" aria-expanded="false" 
                                ref={ (elem) => this.reference = elem } onClick={this.handleInputChange}>
                            <span className="sr-only">{ariaToggleSuggestions}</span>
                        </button> }
                    </div>
                </div>
                { isAbleToSuggest === false ? null :
                    <div ref={ (elem) => this.popup = elem } className={isSuggestDialogOpen === false ? "dropdown-menu" : "dropdown-menu d-block"} style={ { maxHeight: '20rem', overflowY: 'auto' } }>
                        { suggestions.map( (it, i) => <button className="dropdown-item" type="button" style={ i === index ? { cursor: 'pointer', backgroundColor: '#dae0e5' } : { cursor: 'pointer' } } 
                            name="choose" key={it.id} data-index={i} onClick={this.handleInputChange}>{ it.label }</button> ) }
                </div> }
            </div>
        )
    }
}

export default LocalizableHoc()(SingleValueInput)
