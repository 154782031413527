import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'
import { buildActorString } from '../../services/Aggregator'

import { UserRole } from '../../domain/UserRole'
import { ActorTypes } from '../../domain/CategorizationData'
import { buildReferenceItemDto, buildReferenceLocalizedItemDto, propertyId, formValue, formObject, cloneObject, formArray, buildNumberValue } from '../../domain/DataTransferObjects'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'
import MultiValueInput from './MultiValueInput'


class StepActors extends React.Component {
    constructor( props ) {
        super( props )

        this.state = {
            currentActorIndex: 0
        }

        this.handleAddActor = this.handleAddActor.bind( this )
        this.handleRemoveActor = this.handleRemoveActor.bind( this )
        this.handleActorChange = this.handleActorChange.bind( this )
        this.handleLanguageChange = this.handleLanguageChange.bind( this )
        this.handleNationalityChange = this.handleNationalityChange.bind( this )
        this.handleInputChange = this.handleInputChange.bind( this )

        this.buildActorListItem = this.buildActorListItem.bind( this )
        this.buildActorHeader = this.buildActorHeader.bind( this )
        this.buildActorBody = this.buildActorBody.bind( this )
    }

    /** Adds an empty actor to the end of the actor list. */
    handleAddActor( event ) {
        // note: we want to initially start editing the first actor thus actors is empty and add should add a second actor
        const { actors: lookup } = this.props.contribution
        const actors = lookup.length === 0 ? [ {} ] : lookup.slice()
        const index = actors.push( {} ) -1
        this.props.callbacks.onActorsChange( actors, index )
        this.setState( { currentActorIndex: index } )
    }

    /** Removes the actor with the provided data-id from the actor list. */
    handleRemoveActor( event ) {
        // TODO: as we are using buttons to trigger this event, we could use the value attribute instead of data attributes
        const id = Number.parseInt( event.target.dataset.id !== undefined ? event.target.dataset.id : event.target.parentElement.dataset.id )
        const { actors } = this.props.contribution
        if ( Number.isNaN( id ) === true || id >= actors.length ) return
        const result = actors.filter( (it, i) => i === id ? false : true )
        this.props.callbacks.onActorsChange( result, id )
        this.setState( {
            currentActorIndex: id <= this.state.currentActorIndex && this.state.currentActorIndex !== 0 ? this.state.currentActorIndex -1 : this.state.currentActorIndex
        } )
    }

    /** Changes the currently editable actor to the provided data-id index of the actor list. */
    handleActorChange( event ) {
        const id = Number.parseInt( event.target.dataset.id )
        if ( Number.isNaN( id ) === true || id < 0 || id >= this.props.contribution.actors.length ) return
        this.setState( { currentActorIndex: id } )
    }

    /** Callback function from the multi value input component for languages. */
    handleLanguageChange( languages ) {
        if ( Array.isArray( languages ) === false ) return
        const { actors } = this.props.contribution
        const actor = Object.assign( {}, actors[ this.state.currentActorIndex ], { 'languages': languages } )
        const result = actors.length === 0 ? [ actor ] : actors.map( (it, i) => i === this.state.currentActorIndex ? actor : it )
        this.props.callbacks.onActorsChange( result, this.state.currentActorIndex )
    }

    /** Callback function from the multi value input component for nationalities. */
    handleNationalityChange( nationalities ) {
        if ( Array.isArray( nationalities ) === false ) return
        const { actors } = this.props.contribution
        const actor = Object.assign( {}, actors[ this.state.currentActorIndex ], { 'nationalities': nationalities } )
        const result = actors.length === 0 ? [ actor ] : actors.map( (it, i) => i === this.state.currentActorIndex ? actor : it )
        this.props.callbacks.onActorsChange( result, this.state.currentActorIndex )
    }
    
    /** Changes property values of the current actor being edited. */
    handleInputChange( event ) {
        const target = event.target
        const name = target.name
        // map the id and string value of the input into the appropriate property value of the data transfer object
        const id = target.dataset.id
        // build the property value of the actor
        const value = name === 'type' || name === 'familiarityLevel' ? buildReferenceItemDto( target.value )
                // note: the build function trims the input (for good reason), thus we are not able to input more than one word, thus we do this workaround
                : name === 'systemSpecification' ? Object.assign( {}, buildReferenceLocalizedItemDto( id ), { label: target.value } )
                : name === 'minAge' || name === 'maxAge' || name === 'extendOfGroup' ? buildNumberValue( target.value )
                : name === 'gender' && target.value !== 'mixedGender' ? buildReferenceItemDto( target.value )
                : name === 'gender' && target.value === 'mixedGender' ? target.checked
                : target.checked
        // note: we need to map the additional gender value 'mixed' for group actors to the property isMixedGender
        const propname = name === 'gender' && target.value === 'mixedGender' ? 'isMixedGender' : name
        // obtain old actor
        const { actors } = this.props.contribution
        const old = actors.length === 0 ? {} : actors[ this.state.currentActorIndex ]
        const oldType = this.props.categorization.actorTypeByProperty( old.type )
        let actor
        if ( name === 'type' ) {
            // migrate all properties supported by the target actor type
            const type = this.props.categorization.actorTypeByProperty( value )
            if ( type === ActorTypes.System ) {
                actor = cloneObject( old, 'id', 'languages', 'systemSpecification' )
            } else if ( type === ActorTypes.Human ) {
                actor = cloneObject( old, 'id', 'languages', 'minAge', 'maxAge', 'gender', 'nationalities' )
                // note: we initialize the property isAuthor with false - the user is able to change it in the separate author information step
                Object.assign( actor, { 'isAuthor': false } )
            } else if ( type === ActorTypes.Group ) {
                actor = cloneObject( old, 'id', 'languages', 'minAge', 'maxAge', 'gender', 'nationalities', 'familiarityLevel', 'extendOfGroup', 'isMixedGender' )
            }
            // assign new actor type
            Object.assign( actor, { 'type': value } )
        } else if ( oldType === ActorTypes.Group && ( propname === 'gender' || propname === 'isMixedGender' ) ) {
            // note: isMixedGender property must be present, gender property is only allowed when mixed gender is set to false
            actor = cloneObject( old, 'id', 'type', 'languages', 'minAge', 'maxAge', 'nationalities', 'familiarityLevel', 'extendOfGroup' )
            if ( propname === 'isMixedGender' ) Object.assign( actor, { [propname]: value } )
            if ( propname === 'gender' ) Object.assign( actor, { [propname]: value, isMixedGender: false } )
        } else {
            // create a shallow copy of the data transfer object with the property value changed + fire on change for dto
            actor = Object.assign( {}, old, { [propname]: value } )
        }
        // note: during initial view when create an experience, the actors array is empty but there is already the possibility to edit the first actor
        const result = actors.length === 0 ? [ actor ] : actors.map( (it, i) => i === this.state.currentActorIndex ? actor : it )
        this.props.callbacks.onActorsChange( result, this.state.currentActorIndex )
    }
    
    render() {
        const { t, navigation } = this.props
        const { currentActorIndex } = this.state
        const { actors: lookup } = this.props.contribution
        const actors = lookup === undefined || lookup.length === 0 ? [ {} ] : lookup
        const currentActor = actors[this.state.currentActorIndex] === undefined ? {} : actors[this.state.currentActorIndex]
        const beforeList = actors.slice( 0, currentActorIndex )
        const afterList = actors.slice( currentActorIndex +1 )
        // const isRemoveEnabled = lookup === undefined || lookup.length === 0 ? false : true
        const localStepNavigationButtons = <button className="btn btn-secondary" type="button" onClick={this.handleAddActor}>
                    <span>{ t('contribute.steps.actors.actor.action.addActor') }&nbsp;</span>
                    <FontAwesomeIcon icon="plus" />
                </button>
        return (
            <div>
                <div className="card rounded">
                    { beforeList.length > 0 
                        ? <ul className="list-group list-group-flush">{ beforeList.map( (it, i) => this.buildActorListItem( it, i, 0, actors ) ) }</ul>
                        : null }
                    { this.buildActorHeader( currentActor, currentActorIndex, actors ) }
                    { this.buildActorBody( currentActor, currentActorIndex ) }
                    { afterList.length > 0 
                        ? <ul className="list-group list-group-flush">{ afterList.map( (it, i) => this.buildActorListItem( it, i, currentActorIndex +1, actors ) ) }</ul>
                        : null }
                </div>
                <BackNextNavigation t={t} navigation={navigation} localStepNavigationButtons={localStepNavigationButtons} />
            </div>
        )
    }

    buildActorListItem( actor, index, offset, actors ) {
        if ( actor === undefined || index === undefined || offset === undefined ) return null
        const { t, categorization } = this.props
        const headerText = buildActorString( actor, categorization, t, actors )
        const header = headerText === undefined ? t('contribute.steps.actors.header.new') : headerText
        const id = index + offset
        return (
            <li key={id} className="list-group-item d-flex justify-content-between align-items-center" role="group">
                <button type="button" className="btn p-0 mr-5 flex-fill text-left text-secondary text-truncate font-weight-bold"
                        data-id={id} onClick={this.handleActorChange}>
                    { header }
                </button>
                <button className="btn btn-sm btn-secondary" type="button" data-id={id} onClick={this.handleRemoveActor}>
                    <FontAwesomeIcon icon={['far', 'trash-alt']} data-id={id} />
                </button>
            </li>
        )
    }

    buildActorHeader( actor, index, actors ) {
        const { t, categorization } = this.props
        const headerText = buildActorString( actor, categorization, t, actors )
        const header = headerText === undefined ? t('contribute.steps.actors.header.new') : headerText
        const isRemoveEnabled = this.props.contribution.actors.length > 0
        return (
            <div className="card-header d-flex align-items-center">
                <h6 className="flex-grow-1 mb-0 pr-2 text-truncate font-weight-bold">
                    <FontAwesomeIcon icon={['far', 'edit']} />
                    <span className="pl-1">{ header }</span>
                </h6>
                <div className="btn-group" role="group" aria-label={ t('contribute.steps.actors.actor.aria.controlgroup') }>
                    { isRemoveEnabled === true
                        ? <button className="btn btn-sm btn-secondary" type="button" data-id={index} onClick={this.handleRemoveActor}>
                                <FontAwesomeIcon icon={['far', 'trash-alt']} data-id={index} />
                            </button>
                        : null }
                </div>
            </div>
        )
    }

    buildActorBody( currentActor, index ) {
        const { t, categorization, wasValidated, authService } = this.props
        // extract editable properties of this step
        const type = formValue( propertyId( currentActor.type ) )
        const isSystemActor = categorization.isActorType( currentActor.type, ActorTypes.System )
        const isHumanActor = categorization.isActorType( currentActor.type, ActorTypes.Human )
        const isGroupActor = categorization.isActorType( currentActor.type, ActorTypes.Group )
        const systemSpecification = formObject( currentActor.systemSpecification )
        const familiarityLevel = formValue( propertyId( currentActor.familiarityLevel ) )
        const isMixedGender = currentActor.isMixedGender === true ? true : false
        const gender = isGroupActor && isMixedGender ? "" : formValue( propertyId( currentActor.gender ) )
        const extendOfGroup = formValue( currentActor.extendOfGroup )
        const minAge = formValue( currentActor.minAge )
        const maxAge = formValue( currentActor.maxAge )
        const nationalities = formArray( currentActor.nationalities )
        const languages = formArray( currentActor.languages )
        // see step one for why we need to switch on invalid feedback manually
        const showInvalidFeedbackActorType = wasValidated === true && authService.isAuthor() && !( type !== undefined && categorization.actorType().some( (it) => it.id === type ) )
        const showInvalidFeedbackFamiliarityLevel = wasValidated === true && authService.isAuthor() && !( familiarityLevel !== undefined && categorization.familiarityLevel().some( (it) => it.id === familiarityLevel ) )
        const showInvalidFeedbackGender = wasValidated === true && authService.isAuthor() && !( isMixedGender === true || categorization.gender().some( (it) => it.id === gender ) )
        // invalid feedback for multi value input must be switched on manually
        const showInvalidFeedbackNationalities = wasValidated === true && authService.isAuthor() && nationalities.length === 0
        const showInvalidFeedbackLanguages = wasValidated === true && authService.isAuthor() && languages.length === 0
        return (
            <div className="card-body border-bottom">
                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.actors.actor.label.type') }</legend>
                        <div className="col-md-9">
                            { categorization.actorType().map( (it, i) =>
                                <div key={it.id} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id={'type' +it.id} name="type" value={it.id} required={authService.isAuthor()} checked={type === it.id} onChange={this.handleInputChange} />
                                    <label className="form-check-label" htmlFor={'type' +it.id}>{ it.label }</label>
                                </div>
                            ) }
                            { showInvalidFeedbackActorType === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.actors.actor.validation.type') }</div> : null }
                        </div>
                    </div>
                </fieldset>
                { isSystemActor === true ?
                <div className="row form-group">
                    <label className="col-md-3 col-form-label" htmlFor="systemSpecification">{ t('contribute.steps.actors.actor.label.systemSpecification') }</label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" id="systemSpecification" name="systemSpecification" autoComplete="off" placeholder={t('contribute.steps.actors.actor.placeholder.systemSpecification')}
                            required={authService.isAuthor()} data-id={systemSpecification.id} value={systemSpecification.label} onChange={this.handleInputChange} />
                        <div className="invalid-feedback">{ t('contribute.steps.actors.actor.validation.systemSpecification') }</div>
                    </div>
                </div>
                : null }
                { isGroupActor === true ?
                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.actors.actor.label.familiarityLevel') }</legend>
                        <div className="col-md-9">
                            { categorization.familiarityLevel().map( (it, i) =>
                                <div key={it.id} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id={'familiarityLevel' +it.id} name="familiarityLevel" value={it.id} required={authService.isAuthor()} checked={familiarityLevel === it.id} onChange={this.handleInputChange} />
                                    <label className="form-check-label" htmlFor={'familiarityLevel' +it.id}>{ it.label }</label>
                                </div>
                            ) }
                            { showInvalidFeedbackFamiliarityLevel === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.actors.actor.validation.familiarityLevel') }</div> : null }
                        </div>
                    </div>
                </fieldset>
                : null }
                { isGroupActor === true || isHumanActor === true ?
                <fieldset className="form-group">
                    <div className="row">
                        <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.actors.actor.label.gender') }</legend>
                        <div className="col-md-9">
                            { isGroupActor === true ?
                            <div key="mixedGender" className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="mixedGender" name="gender" value="mixedGender" required={authService.isAuthor()} checked={isMixedGender} onChange={this.handleInputChange} />
                                <label className="form-check-label" htmlFor="mixedGender">{ t('contribute.steps.actors.actor.label.mixedGender') }</label>
                            </div>
                            : null }
                            { categorization.gender().map( (it, i) =>
                                <div key={it.id} className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id={'gender' +it.id} name="gender" value={it.id} required={authService.isAuthor()} checked={gender === it.id} onChange={this.handleInputChange} />
                                    <label className="form-check-label" htmlFor={'gender' +it.id}>{ it.label }</label>
                                </div>
                            ) }
                            { showInvalidFeedbackGender === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.actors.actor.validation.gender') }</div> : null }
                        </div>
                    </div>
                </fieldset>
                : null }
                { isGroupActor === true ?
                <div className="row form-group">
                    <label className="col-md-3 col-form-label" htmlFor="extendOfGroup">{ t('contribute.steps.actors.actor.label.extendOfGroup') }</label>
                    <div className="col-md-9">
                        <input className="form-control" type="text" id="extendOfGroup" name="extendOfGroup" autoComplete="off" required={authService.isAuthor()} placeholder={t('contribute.steps.actors.actor.placeholder.extendOfGroup')} value={extendOfGroup} onChange={this.handleInputChange} />
                        <div className="invalid-feedback">{ t('contribute.steps.actors.actor.validation.extendOfGroup') }</div>
                    </div>
                </div>
                : null }
                { isGroupActor === true || isHumanActor === true ?
                <div className="row form-group">
                    <label className="col-md-3 col-form-label" htmlFor="minAge">{ t('contribute.steps.actors.actor.label.age') }</label>
                    <div className="col-md-9 input-group">
                        <div className="input-group-prepend"><span className="input-group-text">{t('contribute.steps.actors.actor.label.agebetween')}</span></div>
                        <input className="form-control" type="text" id="minAge" name="minAge" autoComplete="off" required={authService.isAuthor()} placeholder={t('contribute.steps.actors.actor.placeholder.minAge')} aria-label={t('contribute.steps.actors.actor.aria.minAge')} value={minAge} onChange={this.handleInputChange} />
                        <input className="form-control" type="text" id="maxAge" name="maxAge" autoComplete="off" required={authService.isAuthor()} placeholder={t('contribute.steps.actors.actor.placeholder.maxAge')} aria-label={t('contribute.steps.actors.actor.aria.maxAge')} value={maxAge} onChange={this.handleInputChange} />
                        <div className="invalid-feedback">{ t('contribute.steps.actors.actor.validation.age') }</div>
                    </div>
                </div>
                : null }
                { isGroupActor === true || isHumanActor === true ?
                <div className="row form-group">
                    <label className="col-md-3 col-form-label" htmlFor="nationalities">{ t('contribute.steps.actors.actor.label.nationalities') }</label>
                    <div className="col-md-9">
                        <MultiValueInput values={nationalities} datalist={categorization.nationality()} id="nationalities" isRequired={authService.isAuthor() && nationalities.length === 0} placeholder={t('contribute.steps.actors.actor.placeholder.nationalities')} 
                                buttonText={t('contribute.steps.actors.actor.action.concludeNationality')} ariaRemoveLabel={t('contribute.steps.actors.actor.aria.removeNationality')} ariaToggleSuggestions={t('contribute.steps.actors.actor.aria.toggleNationality')}
                                hintText={t('contribute.steps.actors.actor.hint.nationalities')}
                                onValuesChange={this.handleNationalityChange} />
                        { showInvalidFeedbackNationalities === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.actors.actor.validation.nationalities') }</div> : null }
                    </div>
                </div>
                : null }
                <div className="row form-group">
                    <label className="col-md-3 col-form-label" htmlFor="languages">{ t('contribute.steps.actors.actor.label.languages') }</label>
                    <div className="col-md-9">
                        <MultiValueInput values={languages} datalist={categorization.language()} id="languages" isRequired={authService.isAuthor() && languages.length === 0} placeholder={t('contribute.steps.actors.actor.placeholder.languages')} 
                                buttonText={t('contribute.steps.actors.actor.action.concludeLanguage')} ariaRemoveLabel={t('contribute.steps.actors.actor.aria.removeLanguage')} ariaToggleSuggestions={t('contribute.steps.actors.actor.aria.toggleLanguage')}
                                hintText={t('contribute.steps.actors.actor.hint.languages')}
                                onValuesChange={this.handleLanguageChange} />
                        { showInvalidFeedbackLanguages === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.actors.actor.validation.languages') }</div> : null }
                    </div>
                </div>
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(StepActors), { require: UserRole.AUTHOR } )
