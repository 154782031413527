/**
 * Basic React Import
 */
import React from 'react'
import ReactTable from 'react-table'
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

/**
 * custom ui icon related imports
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'
import { UserRole } from '../../domain/UserRole'

/**
 * custom cis related helper dependencies
 */
import { endpoints } from '../../config/endpoints'

/** custom functions for string representations of experience values */
import { buildLabelString, buildLocationString, buildCollectionString, buildAuthorString, buildActorsString } from '../../services/Aggregator'


/**
 * The component visualizes the entries as a table and provides the functionality to perform the operations edit, delete and view details.
 * 
 * @version 0.1.0
 * @since 1.0.0
 */
export class IntercuturalExperienceList extends React.Component {

  /**
   * Default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props);

    this.state = {
      offset: 0,
      quantity: 10,
      totalNumberOfInterculturalExperiences: 0,
      pages: 0,
      page: 0,
      nointerculturalExperienceDataText: 'Es sind keine Erfahrungen vorhanden',
      dataIsLoading: true,
      interculturalExperiences: [],
      currentUser: {
        role: ''
      },
      selectedInterculturalExperience: {
        id: '',
        created: '',
        title: '',
        story: '',
        dataState: '',
        type: '',
        origin: '',
        medium: '',
        contactDomains: '',
        hotspots: '',
        communicationDomains: '',
        selfAttributions: '',
        languages: '',
        storyDataKind: '',
        storyIsTranscribed: '',
        storyNarrativePerspective: '',
        storyLanguage: '',
        location: '',
        actors: '',
        author: '',
      },
      selectedInterculturalExperienceID: null,
      selectedInterculturalExperienceTitle: 'Lorem Ipsum',
      modalDialogDeleteIsVisible: false,
      modalDialogDetailsIsVisible: false,
      notificationDeleteErrorIsVisible: false,
      notificationDeleteIsVisible: false
    };

    this.openModalDialogDetails = this.openModalDialogDetails.bind(this);
    this.openModalDialogDelete = this.openModalDialogDelete.bind(this);
    this.closeModalDialogDetails = this.closeModalDialogDetails.bind(this);
    this.closeModalDialogDeleteAndDelete = this.closeModalDialogDeleteAndDelete.bind(this);
    this.closeModalDialogDeleteAndAbort = this.closeModalDialogDeleteAndAbort.bind(this);
    this.closeNotificationDeleteSuccess = this.closeNotificationDeleteSuccess.bind(this);
    this.showNotificationDeleteSuccess = this.showNotificationDeleteSuccess.bind(this);

    this.showNotificationDeleteError = this.showNotificationDeleteError.bind(this);
    this.closeNotificationDeleteError = this.closeNotificationDeleteError.bind(this);

    //this.fetchData = this.fetchData.bind(this);
    this.getPageData = this.getPageData.bind(this);
  }

  /**
   * load new data from server over component interaction
   * 
   * @param {*} state  current state object from the component
   * @param {*} instance current instance of the component
   */
  fetchData(state, instance) {
    this.setState({ dataIsLoading: true })

    if (this.state.offset >= 0) {
      var payload = {
        offset: this.state.offset,
        quantity: this.state.quantity,
      }

      fetch(endpoints.experiences.query, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(data => this.setState({ interculturalExperiences: data }))
        .then(data => { this.setState({ dataIsLoading: false }); })
    }
    else {
      this.setState({ interculturalExperience: [] })
      this.setState({ offset: 0 })
    }
  }

  /**
   * Handles the data callback from the delete button, when its clicked
   */
  experienceCallback = (e, row) => {
    this.openModalDialogDelete(e, row)
  }

  /**
   * Shows a notification for successful delete operation and hide it after a specific time
   * 
   * @public
   */
  showNotificationDeleteSuccess() {
    this.setState({ notificationDeleteIsVisible: true })

    /*
    setTimeout(function () {
      this.setState({ notificationDeleteIsVisible: false })
    }.bind(this), process.env.REACT_APP_NOTIFICATION_HIDE_INTERVAL);
    */
  }

  /**
   * Closes the notification for successful delete operation manually
   * 
   * @public
   */
  closeNotificationDeleteSuccess() {
    this.setState({ notificationDeleteIsVisible: false })
  }

  /**
   * Shows a notification for an error during the delete experience process
   * @public
   */
  showNotificationDeleteError() {
    this.setState({ notificationDeleteErrorIsVisible: true })
  }

  /**
 * Closes the notification for an error during the delete experience process
 * 
 * @public
 */
  closeNotificationDeleteError() {
    this.setState({ notificationDeleteErrorIsVisible: false })
  }

  /**
   * Opens a Modal Dialog for delete a intercultural experience entry and prepare the necessary state member
   * 
   * @param {*} event (click) event from the table row 
   * @param {*} interculturalExperience selected intercultural experience (from table row)
   * @public
   */
  openModalDialogDelete(event, interculturalExperience) {
    this.toogleModalDialogDeleteIsVisible();
    let selectedInterculturalExperience = Object.assign({}, this.state.selectedInterculturalExperience)
    selectedInterculturalExperience.id = interculturalExperience.id;
    selectedInterculturalExperience.title = interculturalExperience.title;
    this.setState({
      selectedInterculturalExperience: selectedInterculturalExperience
    });
  }

  /**
   * Opens a Modal Dialog for viewing the details of a intercultural experience entry and prepare the necessary state member
   * 
   * @param {*} event the click event
   * @param {*} interculturalExperience the current selected intercultural experience from the table row
   * @public
   */
  openModalDialogDetails(event, interculturalExperience) {
    this.toogleModalDialogDetailsIsVisible();
    let selectedInterculturalExperience = Object.assign({}, this.state.selectedInterculturalExperience)
    selectedInterculturalExperience.id = interculturalExperience.id;
    selectedInterculturalExperience.title = interculturalExperience.title;
    selectedInterculturalExperience.created = interculturalExperience.created;
    selectedInterculturalExperience.story = interculturalExperience._original.textStory.story;
    // use the same functions as the experience overview page
    selectedInterculturalExperience.type = buildLabelString( interculturalExperience._original.type )
    selectedInterculturalExperience.origin = buildLabelString( interculturalExperience._original.origin )
    selectedInterculturalExperience.medium = buildLabelString( interculturalExperience._original.medium )
    selectedInterculturalExperience.contactDomains = buildLabelString( interculturalExperience._original.contactDomains )
    selectedInterculturalExperience.hotspots = buildLabelString( interculturalExperience._original.hotspots )
    selectedInterculturalExperience.communicationDomains = buildLabelString( interculturalExperience._original.communicationDomains )
    selectedInterculturalExperience.selfAttributions = buildLabelString( interculturalExperience._original.selfAttributions )
    selectedInterculturalExperience.rightOfUse = buildLabelString( interculturalExperience._original.rightOfUse )
    selectedInterculturalExperience.collection = buildCollectionString( interculturalExperience._original.collection )
    selectedInterculturalExperience.languages = buildLabelString( interculturalExperience._original.languages )
    
    selectedInterculturalExperience.storyDataKind = buildLabelString( interculturalExperience._original.textStory.kind )
    selectedInterculturalExperience.storyNarrativePerspective = buildLabelString( interculturalExperience._original.textStory.perspective )
    selectedInterculturalExperience.storyLanguage = buildLabelString( interculturalExperience._original.textStory.language )
    if (interculturalExperience._original.textStory.transcribed === true) {
      selectedInterculturalExperience.storyIsTranscribed = 'ja';
    } else {
      selectedInterculturalExperience.storyIsTranscribed = 'nein';
    }

    selectedInterculturalExperience.location = buildLocationString( interculturalExperience._original.location )
    selectedInterculturalExperience.actors = buildActorsString( interculturalExperience.actors )
    selectedInterculturalExperience.author = buildAuthorString( interculturalExperience._original.textStory.author )

    this.setState({
      selectedInterculturalExperience: selectedInterculturalExperience
    });
  }

  /**
   * Closes the Details Modal Dialog without any data changes
   * 
   * @public
   */
  closeModalDialogDetails() {
    this.toogleModalDialogDetailsIsVisible();
  }

  /**
   * Closes the Delete Modal Dialog without any data changes
   * 
   * @public
   */
  closeModalDialogDeleteAndAbort() {
    this.toogleModalDialogDeleteIsVisible();
  }

  /**
   * Closes the delete modal dialog and deletes the selected intercultural experience entry
   * 
   * @public
   */
  closeModalDialogDeleteAndDelete() {
    this.toogleModalDialogDeleteIsVisible();
    let url = endpoints.experiences.single + this.state.selectedInterculturalExperience.id
    fetch( url, {
      method: 'DELETE',
      credentials: 'include',
    }).then(response => {
      if (response.ok === true) {
        //this.requestAllInterculturalExperiences();
        this.getPageData(this.state.page)
        this.showNotificationDeleteSuccess();
      }
      else {
        this.showNotificationDeleteError();
      }
    }).catch(this.showNotificationDeleteError())
    fetch(endpoints.experiences.query, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => this.setState({ totalNumberOfInterculturalExperiences: data.count }, this.calculateTablePages))
  }

  /**
   * Toogle the boolean state member for the visibility of the delete modal dialog
   * 
   * @public
   */
  toogleModalDialogDeleteIsVisible() {
    this.setState({
      modalDialogDeleteIsVisible: !this.state.modalDialogDeleteIsVisible
    });
  }

  /**
   * Toogle the boolean state member for the visibility of the details modal dialog
   * 
   * @public
   */
  toogleModalDialogDetailsIsVisible() {
    this.setState({
      modalDialogDetailsIsVisible: !this.state.modalDialogDetailsIsVisible
    });
  }

  /**
   * Requests  intercultural experiences from the cis-backend server and fill the corresponding state member based
   * on offset and quantity parameters
   */
  requestAllInterculturalExperiences() {

    this.setState({ dataIsLoading: true })

    //if (this.state.offset >= 0) {
    var payload = {
      offset: this.state.offset,
      quantity: this.state.quantity
    }

    fetch(endpoints.experiences.query, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => this.setState({ interculturalExperiences: data }))
      .then(data => { this.setState({ dataIsLoading: false }); })
  }

  /**
   * sub function, which is called after render, when an instance of the component is being created and inserted into the DOM)
   */
  componentDidMount() {
    this.requestAllInterculturalExperiences();
    if (localStorage.getItem('role') !== '') {
      let userFromCache = Object.assign({}, this.state.currentUser)
      //userFromCache.identification = localStorage.getItem('identification');
      //userFromCache.credentials = localStorage.getItem('credentials')
      userFromCache.role = localStorage.getItem('role')
      this.setState({
        currentUser: userFromCache
      });
    }

    fetch(endpoints.experiences.query, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => this.setState({ totalNumberOfInterculturalExperiences: data.count }, this.calculateTablePages))
  }

  /**
   * calculates the current (new) amount of pages, e.g. after delete experiences
   */
  calculateTablePages() {
    if (this.state.totalNumberOfInterculturalExperiences > 0) {
      let totalNumberOfPages = Math.ceil((this.state.totalNumberOfInterculturalExperiences / this.state.quantity));
      this.setState({ pages: totalNumberOfPages })
    }
  }

  /**
   * gets a specific slice of intercultural experience data based on offset and quantitiy
   * @param {*} newPage the index of the new page (next, prior)
   */
  getPageData(newPage) {
    fetch(endpoints.experiences.query, {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => this.setState({ totalNumberOfInterculturalExperiences: data.count }, this.calculateTablePages))

    if (this.state.page > -1) {
      let newPageIndex = newPage;
      let newOffSet = this.state.quantity * newPage;
      this.setState({ offset: newOffSet });
      this.setState({ dataIsLoading: true })

      var payload = { offset: this.state.offset, quantity: this.state.quantity }

      fetch(endpoints.experiences.query, {
        method: 'POST', headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(data => this.setState({ interculturalExperiences: data }))
        .then(data => { this.setState({ dataIsLoading: false }); })
        .then(data => { this.refreshPagination(this.state.interculturalExperiences.length, newPageIndex) })
    }
    else {
      this.setState({ page: 0 })
    }
  }

  /**
   * updates the current state and date of the table pagination 
   * 
   * @param {*} interculturalExperiencesLenght count of shown intercultural experiences
   * @param {*} newPageIndex the next page index
   */
  refreshPagination(interculturalExperiencesLenght, newPageIndex) {
    if (interculturalExperiencesLenght !== 0) {
      this.setState({ page: newPageIndex })
      this.setState({ dataIsLoading: true })

      if (this.state.offset >= 0) {
        var payload = { offset: this.state.offset, quantity: this.state.quantity }

        fetch(endpoints.experiences.query, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })
          .then(response => response.json())
          .then(data => this.setState({ interculturalExperiences: data }))
          .then(data => { this.setState({ dataIsLoading: false }); })
      }
      else {
        this.setState({ interculturalExperience: [] })
        this.setState({ offset: 0 })
      }
    }
    else {
      fetch(endpoints.experiences.query, {
        method: 'GET',
        credentials: 'include',
      })
        .then(response => response.json())
        .then(data => this.setState({ totalNumberOfInterculturalExperiences: data.count }, this.calculateTablePages))

      this.setState({ page: newPageIndex - 1 }, function () {
        let newOffSet = this.state.quantity * (newPageIndex - 1);
        this.setState({ offset: newOffSet }, console.log('this.state.offset ' + this.state.offset));
        this.setState({ dataIsLoading: true })

        if (newOffSet < 0) {
          newOffSet = 0;
        }

        var payload = { offset: newOffSet, quantity: this.state.quantity }

        fetch(endpoints.experiences.query, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        })
          .then(response => response.json())
          .then(data => this.setState({ interculturalExperiences: data }))
          .then(data => { this.setState({ dataIsLoading: false }); })
      })
    }

    if (this.state.page < 0) {
      this.setState({ page: 0 })
    }
  }

  /**
   * automatically invoked before the component receives new props
   * 
   * @param {*} newProps new properties from a parent component
   */
  componentWillReceiveProps(newProps) {
    /*
    if (this.state.currentUser.role !== newProps.currentUser.role) { 
      this.setState({ currentUser: newProps.currentUser }); 
    }
    */
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t } = this.props
    return (
            <div>
              <Alert color="success" className="alert-notification" isOpen={this.state.notificationDeleteIsVisible} toggle={this.closeNotificationDeleteSuccess}>
                {t('dataManagement.notification.deleteExperienceSuccess')}
              </Alert>
              <Alert color="danger" className="alert-notification" isOpen={this.state.notificationDeleteErrorIsVisible} toggle={this.closeNotificationDeleteError}>
                {t('dataManagement.notification.deleteExperienceError')}
              </Alert>
              <ReactTable
                manual
                defaultPageSize={this.state.quantity}
                page={this.state.page}
                pages={this.state.pages}
                //onFetchData={this.fetchData}
                onPageChange={(newPage) => this.getPageData(newPage)}
                loading={this.state.dataIsLoading}
                loadingText={t('dataManagement.table.loadingText')}
                showPageSizeOptions={false}
                filterable={false}

                className="-striped -highlight text-center table-responsive"
                data={this.state.interculturalExperiences}
                noDataText={this.state.nointerculturalExperienceDataText}
                previousText={t('dataManagement.table.previousText')}
                nextText={t('dataManagement.table.nextText')}
                rowsText={t('dataManagement.table.rowsText')}
                pageText={t('dataManagement.table.pageText')}
                ofText={t('dataManagement.table.ofText')}
                columns={[
                  {
                    Header: t('dataManagement.table.columns.id'),
                    accessor: "id",
                  },
                  {
                    Header: t('dataManagement.table.columns.title'),
                    accessor: "title",
                  },
                  {
                    Header: t('dataManagement.table.columns.created'),
                    accessor: "created"
                  },
                  {
                    Header: "",
                    accessor: "details",
                    filterable: false,
                    Cell: ({ row }) => (<button className="btn btn-sm btn-outline-info" onClick={(e) => this.openModalDialogDetails(e, row)} >{t('dataManagement.table.show')}</button>)

                  },
                  {
                    Header: "",
                    accessor: "edit",
                    filterable: false,
                    //Cell: ({ row }) => (<button className="btn btn-sm btn-outline-warning" disabled onClick={() => { console.log('clicked value', row) }}>Eintrag bearbeiten</button>)
                  },
                  {
                    Header: "",
                    accessor: "delete",
                    filterable: false,
                    Cell: ({ row }) => (<Button size="sm" color="danger" outline onClick={(event) => this.experienceCallback(event, row)}>{t('dataManagement.table.delete')}</Button>)
                    // Cell: ({ row }) => (<DeleteButton row={row} callBackInterculturalExperience={this.experienceCallback} userRole={this.state.currentUser.role} />)
                  }
                ]}
              />

              <Modal isOpen={this.state.modalDialogDeleteIsVisible} toggle={this.closeModalDialogDeleteAndAbort} className={this.props.className}>
                <ModalHeader toggle={this.closeModalDialogDeleteAndAbort}>
                  {t('dataManagement.delete.header.part1')} (#{this.state.selectedInterculturalExperience.id}) {t('dataManagement.delete.header.part2')}?
                </ModalHeader>
                <ModalBody className="text-center">
                  {t('dataManagement.delete.text.part1')}<br /><strong>#{this.state.selectedInterculturalExperience.id}</strong> - <strong>{this.state.selectedInterculturalExperience.title}</strong><br /> {t('dataManagement.delete.text.part2')}
                </ModalBody>
                <ModalFooter  >
                  <div className="container-fluid text-center">
                    <Button color="success" className="modal-delete-btn" onClick={this.closeModalDialogDeleteAndDelete}>{t('dataManagement.delete.confirm')}</Button>{' '}
                    <Button color="danger" className="modal-delete-btn" onClick={this.closeModalDialogDeleteAndAbort}>{t('dataManagement.delete.cancel')}</Button>
                  </div>
                </ModalFooter>
              </Modal>

              <Modal isOpen={this.state.modalDialogDetailsIsVisible} toggle={this.closeModalDialogDetails} className={this.props.className} className="modal-dialog modal-lg">
                <ModalHeader toggle={this.closeModalDialogDetails}>
                  #{this.state.selectedInterculturalExperience.id}
                </ModalHeader>
                <ModalBody>
                  <div className="intercultural-experience-card">
                    <div className="row">
                      <div className="col-7 intercultural-expericence-title">{this.state.selectedInterculturalExperience.title}</div>
                    </div>
                    <div className="row intercultural-experience-card-segment-gap">
                      <div className="col-8">
                        <ul className="list-inline intercultural-experience-story-hashtags">
                        </ul>
                      </div>
                      <div className="col-4 intercultural-experience-story-author">{t('dataManagement.details.created')} {this.state.selectedInterculturalExperience.created}</div>
                    </div>
                    <div className="row">
                      <div className="col-12 intercultural-experience-story-text">
                        <p>{this.state.selectedInterculturalExperience.story}</p>
                      </div>
                    </div>
                    <div className="row intercultural-experience-card-segment-gap">
                      <div className="col-6 intercultural-experience-detail-link-left">
                        <button type="button" className="btn btn-outline-primary" data-toggle="collapse" href="#collapseExample2" aria-expanded="false"
                          aria-controls="collapseExample2"><FontAwesomeIcon icon="info-circle" />
                        </button>
                      </div>
                    </div>
                    <div className="row intercultural-experience-card-segment-gap">
                      <div className="col-12 collapse" id="collapseExample2">
                        <div className="intercultural-experience-detail-table">
                          <table className="table table-sm table-striped table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">{t('dataManagement.details.category')}</th>
                                <th scope="col">{t('dataManagement.details.value')}</th>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{t('dataManagement.details.origin')}</td>
                                <td>{this.state.selectedInterculturalExperience.origin}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.dataKind')}</td>
                                <td>{this.state.selectedInterculturalExperience.storyDataKind}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.perspective')}</td>
                                <td>{this.state.selectedInterculturalExperience.storyNarrativePerspective}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.transcribed')}</td>
                                <td>{this.state.selectedInterculturalExperience.storyIsTranscribed}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.interactionMedium')}</td>
                                <td>{this.state.selectedInterculturalExperience.medium}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.collection')}</td>
                                <td>{this.state.selectedInterculturalExperience.collection}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.locationOfHappening')}</td>
                                <td>{this.state.selectedInterculturalExperience.location}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.contactField')}</td>
                                <td>{this.state.selectedInterculturalExperience.contactDomains}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.hotspots')}</td>
                                <td>{this.state.selectedInterculturalExperience.hotspots}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.communicationDomains')}</td>
                                <td>{this.state.selectedInterculturalExperience.communicationDomains}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.languages')}</td>
                                <td>{this.state.selectedInterculturalExperience.languages}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.actors')}</td>
                                <td>{this.state.selectedInterculturalExperience.actors}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.author')}</td>
                                <td>{this.state.selectedInterculturalExperience.author}</td>
                              </tr>
                              <tr>
                                <td>{t('dataManagement.details.selfAttributions')}</td>
                                <td>{this.state.selectedInterculturalExperience.selfAttributions}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="container-fluid text-center">
                    <Button color="primary" className="modal-delete-btn" onClick={this.closeModalDialogDetails}>{t('dataManagement.details.close')}</Button>{' '}
                  </div>
                </ModalFooter>
              </Modal>
            </div>
    )
  }
}

export default AuthHoc( LocalizableHoc()(IntercuturalExperienceList), { require: UserRole.ADMIN } )
