/**
 * InsufficientPermission.js
 * @description Views an alert about insufficient permissions.
 * @since 2.0
 */

import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../services/LanguageService'


/**
 * Renders an alert to notify the user about insufficient permissions.
 * Note: this component should be refactored when realizing a notification system for this app
 * TODO: when refactoring or restructuring the common html markup, remove these unnecessary nestings
 * TODO: when refactoring the notification system, use a separate translation prefix for error messages
 */
export class InsufficientPermission extends React.Component {
    render() {
        const { t } = this.props
        return (
            <div className='cisComponent'>
                <div className="container-fluid">
                    <div className="cisComponentHeader">
                        <div className="row">
                            <div className="col">
                                <h1>{t('contribute.header')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="cisComponentBody">
            <div className="alert alert-danger" role="alert">
                <strong>{ t('connector.connection.error') }</strong> { t('connector.connection.code.401') }
            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LocalizableHoc()( InsufficientPermission )
