/**
 * basic react module dependencies
 */
import React from 'react'
import { Container, Row, Col, Card, CardHeader, CardBody, CardTitle, CardText, CardFooter, Button, Collapse, Table } from 'reactstrap'

// language + translation service
import { LocalizableHoc } from '../../services/LanguageService'

/**
 * custom cis related helper module dependencies
 */
import ReadMore from './ReadMore'
import InterculturalExperienceDetailRow from './InterculturalExperienceDetailRow'

/**
 * custom cis related helper libary dependencies
 */
import { buildLabelString, buildAuthorString, buildActorsString, buildLocationString, buildCollectionString } from '../../services/Aggregator'
import { Link4Author } from '../Link'

/**
 * The component visualizes a intercultural experience as an index card.
 * TODO: see #100
 * @version 1.5.0
 * @since 1.2.0
 */
export class InterculturalExperienceCard extends React.Component {

  constructor( props ) {
    super( props )

    this.onDeleteClick = this.onDeleteClick.bind(this)

    var intercuturalExperience = props.intercuturalExperience

    this.state = {
      id: intercuturalExperience.id,
      title: intercuturalExperience.title,
      created: intercuturalExperience.created,
      type: buildLabelString( intercuturalExperience.type ),
      origin: buildLabelString( intercuturalExperience.origin ),
      medium: buildLabelString( intercuturalExperience.medium ),
      contactDomains: buildLabelString( intercuturalExperience.contactDomains ),
      hotspots: buildLabelString( intercuturalExperience.hotspots ),
      communicationDomains: buildLabelString( intercuturalExperience.communicationDomains ),
      selfAttributions: buildLabelString( intercuturalExperience.selfAttributions ),
      rightOfUse: buildLabelString( intercuturalExperience.rightOfUse ),
      // deprecated, only needed for backward compatability of data
      languages: buildLabelString( intercuturalExperience.languages ),
      story: intercuturalExperience.textStory.story,
//      storyType: intercuturalExperience.textStory.type === null ? '' : intercuturalExperience.textStory.type.label,
      storyIsTranscribed: intercuturalExperience.isTranscribed === undefined ? '' : intercuturalExperience.isTranscribed,
      storyDataKind: buildLabelString( intercuturalExperience.textStory.kind ),
      storyPerspective: buildLabelString( intercuturalExperience.textStory.perspective ),
      storyLanguage: buildLabelString( intercuturalExperience.textStory.language ),
      author: buildAuthorString( intercuturalExperience.textStory.author, this.props.t ),
      location: buildLocationString( intercuturalExperience.location ),
      collection: buildCollectionString( intercuturalExperience.collection ),
      actors: buildActorsString( intercuturalExperience.actors, this.props.t ),
      reflection:intercuturalExperience.reflection?intercuturalExperience.reflection.reflection:'',
      reflectionKeyWords:intercuturalExperience.reflection?buildLabelString(intercuturalExperience.reflection.keywords):"",
      // ui properties
      collapseHref: '#collapseExample'.concat( intercuturalExperience.id ),
      collapseId: 'collapseExample'.concat( intercuturalExperience.id ),
    }

    // TODO: should check if at least one actor has a language attached!
    const { t } = this.props
    if ( this.state.languages.length === 0 ) this.state.languages = t('overview.card.details.languagesPorted')
  }

  onDeleteClick() {
    this.props.onDeleteClick( this.props.intercuturalExperience )
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t } = this.props
    const { id, isEditable, isDeletable } = this.props.intercuturalExperience
    
    return (
      <div className='cardGap'>
        <Container fluid>
          <Row className="gapTop">
            <Col>
              <Card>
                <CardHeader>{this.state.id}</CardHeader>
                <CardBody>
                  <CardTitle>{this.state.title}</CardTitle>
                  <CardText>
                    <span><ReadMore >{this.state.story}</ReadMore></span>
                  </CardText>
                  <div className="d-flex justify-content-between">
                    <Button className="btn btn-primary" data-toggle="collapse" href={this.state.collapseHref} aria-expanded="false" aria-controls={this.state.collapseId}>{t('overview.card.details.toogle')}</Button>
                    <div className="">
                      { isEditable !== true || id === undefined || id === null ? null : 
                        <Link4Author role="button" className="btn btn-primary" to={'/contribute/' + id} >{ t('overview.card.actions.edit') }</Link4Author> }
                      { isDeletable !== true || id === undefined || id === null ? null : 
                        <button type="button" className="btn btn-danger ml-2" onClick={this.onDeleteClick}>{t('overview.card.actions.delete')}</button> }
                    </div>
                  </div>
                  <Collapse id={this.state.collapseId}>
                    <Card>
                      <CardBody>
                        <Table striped size="sm">
                          <tbody>
                            <tr><td><strong>{t('overview.card.details.category')}</strong></td><td><strong>{t('overview.card.details.value')}</strong></td></tr>
                            <InterculturalExperienceDetailRow name={t('overview.card.details.origin')} value={this.state.origin} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.dataKind')} value={this.state.storyDataKind} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.transcribed')} value={this.state.storyIsTranscribed} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.perspective')} value={this.state.storyPerspective} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.interactionMedium')} value={this.state.medium} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.communicationDomains')} value={this.state.communicationDomains} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.collection')} value={this.state.collection} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.locationOfHappening')} value={this.state.location} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.contactFields')} value={this.state.contactDomains} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.hotspots')} value={this.state.hotspots} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.languages')} value={this.state.languages} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.actors')} value={this.state.actors} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.author')} value={this.state.author} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.selfAttributions')} value={this.state.selfAttributions} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.reflection')} value={this.state.reflection} />
                            <InterculturalExperienceDetailRow name={t('overview.card.details.reflectionkeywords')} value={this.state.reflectionKeyWords} />
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </Collapse>
                </CardBody>
                <CardFooter className="text-muted">{t('overview.card.captured')} {this.state.created}</CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default LocalizableHoc()(InterculturalExperienceCard)