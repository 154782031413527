/**
 * basic react module dependencies
 */
import React from 'react'
import { Row, Col } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component'
import ScrollUpButton from 'react-scroll-up-button'

// language + translation service
import { LocalizableHoc } from '../../services/LanguageService'

/**
 * custom cis related helper dependencies
 */
import { Connector, buildRequest } from '../../services/Fetcher'
import { endpoints } from '../../config/endpoints'
import { config } from '../../config/config'

/**
 * custom cis related module dependencies
 */
import InterculturalExperienceCard from './InterculturalExperienceCard'
import { UserRole } from '../../domain/UserRole'
import { AuthHoc } from '../../services/AuthService'

/**
 * The component visualizes the entries as a table and provides the functionality to perform the operations edit, delete and view details.
 * 
 * @version 0.1.0
 * @since 1.2.0
 */
export class InterculturalExperienceCardBoard extends React.Component {

  /**
   * Default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props)

    this.state = {
      offset: 0,
      quantity: config.fetchData.quantity,
      interculturalExperiences: [],
      moreInterculturalExperiencesAreAvailable: true,

      isConfirmingDelete: false,
      experienceToDelete: undefined,
      connector: new Connector(),
      isDeleteSuccessfully: undefined,
      isQuerySuccessfully: undefined
    }

    this.reloadExperiences = this.reloadExperiences.bind(this)
    this.loadExperiences = this.loadExperiences.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.abortConfirmDelete = this.abortConfirmDelete.bind(this)
    this.doDeleteExperience = this.doDeleteExperience.bind(this)
  }

  componentDidMount() {
    this.reloadExperiences()
    // note: when user logs in the user role might change and if it does, the experiences need to be fetched again for edit and delete actions to appear
    this.props.authService.addRoleChangeListener( this.reloadExperiences )
  }

  componentWillUnmount() {
    this.props.authService.removeRoleChangeListener( this.reloadExperiences )
  }

  reloadExperiences() {
    if(!this.props.results)
    {this.setState( { interculturalExperiences: [], offset: 0 }, this.loadExperiences )}
  }

  loadExperiences() {
    if(this.props.results)
    {
      this.setState( {  interculturalExperiences:this.props.results,moreInterculturalExperiencesAreAvailable:false,isQuerySuccessfully: true,offset:0,quantity:this.props.results.length})
      return
    }
    const { connector } = this.state
    connector.send(
      buildRequest( endpoints.experiences.query.url(), 'POST', true, { offset: this.state.offset, quantity: this.state.quantity } ),
      (json) => {
        if ( connector.hasJson() && connector.is200() ) {
          if ( Array.isArray(json) && json.length > 0 ) {
            this.setState( {
                interculturalExperiences: this.state.interculturalExperiences.concat( json ), moreInterculturalExperiencesAreAvailable: true, 
                isQuerySuccessfully: true
            } )
          } else {
            this.setState( { moreInterculturalExperiencesAreAvailable: false, isQuerySuccessfully: true } )
          }
        } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
            this.setState( { isQuerySuccessfully: false, isDeleteSuccessfully: undefined } )
        }
      }
    )
  }

  confirmDelete( experience ) {
    window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } )
    this.setState( { isConfirmingDelete: true, experienceToDelete: experience } )
  }

  abortConfirmDelete() {
    this.setState( { isConfirmingDelete: false, experienceToDelete: undefined } )
  }

  doDeleteExperience() {
    this.abortConfirmDelete()
    // perform delete on backend
    const { connector, experienceToDelete } = this.state
    connector.send(
      buildRequest( endpoints.experiences.url( experienceToDelete.id ), 'DELETE', true ), 
      (json) => {
        if ( connector.is200() ) {
            // reload data from backend on success
            this.setState( { isDeleteSuccessfully: true, experienceToDelete: undefined, isQuerySuccessfully: undefined, interculturalExperiences: [], offset: 0 }
                , this.loadExperiences )
        } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
            this.setState( { isDeleteSuccessfully: false, isQuerySuccessfully: undefined } )
        }
      }
    )
  }

  /**
   * load (more available) intercultural experiences on scrolling (callback function for infinite scroll)
   */
  loadNextInterculturalExperiences = () => {
    if(!this.props.results)
    {this.setState( { offset: this.state.offset + 5 }, this.loadExperiences )}
    //else{
    //  this.setState( { offset: this.state.offset + 5 }, this.loadExperiences)}
    //}
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t } = this.props
    const { isConfirmingDelete, experienceToDelete, isDeleteSuccessfully, isQuerySuccessfully } = this.state
    const myStyle = {
      "overflow": "hidden",
    }
    // note: just show only one notification: confirm delete | delete successfully | delete failed | query failed
    const code = this.state.connector.code()
    // if(this.props.results){console.log(this.props.results.length)}
    return (
      <div className="row">
        <div className="col">
          { isDeleteSuccessfully !== false ? null :
            <div className="alert alert-danger">
              <span><strong>{ t('overview.board.alerts.delete.failed.title') } </strong>
                { code === 401 ? t('overview.board.alerts.delete.failed.401') : 
                  code === 404 ? t('overview.board.alerts.delete.failed.404') : t('overview.board.alerts.delete.failed.message') }
              </span>
            </div> }
          { isQuerySuccessfully !== false ? null :
            <div className="alert alert-danger">
              <span><strong>{ t('overview.board.alerts.query.failed.title') } </strong>{ t('overview.board.alerts.query.failed.message') }</span>
            </div> }
          { isConfirmingDelete === false ? null :
              <div className="alert alert-warning" role="alert">
                <div className="d-flex">
                  <span className="flex-fill">
                      <strong>{ t('overview.board.alerts.confirm.title') } </strong>{ t('overview.board.alerts.confirm.message') }
                  </span>
                  <button type="button" className="btn btn-sm btn-outline-danger mx-2 align-self-end align-self-center" onClick={ this.doDeleteExperience }>{ t('overview.board.alerts.confirm.yes') }</button>
                  <button type="button" className="btn btn-sm btn-outline-secondary align-self-end align-self-center" onClick={ this.abortConfirmDelete }>{ t('overview.board.alerts.confirm.no') }</button>
                </div>
                <p><em>{experienceToDelete.title}</em></p>
              </div> }
          { isDeleteSuccessfully !== true ? null :
            <div className="alert alert-success">
              <span><strong>{ t('overview.board.alerts.delete.success.title') } </strong>{ t('overview.board.alerts.delete.success.message') }</span>
            </div> }
          {this.props.results===undefined?
           <InfiniteScroll
             style={myStyle}
             dataLength={this.state.interculturalExperiences.length}
             next={this.loadNextInterculturalExperiences}
             hasMore={this.state.moreInterculturalExperiencesAreAvailable}
             loader={<p className="text-center">{t('overview.board.loading')}</p>}
             endMessage={<p className="text-center">{t('overview.board.noMoreExperiences')}</p>}
           >
            
          {this.state.interculturalExperiences.map((value, index) => (
              <div key={index}><InterculturalExperienceCard intercuturalExperience={value} onDeleteClick={this.confirmDelete} /></div>))}
            </InfiniteScroll>
          :
           this.props.results.map((value,index)=>(
            <div key={value.id}><InterculturalExperienceCard intercuturalExperience={value} onDeleteClick={this.confirmDelete} /></div>))
            }
          
          <ScrollUpButton ContainerClassName="MyOverRideClass" TransitionClassName="MyOverRideTransitionedClass" />

        </div>
      </div>
    )
  }
}

export default AuthHoc( LocalizableHoc()(InterculturalExperienceCardBoard), { require: UserRole.GUEST } )
