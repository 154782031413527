import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'

import { UserRole } from '../../domain/UserRole'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'
import MultiValueInput from './MultiValueInput'


class StepContactDomain extends React.Component {
    constructor( props ) {
        super( props )
        this.handleInputChange = this.handleInputChange.bind( this )
    }

    handleInputChange( contactDomains ) {
        if ( Array.isArray( contactDomains ) === false ) return
        const experience = Object.assign( {}, this.props.contribution.experience, { 'contactDomains': contactDomains } )
        this.props.callbacks.onExperienceChange( experience )
    }

    render() {
        const { t, navigation, categorization, wasValidated, authService } = this.props
        // extract editable properties of this step
        const { contactDomains } = this.props.contribution.experience
        // invalid feedback for multi value input must be switched on manually
        const showInvalidFeedbackContactDomain = wasValidated === true && authService.isAuthor() && contactDomains.length === 0
        return (
            <div>
                <div className="card">
                    <h6 className="card-header">{ t('contribute.steps.contactdomain.header') }</h6>
                    <div className="card-body">
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="contactDomains">{ t('contribute.steps.contactdomain.label.contactdomains') }</label>
                            <div className="col-md-9">
                                <MultiValueInput values={contactDomains} datalist={categorization.contactDomain()} id="contactDomains" isRequired={authService.isAuthor() && contactDomains.length === 0} 
                                        placeholder={t('contribute.steps.contactdomain.placeholder.contactdomains')} buttonText={t('contribute.steps.contactdomain.action.conclude')} 
                                        ariaRemoveLabel={t('contribute.steps.contactdomain.aria.remove')} ariaToggleSuggestions={t('contribute.steps.contactdomain.aria.toggle')}
                                        hintText={t('contribute.steps.contactdomain.hint.contactdomains')}
                                        onValuesChange={this.handleInputChange} />
                                { showInvalidFeedbackContactDomain === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.contactdomain.validation.contactdomains') }</div> : null }
                            </div>
                        </div>
                    </div>
                </div>
                <BackNextNavigation t={t} navigation={navigation} />
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(StepContactDomain), { require: UserRole.AUTHOR } )
