/* eslint-disable no-console */
import React from 'react';
import ConnectorComponent from '../contribute/ConnectorComponent';
import {HeaderRow} from './Tables';
import {IdmQueryTypes,IdmUserListTypes,UserRowTypes,UserBodyTypes,UserTableTypes,RoleArray} from './Types';
import {endpoints} from '../../config/endpoints';
import {buildRequest} from '../../services/Fetcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class IdmQuery extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            email:"",
            uid:"",
            wasValidated:false,
            userFound:null,
            user:null,
            technicalError:null
        };
        this.formElement = null;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.id;
        const state = name;
        const value = target.value;
        this.setState({[state]:value});
    }

    handleFormSubmit = ( event ) =>{
        event.preventDefault();
        event.stopPropagation();
        const {connector} = this.props;
        const idmUser ={
            uid:this.state.uid,
            email:this.state.email
        };
         
        const isValid = this.isFormValid();
        if ( isValid === true ) {
            if(idmUser.uid!=""){
                idmUser.email=null;
            }
            else{
                idmUser.uid=null;
            }
            connector.send( buildRequest( endpoints.administration.idmUser.query.url(), endpoints.administration.idmUser.query.method(), true,idmUser ),
            ( json ) => {
                if ( this.props.connector.hasJson() && this.props.connector.is200() )
                {
                    this.setState({userFound:true,user:json,technicalError:null});
                    //console.log(json);
                }
                else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                // render occured errors (error information is part of the state of the connector)
                  
                  this.setState({userFound:false,user:null});
                  if (json && json["userDto.identification"]) { this.setState({ technicalError: json["userDto.identification"] }); }
                  if(connector.code()===404)
                  {this.setState({technicalError:"Es konnten keine Daten zur Email oder dem Identifikator gefunden werden"});}
                }
            });
            
        }
    }

    isFormValid = () => {
        const isValid = this.formElement.checkValidity();
        this.setState( { isFormValid: isValid, wasValidated: true } );
        return isValid;
    }

    renderResults = (user,t) => {
        if(user){
            return (
            <div>
                <div className="row">
                    <div className="col-md-3" >{t('userManagement.idm.queryID')}:</div>
                    <div className="col-md-6">{user.uid}</div>
                </div>
                <div className="row">
                    <div className="col-md-3" >{t('userManagement.email')}:</div>
                    <div className="col-md-6">{user.email}</div>
                </div>
                <div className="row">
                    <div className="col-md-3" >{t('userManagement.idm.organization')}:</div>
                    <div className="col-md-6">{user.organizationalUnit}</div>
                </div>
                <div className="row">
                    <div className="col-md-3" >{t('userManagement.idm.department')}:</div>
                    <div className="col-md-6">{user.departmentNumber}</div>
                </div>
            </div>
            );
        }
    }
    
    renderError = (error) => {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>{this.props.t('userManagement.idm.error')}:</strong> {error}
            </div>
        );
    }

    render() {
        const { t,connector } = this.props;
        const {wasValidated,isFormValid,userFound,user,technicalError} = this.state;
        const formCss = wasValidated === true && isFormValid === false ? "was-validated" : "";

        return(
            <div>
            <div className="card">
                <div className="card-body">
                    <h6 className="card-subtitle mb-2">{t('userManagement.idm.header')}</h6>
                    <form  ref={ (form) => this.formElement = form } onSubmit={ this.handleFormSubmit } noValidate className={formCss}>
                        <div className="form-group row">
                            <label className="col-md-2 col-form-label" htmlFor="idmuid">{t('userManagement.idm.queryID')}:</label>
                            <div className="col-md-4">
                                <input className="form-control" id="uid" name="uid" placeholder="" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-2 col-form-label" htmlFor="email">{t('userManagement.email')}:</label>
                            <div className="col-md-4">
                                <input type="email" className="form-control" id="email" name="email" placeholder="" onChange={this.handleInputChange} />
                                {
                                    // showInvalidFeedBackIDMQuery === true ? <div className="invalid-feedback">{ t('idmquery.invalid') }</div> : null 
                                }
                            </div>
                            <div className="col-md-4">
                                <button type="submit" className="btn btn-primary md-2 mb-3" >{t('userManagement.submit')}</button>
                                <span tabIndex="0" className="md-2 ml-2 mt-1" data-toggle="popover" data-trigger="focus" data-content={t('userManagement.idm.tip')}>
                                    <FontAwesomeIcon icon="info-circle" />
                                </span>
                            </div>
                            
                        </div>
                    </form>
                </div>
            </div>
            {technicalError ? this.renderError(technicalError) : null}
            {
                userFound===null?<div></div>:
                <ConnectorComponent t={t} connector={connector} isRenderChildren={()=>userFound===true}>
                    <div className="card mt-2">
                        <div className="card-body">
                            <h6 className="card-subtitle mb-2">{t('userManagement.idm.results.header')}</h6>
                            {this.renderResults(user,t)}
                        </div>
                    </div>
                </ConnectorComponent>
            }
        </div>
        );
    }
}

class UserRow extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.value.id,
            identification: this.props.value.identification,
            role: this.props.value.role,
            email: this.props.value.email,
            isActivated:this.props.value.isActivated
        };
    }
    handleInputChange = (event, state) => {
        const target = event.target;
        //const name = target.id;
        //const state = name;
        
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [state]: value });
    }
    createLocalUserObject = () => {
        const user = {
            id: this.state.id,
            identification: this.state.identification,
            role: this.state.role,
            email: this.state.email,
            isActivated:this.state.isActivated
        };
        return user;
    }
    render() {
        const user = this.createLocalUserObject();
        const t = this.props.t;
        return (
            <tr>
                <td scope="col">
                    <input type="text" value={user.identification} disabled />
                </td>
                <td scope="col">
                    {//<select value={permission.kind} onChange={(event) => this.handleInputChange(event, 'kind')}>
                     //   {PermissionKindArray.map((it) => <option key={it} value={it}>{it}</option>)}
                    //</select>
                }
                <input type="text" value={user.email} onChange={(event) => this.handleInputChange(event, 'email')} />
                </td>
                <td scope="col">
                    <select value={user.role} disabled>
                        {RoleArray.map((it) => <option key={it} value={it}>{t("userManagement." + it)}</option>)}
                    </select>
                </td>
                <td scope ="col">
                <div align="center"> <input type="checkbox" onChange={(event) => this.handleInputChange(event, 'isActivated')} checked={user.isActivated===true}/></div>
                </td>
                <td scope="col">
                    <button type="button" onClick={() => this.props.update(user)} className="btn btn-warning btn-xs">
                        <FontAwesomeIcon icon="redo" />
                    </button>
                </td>
            </tr>
        );
    }
}

class UserBody extends React.Component
{
    constructor(props) {
        super(props);
    }

    onUpdate = (user) => {
        this.props.onUserChanged(user, 'edit');
    }

    render() {

        const users = this.props.users;
        
        const body = [];
        
        users.forEach(user => {
            body.push(
                <UserRow key={user.id} value={user} t={this.props.t} update={this.onUpdate} />
            );
        });

        return (
            <tbody>
                {body}
            </tbody>
        );
    }
}
class UserTable extends React.Component
{
    constructor(props) {
        super(props);
    }
    changeRow = (user, change) => {
        this.props.onDataTableChanged(user, change);
    }
    render() {
        return (
            <div>
                <table className="table table-striped" id={this.props.name}>
                    <HeaderRow row={userHeader} t={this.props.t}></HeaderRow>
                    <UserBody t={this.props.t} users={this.props.data} onUserChanged={this.changeRow} />
                </table>
            </div>);
    }
}
class IdmUserList extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            idmusers:null,
            technicalError:null
        };
    }
    getUsers=()=>
    {
        const {connector} = this.props;
        connector.send( buildRequest( endpoints.administration.idmUser.url(), endpoints.administration.idmUser.method(), true),
        ( json ) => {
            if ( this.props.connector.hasJson() && this.props.connector.is200() ){
                //if(json)
                //{
                    this.setState({idmusers:json,dataAcquired:true});
                //}
            }
            else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
              // render occured errors (error information is part of the state of the connector)
              //console.log(error);
              this.setState({idmusers:null,dataAcquired:false});
            } 
        });
    }
    componentDidMount() {
        this.getUsers();
    }

    renderError = (error) => {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>{this.props.t('userManagement.idm.error')}</strong> {error}
            </div>
        );
    }
    onDataTableChange = (row, change) => {
        const { connector } = this.props;

        let url, method, includeCredentials, id;
        switch (change) {
            //add entry
            //change entry
            case 'edit':
                url = endpoints.administration.updateUser.url;
                id = row.id;
                method = endpoints.administration.updateUser.method;
                includeCredentials = true;
                break;
            default:
        }
        connector.send(buildRequest(url(id), method(), includeCredentials, row),
            (json) => {
                if (this.props.connector.is200()) {console.log(json); this.getUsers();this.setState({ technicalError:null });}
                else if (connector.hasError() || (connector.hasResponse() && !connector.is200())) {
                    console.log(json);
                    //something went utterly wrong :-(
                    if (json && json["userDto.email"]) { this.setState({ technicalError: json["userDto.email"] }); }
                }
            }
        );
        }
    render()
    {
        const {t,connector} = this.props;
        const {idmusers,dataAcquired,technicalError} = this.state;
        const tablename = 'idmuserlist';
        return (
            <div className="card">
                <div className="card-body">
                <h6 className="card-subtitle mb-2">{t('userManagement.idm.listheader')}</h6>
                {technicalError ? this.renderError(technicalError) : null}
                <ConnectorComponent t={t} connector={connector} isRenderChildren={()=>dataAcquired===true}>
                    <UserTable data={idmusers} name={tablename} onDataTableChanged={this.onDataTableChange} t={t} />
                   {
                    // <Table data={idmusers} name="idmuserlist" t={t} />>
                   }
                </ConnectorComponent>
                </div>
            </div>
        );
    }
}
const userHeader = ['identification', 'email', 'effectiverole','isActivated'];
IdmQuery.propTypes = IdmQueryTypes;
IdmUserList.propTypes = IdmUserListTypes;
UserBody.propTypes = UserBodyTypes;
UserTable.propTypes = UserTableTypes;
UserRow.propTypes = UserRowTypes;
export{
    IdmQuery,
    IdmUserList
};