import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'

import { UserRole } from '../../domain/UserRole'
import { buildReferenceItemDto, formValue, propertyId } from '../../domain/DataTransferObjects'

// views: single value input
import SingleValueInput from './SingleValueInput'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'
import MultiValueInput from './MultiValueInput'


class StepLocation extends React.Component {
    constructor( props ) {
        super( props )
        this.handleCountryChange = this.handleCountryChange.bind( this )
        this.handleInputChange = this.handleInputChange.bind( this )
    }

    handleCountryChange( country ) {
        const location = Object.assign( {}, this.props.contribution.location, { country: country } )
        this.props.callbacks.onLocationChange( location )
    }

    handleInputChange( event ) {
        const target = event.target
        // note: handle the callback of the multi value input for indications
        if ( Array.isArray( event ) && target === undefined ) {
            const location = Object.assign( {}, this.props.contribution.location, { indications: event } )
            this.props.callbacks.onLocationChange( location )
        } else if ( event.type === 'change' && target.name === 'place' ) {
            const value = buildReferenceItemDto( target.value )
            // create a shallow copy of the data transfer object with the property value changed + fire on change for dto
            const location = Object.assign( {}, this.props.contribution.location, { place: value } )
            this.props.callbacks.onLocationChange( location )
        }
    }

    render() {
        const { t, navigation, categorization, wasValidated, authService } = this.props
        const { location } = this.props.contribution
        // extract editable properties of this step
        const country = location.country
        const place = formValue( propertyId( location.place ) )
        const indications = location.indications
        // note: the country is required only for authors when the particular place is set to country
        const isCountryRequired = authService.isAuthor() && categorization.isCountryRequired( place )
        // see step one for why we need to switch on invalid feedback manually
        const showInvalidFeedbackPlace = wasValidated === true && authService.isAuthor() && !( place !== undefined && categorization.particularPlace().some( (it) => it.id === place ) )
        const showInvalidFeedbackCountry = wasValidated === true && isCountryRequired === true && !( country !== undefined && ( country.id !== undefined || country.label !== undefined ) )
        // note: the country is either required and showed in the first card or optional and showed in the second card
        const countryInput = <div className="row form-group">
            <label className="col-md-3 col-form-label" htmlFor="country">{ t('contribute.steps.location.country.label.country') }</label>
            <div className="col-md-9">
                <SingleValueInput id="country" value={country} isRequired={isCountryRequired} datalist={categorization.country()}
                        placeholder={t('contribute.steps.location.country.placeholder.country')} ariaToggleSuggestions={t('contribute.steps.location.country.aria.toggleCountry')}
                        onValueChange={this.handleCountryChange} />
                { showInvalidFeedbackCountry === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.location.country.validation.country') }</div> : null }
            </div>
        </div>
        return (
            <div>
                <div className="card">
                    <h6 className="card-header">{ t('contribute.steps.location.location.header') }</h6>
                    <div className="card-body">
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.location.location.label.place') }</legend>
                                <div className="col-md-9">
                                    { categorization.particularPlace().map( (it, i) =>
                                        <div key={it.id} className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" id={'place' +it.id} name="place" value={it.id} required={ authService.isAuthor() } checked={place === it.id} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={'place' +it.id}>{ it.label }</label>
                                        </div>
                                    ) }
                                    { showInvalidFeedbackPlace === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.location.location.validation.place') }</div> : null }
                                </div>
                            </div>
                        </fieldset>
                        { isCountryRequired === true ? countryInput : null }
                    </div>
                </div>
                <div className="card mt-3">
                    <h6 className="card-header">{ t('contribute.steps.location.indications.header') }</h6>
                    <div className="card-body">
                        { isCountryRequired === false ? countryInput : null }
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="indication">{ t('contribute.steps.location.indications.label.indications') }</label>
                            <div className="col-md-9">
                                <MultiValueInput values={indications} isRequired={false} id="indication" placeholder={t('contribute.steps.location.indications.placeholder.indications')} 
                                        buttonText={t('contribute.steps.location.indications.action.conclude')} ariaRemoveLabel={t('contribute.steps.location.indications.aria.remove')}
                                        hintText={t('contribute.steps.location.indications.hint.indications')}
                                        onValuesChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <BackNextNavigation t={t} navigation={navigation} />
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(StepLocation), { require: UserRole.AUTHOR } )
