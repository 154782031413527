/**
 * basic react module dependencies
 */
import React from 'react'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'

// language + translation service
import { LocalizableHoc } from '../../services/LanguageService'

/**
 * custom cis related helper dependencies
 */
import { config } from '../../config/config'

/**
 * the component provides the functionality to show 'foldable' read more-content
 * 
 * @version 1.0.0
 * @since 1.2.0
 */
export class ReadMore extends React.Component {

  /**
   * default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      truncated: false,
      lines: config.readmore.lines
    };

    this.handleTruncate = this.handleTruncate.bind(this)
    this.toggleLines = this.toggleLines.bind(this)
  }

  /**
   * changes (toogles) the current truncated state for the read more content
   * @param {*} truncated the new truncated state from the ui 
   */
  handleTruncate(truncated) {
    if (this.state.truncated !== truncated) {
      this.setState({ truncated })
    }
  }

  /**
   * toogles the (text is) expanded state for the text content
   * @param {*} event the click event from the ui component
   */
  toggleLines(event) {
    event.preventDefault();
    this.setState({ expanded: !this.state.expanded })
  }

  /**
   * Main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t, children } = this.props
    const { expanded, truncated, lines } = this.state

    return (
      <span >
        <Truncate
          lines={!expanded && lines}
          ellipsis={(
            // eslint-disable-next-line
            <span>... <a href='#' onClick={this.toggleLines}>{t('overview.card.readmore.more')}</a></span>
          )}
          onTruncate={this.handleTruncate}
        >
          <span className="fullWidth">
            {children}
          </span>
        </Truncate>
        {!truncated && expanded && (
          // eslint-disable-next-line
          <span> <a href='#' onClick={this.toggleLines}>{t('overview.card.readmore.less')}</a></span>
        )}
      </span>
    )
  }
}

export default LocalizableHoc()(ReadMore)

/**
 * property type definitions (read more component content specification)
 */
ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
}