

/**
 * Provides useful helper functions to create data transfer objects used to send data to the backend.
 */

/** Returns the id property of a data transfer object. */
export function propertyId( dto ) {
    return dto === undefined || dto === null ? undefined : dto.id
}

/** Returns the html form field value for the given value. */
export function formValue( value ) {
    return value === undefined || value === null ? "" : value
}

/** Returns an object with the id and label property of the given dto as html form field values. */
export function formObject( dto ) {
    if ( dto === undefined || dto === null ) return { id: "", label: "" }
    return {
        id: formValue( dto.id ),
        label: formValue( dto.label )
    }
}

/** Returns an array for the given value. */
export function formArray( value ) {
    if ( value === undefined || value === null || Array.isArray( value ) === false ) return []
    return value
}


/** Returns the numerical value of the given value or undefined. */
export function buildNumberValue( value ) {
    const num = Number.parseInt( value )
    return Number.isNaN( num ) === true ? undefined : num
}


/** Returns flat copy of the given source object with the given properties. All other properties are ignored and not cloned! */
export function cloneObject( source, ...properties ) {
    const result = {}
    Object.keys( source ).forEach( (it, i) => {
        const value = source[it]
        if ( properties.includes( it ) && value !== undefined && value !== null ) result[it] = value
    } )
    return result
}

/** Assigns the given properties of the source object to the target object. All other properties are ignored and not cloned! */
export function cloneProperties( target, source, ...properties ) {
    Object.keys( source ).forEach( (it, i) => {
        const value = source[it]
        if ( properties.includes( it ) && value !== undefined && value !== null ) target[it] = value
    } )
    return target
}


/** Returns true if any of the given properties, references or localized references of the given dto have some data, otherwise false. */
export function hasDataDto( dto, properties = [], references = [], localizedReferences = [] ) {
    if ( dto === undefined || dto === null ) return false
    // note: we do not assume that all properties, references or localized references given must exist on the dto as non existing things doesn't contain any data!
    return Object.keys( dto ).some( (it) =>
        isKeySpecified( it, properties, references, localizedReferences )
        && ( isEmptyValue( it, dto, properties, references, localizedReferences ) === false )
    )
}

/** Returns true if any of the given properties, references or localized references of the given data transfer object is empty or does not exist, otherwise false. */
export function isEmptyDto( dto, properties = [], references = [], localizedReferences = [] ) {
    if ( dto === undefined || dto === null ) return true
    // check if the dto has the given properties, references and localized references
    if ( hasAllKeys( dto, properties, references, localizedReferences ) === false ) return true
    return Object.keys( dto ).some( (it) => isEmptyValue( it, dto, properties, references, localizedReferences ) === true )
}

function isKeySpecified( key, properties, references, localizedReferences ) {
    if ( properties.some( it => it === key ) || references.some( it => it === key ) || localizedReferences.some( it => it === key ) )  {
        return true
    }
    return false
}

/** Returns true if the given dto has the given properties, references and localized references, otherwise false. */
function hasAllKeys( dto, properties = [], references = [], localizedReferences = [] ) {
    const keys = Object.keys( dto )
    if ( properties.some( it => keys.includes(it) === false ) || references.some( it => keys.includes(it) === false ) || localizedReferences.some( it => keys.includes(it) === false ) )  {
        return false
    }
    return true
}

/** Returns true if the value with the given key on the given dto is empty, otherwise false. */
function isEmptyValue( key, dto, properties = [], references = [], localizedReferences = [] ) {
    const value = dto[key]
    const isMultiValued = Array.isArray( value )
    if ( properties.includes( key ) && isEmpty( value ) ) return true
    // reference or localized reference items may be multi valued!
    if ( references.includes( key ) && ( isMultiValued ? value.length === 0 || value.some( it => isEmptyDto( it, ['id'] ) ) : isEmptyDto( value, ['id'] ) ) ) return true
    if ( localizedReferences.includes( key ) && ( isMultiValued ? value.length === 0 || value.some( it => isEmptyDto( it, ['id'] ) && isEmptyDto( it, ['label'] ) ) : isEmptyDto( value, ['id'] ) && isEmptyDto( value, ['label'] ) ) ) return true
    return false
}

/** Returns true if the given value is undefined, null, an empty string or a string containing just whitespace, otherwise returns false. */
export function isEmpty( value ) {
    return value === undefined || value === null || ( Array.isArray( value )
            ? value.length === 0 || ( value.length === 1 && String( value[0] ).trim().length === 0 )
            : String( value ).trim().length === 0 )
        ? true : false
}


/** Returns an initialized text input item from the given reference or localized item and the given official values. */
export function initTextInputItem( dto, values ) {
    if ( dto === undefined || dto === null ) return buildTextInputItem()
    const item = buildTextInputItem( dto.id, dto.label, false )
    if ( values !== undefined && values !== null && Array.isArray( values ) === true ) {
        // note: to initialize the is official field we just check the id as the label might be in another language
        item.isOfficial = values.some( it => it.id === item.id )
    }
    return item
}

/**
 * Returns an object like a reference localized item dto but without trimming whitespace from the value and with an additional field isOfficial
 * to distinct individual values from official categorization values.
 */
export function buildTextInputItem( id, value, isOfficial, index ) {
    const num = Number.parseInt( id )
    const isNan = Number.isNaN( num )
    const label = value === undefined || value === null ? "" : String( value )
    const isEmpty = label.length === 0
    const idx = index === undefined || index === null ? undefined : Number.parseInt( index )
    return {
        id: isNan === true ? undefined : num,
        label: isEmpty === true ? "" : label,
        isOfficial: isOfficial === true ? true : false,
        index: Number.isNaN( idx ) === true ? undefined : idx
    }
}


/**
 * Returns a reference item dto with the given value as id or undefined.
 * @param {String} value
 */
export function buildReferenceItemDto( value ) {
    const id = Number.parseInt( value )
    if ( Number.isNaN( id ) ) {
        return { id: undefined }
    } else {
        return { id: id }
    }
}

/**
 * Returns a reference item dto with the given id and value or undefined.
 * @param {String} value
 * @param {String} id
 */
export function buildReferenceLocalizedItemDto( id, value ) {
    const num = Number.parseInt( id )
    const isNan = Number.isNaN( num )
    const label = value === undefined || value === null ? "" : String( value ).trim()
    const isEmpty = label.length === 0
    return { id: isNan === true ? undefined : num, label: isEmpty === true ? "" : label }
}


// - - - contribution related functions

/** Returns true if the author is an actor, otherwise false if the author has some data or undefined if the author is empty. */
export function isAuthorActor( actors, author ) {
    if ( !actors || !author || !Array.isArray( actors ) ) return undefined
    // note: author is actor is true when an actor has the property isAuthor set to true
    // author is actor is false, if the author dto has any data property value set, i. e. excluding experience id, empty nationalities array, isContributor
    // author is actor is undefined, if no actor has isAuthor set to true and the author contains just initialized data
    // note: author is contributor has no relation to author is actor or not!
    return actors.some( (it) => it.isAuthor === true ) ? true
            : isEmptyDto( author, ['minAge', 'maxAge'], ['gender'], ['nationalities'] ) === false ? false : undefined
}

/** Builds an empty contribution object or a contribution object initialized with values from the given experience dto. */
export function buildContribution( dto ) {
    // init contribution object structure with initialized multi value as well as boolean properties
    const result = {
        experience: {
            contactDomains: dto ? Array.isArray( dto.contactDomains ) ? dto.contactDomains : [] : [],
            hotspots: dto ? Array.isArray( dto.hotspots ) ? dto.hotspots : [] : [],
            communicationDomains: dto ? Array.isArray( dto.communicationDomains ) ? dto.communicationDomains : [] : [],
            media: dto ? Array.isArray( dto.media ) ? dto.media : [] : []
        },
        textStory: {
            isTranscribed: dto ? dto.textStory ? dto.textStory.isTranscribed === true ? true : false : false : false,
            languages: dto ? dto.textStory ? Array.isArray( dto.textStory.languages ) ? dto.textStory.languages : [] : [] : []
        },
        location: {
            indications: dto ? dto.location ? Array.isArray( dto.location.indications ) ? dto.location.indications : [] : [] : []
        },
        author: {
            isActor: dto ? dto.author ? dto.author.isActor === true ? true : dto.author.isActor === false ? false : undefined : undefined : undefined,
            isContributor: dto ? dto.author ? dto.author.isContributor === true ? true : false : false : false,
            nationalities: dto ? dto.author ? Array.isArray( dto.author.nationalities ) ? dto.author.nationalities : [] : [] : []
        },
        reflection: {
            keywords: dto ? dto.reflection ? Array.isArray( dto.reflection.keywords ) ? dto.reflection.keywords : [] : [] : []
        },
        rightsOfUse: {
            grants: dto ? Array.isArray( dto.rightsOfUse ) ? dto.rightsOfUse : [] : []
        },
        actors: dto ? Array.isArray( dto.actors ) ? dto.actors : [] : [],
        // track all actors to be removed from the backend during next save
        removableActors: []
    }
    // copy single value properties
    if ( dto !== undefined && dto !== null ) cloneProperties( result.experience, dto, 'id', 'created', 'title', 'type', 'origin' )
    if ( dto && dto.textStory ) cloneProperties( result.textStory, dto.textStory, 'experienceId', 'story', 'kind', 'perspective' )
    if ( dto && dto.location ) cloneProperties( result.location, dto.location, 'experienceId', 'country', 'place' )
    if ( dto && dto.author ) cloneProperties( result.author, dto.author, 'experienceId', 'minAge', 'maxAge', 'gender' )
    if ( dto && dto.reflection ) cloneProperties( result.reflection, dto.reflection, 'experienceId', 'reflection' )
    if ( dto && dto.rightsOfUse ) cloneProperties( result.rightsOfUse, dto.rightsOfUse, 'experienceId' )
    // TODO: collection
    // assign the experience id to all dto's
    if ( dto ) result.textStory.experienceId = result.location.experienceId = result.author.experienceId = result.reflection.experienceId = result.rightsOfUse.experienceId = dto.id
    // return the result
    return result
}

/** Perform data migration to the current contribution structure from former versions that may be fetched from local storage. */
export function updateContribution( contribution ) {
    const result = Object.assign( {}, contribution )
    // upgrade frontend version 2.1 to 2.2
    doMigrateVersion21To22( result, contribution )
    return result
}
export function buildQueryDto(data,quantity)
{
    const result = {
        search: data.search,
        isContributor: data.isContributor,
        isAuthor: data.isAuthor,
        checkRightAssignment: data.isCheckRightAssignment,
        withReflection: (data.withReflection)?data.mustBeWithReflection:undefined,
        withIndividualCategorization: (data.withIndividualCategorization)?data.mustBeWithIndividualCategorization:undefined,
        experienceTypeId: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "experienceType") : undefined)) === true ? propertyId(getValues(data.filterExpressions.filter(expression => expression.category === "experienceType"))[0]) : undefined,
        dataOriginId: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "dataOrigin") : undefined)) === true ? propertyId(getValues(data.filterExpressions.filter(expression => expression.category === "dataOrigin"))[0]) : undefined,
        categories: transformToEnum(propertyIds(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "individualCategory") : undefined))),
        before: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "date") : undefined)) === true ? (getValues(data.filterExpressions.filter(expression => expression.category === "date"))[0].before === "" ? undefined : getValues(data.filterExpressions.filter(expression => expression.category === "date"))[0].before) : undefined,
        after: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "date") : undefined)) === true ? (getValues(data.filterExpressions.filter(expression => expression.category === "date"))[0].after === "" ? undefined : getValues(data.filterExpressions.filter(expression => expression.category === "date"))[0].after) : undefined,

        contactDomainIds: propertyIds(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "contactDomain") : undefined)),
        hotspotIds: propertyIds(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "hotspot") : undefined)),
        interactionMediumIds: propertyIds(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "interactionMedium") : undefined)),
        communicationDomainIds: propertyIds(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "communicationDomain") : undefined)),

        dataKindId: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "dataKind") : undefined)) === true ? propertyId(getValues(data.filterExpressions.filter(expression => expression.category === "dataKind"))[0]) : undefined,
        narrativePerspectiveId: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "narrativePerspective") : undefined)) === true ? propertyId(getValues(data.filterExpressions.filter(expression => expression.category === "narrativePerspective"))[0]) : undefined,
        isTranscribed: (data.isTranscribed) ? data.mustBeTranscribed : undefined,
        textLanguageIds: propertyIds(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "language") : undefined)),
        placeId: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "particularPlace") : undefined)) === true ? propertyId(getValues(data.filterExpressions.filter(expression => expression.category === "particularPlace"))[0]) : undefined,
        countryId: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "country") : undefined)) === true ? propertyId(getValues(data.filterExpressions.filter(expression => expression.category === "country"))[0]) : undefined,
        rightOfUseIds:hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "rightOfUse") : undefined)) === true ? propertyId(getValues(data.filterExpressions.filter(expression => expression.category === "rightOfUse"))[0]) : undefined,
        actorFilters: hasValue(getValues(data.filterExpressions ? data.filterExpressions.filter(expression => expression.category === "actor") : undefined)) === true ? getActors(data.filterExpressions.filter(expression => expression.category === "actor")) : undefined,
        quantity:data.hits?data.hits:5
    }
    console.log(result)
    return result
}

function transformToEnum(individualCategoryIds) {
    const IndividualCategory = [
        "CONTACT_DOMAIN",
        "HOTSPOT",
        "NATIONALITY",
        "ACTOR_LANGUAGE",
        "MEDIA_LANGUAGE",
        "COUNTRY",
        "LOCATION_INDICATION",
        "REFLECTION_KEYWORD"
    ]
    if (individualCategoryIds) {
        const result = individualCategoryIds.map((value) => {
            return IndividualCategory[value];
        })
        return result
    }
}

function getActors(actors) {
    //transform values array to respective actor array
    if (actors[0]) {
        const transformed = actors[0].values.map((actor) => {
            let newActor = {
                typeId: actor.actorType ? actor.actorType.id : undefined,
                languageIds: propertyIds(actor.languages),
                genderId: actor.gender ? actor.gender.id : undefined,
                isMixedGender: undefined,
                minAge: actor.ageLowerBound,
                maxAge: actor.ageUpperBound,
                nationalityIds: propertyIds(actor.nationalities),
                familiarityLevelId: propertyId(actor.familiarityLevel),
                minExtend: actor.groupSize,
                maxExtend: actor.groupSizeUpperBound
            }
            if (newActor.typeId == 0) { newActor.typeId = undefined }
            if (newActor.genderId === 0) { newActor.isMixedGender = true; newActor.genderId = undefined }
            if (newActor.minAge === "") { newActor.minAge = undefined }
            if (newActor.maxAge === "") { newActor.maxAge = undefined }
            if (newActor.minExtend === "") { newActor.minExtend = undefined }
            if (newActor.maxExtend === "") { newActor.maxExtend = undefined }
            return newActor
        })
        return transformed
    }
}

function hasValue(possibleProperty)
{
    let result=false;
    if(Array.isArray(possibleProperty))
    {
        possibleProperty.length>0?result=true:result=false
    }
    else
    {
        result=false
    }
    return result;
}

function getValues(categoryFilter)
{
    if(categoryFilter.length<1)
    {
        return undefined
    }
    else
    {
        return categoryFilter[0].values
    }
}

function propertyIds(properties)
{
    const result = properties?properties.map(prop=>propertyId(prop)):undefined;
    return result
}

/** Perform data migration from former versions of the contribution structure that may be fetched from local storage. */
function doMigrateVersion21To22( v22, v21 ) {
    // multi value language: textStory.language to testStory.languages
    if ( v22 && v22.textStory && Array.isArray( v22.textStory.languages ) && v22.textStory.languages.length === 0 && v21 && v21.textStory && v21.textStory.language ) {
        v22.textStory.languages.push( v21.textStory.language )
    }
    // authors reflection: add reflection dto
    if ( v22 && !v22.reflection ) v22['reflection'] = { keywords: [] }
    return v22
}
