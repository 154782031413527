import React,{Component} from 'react';
import {TableTypes,HeaderRowTypes} from './Types';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import datatables from 'datatables.net';
//import { UserManagement } from './UserManagement';

function reloadTableData(names) {
    const table = $('.data-table-wrapper').find('table').DataTable();
    table.clear();
    table.rows.add(names);
    table.draw();
}

class Table extends Component{
    constructor(props) {
        super(props);
        
    }
    componentDidMount() {
        
        $('#' + this.props.name).DataTable({
            dom: '<"data-table-wrapper"t>',
            data: this.props.data,
            columnDefs: [
                {
                  targets: 0,
                  // eslint-disable-next-line no-unused-vars
                  createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
                    $(cell).css('color', 'green');   //add style to cell
                  }
                }],
            columns:[
                {
                    title:'id',
                    data:'id',
                    visible:false

                },
                {
                    title:'identification',
                    data:'identification'},
                {
                    title:'role',
                    data:'role'},
                {
                    title:'email',
                    data:'email'
                },
                {
                    title:'isActivated',
                    data:'isActivated'
                }
            ],
            ordering: false
        });
    }
    componentWillUnmount(){
        $('.data-table-wrapper').find('table').DataTable().destroy(true);
     }
 
     shouldComponentUpdate(nextProps) {
         //if(nextProps.data==null || this.props.data==null )
         //{return false;}
         //if (Object.keys(nextProps.data).length !== Object.keys(this.props.data).length) {
             reloadTableData(nextProps.data);
             return false;
         //} else {
             //updateTable(nextProps.names);
         //}
         //return false;
     }
    render() {
        return (
            <div >
                <table id={this.props.name}>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>identification</th>
                            <th>role</th>
                            <th>email</th>
                            <th>isActivated</th>
                        </tr>
                    </thead>
                </table>
            </div>
            );
    }
}


class HeaderRow extends React.Component
{
    constructor(props) {
        super(props);
    }
    render()
    {
        const row = this.props.row;
        const t = this.props.t;
        const header=[];
        const addRow = this.props.addRow;
        row.forEach(entry=>{header.push(<th key={entry} align="center" id={entry} scope="col">{t("userManagement." + entry)}</th>);});
        
        let button;
        if(addRow)
        {
            button = (
                    <th key="change" id="change" scope="col">{t("userManagement.change")} | <button type="button" onClick={addRow} className="btn btn-primary btn-xs">
                        <FontAwesomeIcon icon="plus-circle" />
                    </button>
                    </th>
                );
        }
        else
        {
            header.push(<th key="change" id="change" scope="col">{t("userManagement.change")}</th>);
        }

        return(
            <thead className="thead-light">
            <tr>
               {header}
               {button?button:null}
            </tr>
            </thead>
        );
    }
}


HeaderRow.propTypes =HeaderRowTypes;

Table.propTypes = TableTypes;
export{
    Table,
    HeaderRow
};