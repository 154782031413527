import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'
import { buildActorHumanDistinctableString } from '../../services/Aggregator'

import { UserRole } from '../../domain/UserRole'
import { ActorTypes, YesNoEnum } from '../../domain/CategorizationData'
import { buildReferenceItemDto, buildNumberValue, formValue, propertyId } from '../../domain/DataTransferObjects'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'
import MultiValueInput from './MultiValueInput'


class StepAuthor extends React.Component {
    constructor( props ) {
        super( props )
        this.handleReflectionChange = this.handleReflectionChange.bind( this )
        this.handleNationalityChange = this.handleNationalityChange.bind( this )
        this.handleInputChange = this.handleInputChange.bind( this )
    }

    handleNationalityChange( nationalities ) {
        const author = Object.assign( {}, this.props.contribution.author, { 'nationalities': nationalities } )
        this.props.callbacks.onAuthorChange( author )
    }

    handleReflectionChange( eventOrkeywords ) {
        if ( !Array.isArray( eventOrkeywords ) && !eventOrkeywords.target ) return
        const reflection = Object.assign( {}, this.props.contribution.reflection, Array.isArray( eventOrkeywords ) 
            ? { 'keywords': eventOrkeywords }
            : { [eventOrkeywords.target.name]: eventOrkeywords.target.value } )
        this.props.callbacks.onReflectionChange( reflection )
    }

    handleInputChange( event ) {
        const target = event.target
        if ( target === undefined ) return
        const propname = target.name
        if ( event.type === 'change' && ( propname === 'isActor' || propname === 'actor' ) ) {
            const { contribution, authorIsActor, categorization } = this.props
            // determine isActor property of author
            const isActor = propname === 'isActor' 
                ? target.value === YesNoEnum.Yes ? true : target.value === YesNoEnum.No ? false : undefined
                // just use the ui only property
                : authorIsActor
            // determine index of actor which is author
            const humanActorCount = contribution.actors.reduce( (acc, it) => categorization.isActorType( it.type, ActorTypes.Human ) ? acc +1 : acc, 0 )
            const index = propname === 'isActor' 
                // on isActor change the only human actor or undefined
                ? humanActorCount === 1 
                    ? contribution.actors.findIndex( it => categorization.isActorType( it.type, ActorTypes.Human ) )
                    : undefined
                // on actor change the selected index of the human actor
                : buildNumberValue( target.value )
            // modify the actors array
            const actors = contribution.actors.map( (it, i) => {
                if ( categorization.isActorType( it.type, ActorTypes.Human ) ) {
                    if ( index === i ) {
                        return Object.assign( {}, it, { isAuthor: true } )
                    } else if ( it.isAuthor === true ) {
                        return Object.assign( {}, it, { isAuthor: false } )
                    }
                }
                return it
            } )
            // modify the author
            const author = Object.assign( {}, contribution.author, { isActor: isActor === true && index >= 0 && index < actors.length } )
            this.props.callbacks.onAuthorIsActorChange( isActor, actors, author )
        } else if ( event.type === 'change' ) {
            const name = propname === 'contributorIsAuthor' ? 'isContributor' : propname
            const value = propname === 'minAge' || propname === 'maxAge' 
                ? buildNumberValue( target.value ) : propname === 'contributorIsAuthor' ? target.checked : buildReferenceItemDto( target.value )
            // create a shallow copy of the data transfer object with the property value changed + fire on change for dto
            const author = Object.assign( {}, this.props.contribution.author, { [name]: value } )
            this.props.callbacks.onAuthorChange( author )
        }
    }

    render() {
        const { t, navigation, categorization, authorIsActor, wasValidated, authService } = this.props
        const { actors, author, reflection } = this.props.contribution
        const contributorIsAuthor = author.isContributor
        const humanActors = actors.filter( (it) => !it || !it.type ? undefined : categorization.actorTypeByProperty( it.type ) === ActorTypes.Human )
        // extract editable properties of this step
        // TODO: are you the author? -> user profile or license grant is originator?
        const isActorValue = authorIsActor === true ? YesNoEnum.Yes : authorIsActor === false ? YesNoEnum.No : undefined
        const actorIndex =  actors.findIndex( (it) => it.isAuthor === true )
        const actorIndexValue = actorIndex === -1 ? "" : actorIndex
        const actorCount = actors.reduce( (acc, it) => categorization.actorTypeByProperty( it.type ) === ActorTypes.Human ? acc +1 : acc, 0 )
        // TODO: maybe externalize a component to edit human actor information that could be reused by author?
        const gender = formValue( propertyId( author.gender ) )
        const minAge = formValue( author.minAge )
        const maxAge = formValue( author.maxAge )
        const reflectionText = formValue( reflection.reflection )
        const nationalities = author.nationalities
        const keywords = reflection.keywords
        const showInvalidFeedbackIsActor = wasValidated === true && authService.isAuthor() && authorIsActor !== false && authorIsActor !== true
        const showInvalidFeedbackGender = wasValidated === true && authService.isAuthor() && !categorization.gender().some( (it) => it.id === gender )
        const showInvalidFeedbackNationalities = wasValidated === true && authService.isAuthor() && nationalities.length === 0
        return (
            <div>
                <div className="card">
                    <h6 className="card-header">{ t('contribute.steps.author.header') }</h6>
                    <div className="card-body">
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.author.label.isAuthor') }</legend>
                                <div className="col-md-9">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id={'contributorIsAuthor'} name="contributorIsAuthor" checked={contributorIsAuthor === true} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor={'contributorIsAuthor'}>{ t('contribute.steps.author.label.contributorIsAuthor') }</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.author.label.isActor') }</legend>
                                <div className="col-md-9">
                                    { YesNoEnum.values().map( (it) => 
                                        <div key={it} className=" form-check form-check-inline">
                                            <input className="form-check-input" type="radio" id={ 'isActor' + it } name="isActor" value={ it } required={ authService.isAuthor() } checked={it === isActorValue} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={ 'isActor' + it }>{ t('contribute.steps.author.label.isActor' + it) }</label>
                                        </div>
                                    ) }
                                    { showInvalidFeedbackIsActor === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.author.validation.isActor') }</div> : null }
                                </div>
                            </div>
                        </fieldset>
                        { authorIsActor === true ?
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="actor">{ t('contribute.steps.author.label.actor') }</label>
                            <div className="col-md-9">
                                <select className="form-control text-truncate" id="actor" name="actor" required={authorIsActor === true} value={actorIndexValue} onChange={this.handleInputChange}>
                                    { actorCount === 0 ? <option value="">{ t('contribute.steps.author.empty.actor') }</option>
                                        : <option value="">{ t('contribute.steps.author.placeholder.actor') }</option> }
                                    { actors.map( (it, i) => categorization.actorTypeByProperty( it.type ) !== ActorTypes.Human ? null
                                        : <option key={i} value={i}>{ buildActorHumanDistinctableString( it, humanActors, categorization, t ) }</option> ) }
                                </select>
                                <div className="invalid-feedback">{ t('contribute.steps.author.validation.actor') }</div>
                            </div>
                        </div>
                        : null }
                        { authorIsActor === false ?
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.actors.actor.label.gender') }</legend>
                                <div className="col-md-9">
                                    { categorization.gender().map( (it, i) =>
                                        <div key={it.id} className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" id={'gender' +it.id} name="gender" value={it.id} required={authService.isAuthor()} checked={gender === it.id} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={'gender' +it.id}>{ it.label }</label>
                                        </div>
                                    ) }
                                    { showInvalidFeedbackGender === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.actors.actor.validation.gender') }</div> : null }
                                </div>
                            </div>
                        </fieldset>
                        : null }
                        { authorIsActor === false ?
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="minAge">{ t('contribute.steps.actors.actor.label.age') }</label>
                            <div className="col-md-9 input-group">
                                <div className="input-group-prepend"><span className="input-group-text">{t('contribute.steps.actors.actor.label.agebetween')}</span></div>
                                <input className="form-control" type="text" id="minAge" name="minAge" autoComplete="off" required={authService.isAuthor()} placeholder={t('contribute.steps.actors.actor.placeholder.minAge')} aria-label={t('contribute.steps.actors.actor.aria.minAge')} value={minAge} onChange={this.handleInputChange} />
                                <input className="form-control" type="text" id="maxAge" name="maxAge" autoComplete="off" required={authService.isAuthor()} placeholder={t('contribute.steps.actors.actor.placeholder.maxAge')} aria-label={t('contribute.steps.actors.actor.aria.maxAge')} value={maxAge} onChange={this.handleInputChange} />
                                <div className="invalid-feedback">{ t('contribute.steps.actors.actor.validation.age') }</div>
                            </div>
                        </div>
                        : null }
                        { authorIsActor === false ?
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="nationalities">{ t('contribute.steps.actors.actor.label.nationalities') }</label>
                            <div className="col-md-9">
                                <MultiValueInput values={nationalities} datalist={categorization.nationality()} id="nationalities" isRequired={authService.isAuthor() && nationalities.length === 0} placeholder={t('contribute.steps.actors.actor.placeholder.nationalities')} 
                                        buttonText={t('contribute.steps.actors.actor.action.concludeNationality')} ariaRemoveLabel={t('contribute.steps.actors.actor.aria.removeNationality')} ariaToggleSuggestions={t('contribute.steps.actors.actor.aria.toggleNationality')}
                                        hintText={t('contribute.steps.actors.actor.hint.nationalities')}
                                        onValuesChange={this.handleNationalityChange} />
                                { showInvalidFeedbackNationalities === true ? <div className="invalid-feedback d-block">{ t('contribute.steps.actors.actor.validation.nationalities') }</div> : null }
                            </div>
                        </div>
                        : null }
                    </div>
                </div>
                { contributorIsAuthor !== true && authService.isAdmin() === false ? null :
                    <div className="card mt-3">
                        <h6 className="card-header">{ t('contribute.steps.author.reflection.header') }</h6>
                        <div className="card-body">
                            <div className="row form-group">
                                <div className="col">
                                    <textarea className="form-control" rows="7" name="reflection" autoComplete="off" required={false} placeholder={t('contribute.steps.author.reflection.placeholder')} value={reflectionText} onChange={this.handleReflectionChange} />
                                </div>
                            </div>
                            <div className="row form-group">
                                <label className="col-md-3 col-form-label" htmlFor="keywords">{ t('contribute.steps.author.reflection.keywords.label') }</label>
                                <div className="col-md-9">
                                    <MultiValueInput values={keywords} id="keywords" isRequired={false} onValuesChange={this.handleReflectionChange}
                                            placeholder={t('contribute.steps.author.reflection.keywords.placeholder')} buttonText={t('contribute.steps.author.action.conclude')} 
                                            hintText={t('contribute.steps.author.reflection.keywords.hint')}
                                            ariaRemoveLabel={t('contribute.steps.author.aria.remove')} />
                                </div>
                            </div>
                        </div>
                    </div> }
                <BackNextNavigation t={t} navigation={navigation} />
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(StepAuthor), { require: UserRole.AUTHOR } )
