import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc, authService } from '../../services/AuthService'
import { UserRole } from '../../domain/UserRole'
import { RightOfUseScope, RightOfUse } from '../../domain/CategorizationData'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'
import { EditFeedbackEnum } from './Contribute'

import { Link4Author } from '../Link'

import { isAuthorActor } from '../../domain/DataTransferObjects'
import { buildLabelString, buildAuthorSummary, buildActorSummary, buildDateString } from '../../services/Aggregator'


class StepSummary extends React.Component {
    constructor( props ) {
        super( props )
        this.state = {
            isConfirmingClearData: false
        }
        this.confirmClearData = this.confirmClearData.bind(this)
        this.abortClearData = this.abortClearData.bind(this)
        this.doClearData = this.doClearData.bind(this)
        this.messages = this.messages.bind(this)
    }

    /** Displays a confirmation dialog to discard the current contribution. */
    confirmClearData() {
        this.props.navigation.scrollToTop()
        this.setState( { isConfirmingClearData: true } )
    }

    /** Resets the state of this component. */
    abortClearData() {
        this.setState( { isConfirmingClearData: false } )
    }

    /** Resets the state of this component, clears the current contribution and starts with an empty contribution or the pending edit. */
    doClearData() {
        this.abortClearData()
        this.props.callbacks.handleClearData()
    }

    /** Returns all message that should be displayed. */
    messages() {
        const { isConfirmingClearData } = this.state
        const { t, contribution, authorIsActor, isSaveInProgress, stepsFeedback, isEditRequest, isEditPending, hadUnsavedData, hadUnsavedEdit } = this.props
        const stepsFeedbackKeys = Object.keys( stepsFeedback )
        const hasValidationErrors = stepsFeedbackKeys.some( (it) => stepsFeedback[it] === EditFeedbackEnum.required )
        const isSavedSuccessfully = stepsFeedbackKeys.length > 0 && stepsFeedbackKeys.every( (it) => stepsFeedback[it] === EditFeedbackEnum.saved )
        const isShowingNoCiWarning = contribution.actors.length === 1
        // note: isAuthorActor may return undefined in case it could not be determined!
        const isShowingAuthorIsActorButNoActorIsAuthorError = authorIsActor === true && isAuthorActor( contribution.actors, contribution.author ) === false
        const updated = buildDateString( contribution.updated )
        const messages = [
            /* warning: unsaved edit- hide it when save was successful (the save alert already has the right text and actions) */
            hadUnsavedData === false || hadUnsavedEdit === false || isSavedSuccessfully === true ? null : 
                <div key="1" className="alert alert-danger" role="alert">
                    <h4>{ t('contribute.steps.summary.alerts.unsaved.edit.title') }</h4>
                    <p>
                        { t('contribute.steps.summary.alerts.unsaved.edit.message', { updated: updated } ) }
                        { isEditRequest ? t('contribute.steps.summary.alerts.unsaved.edit.edit') : t('contribute.steps.summary.alerts.unsaved.edit.contribute') }
                    </p>
                    <p>{ t('contribute.steps.summary.alerts.unsaved.edit.concurrentChanges' ) }</p>
                    <button type="button" className="btn btn-sm btn-outline-secondary align-self-end align-self-center" onClick={ this.doClearData }>
                        { isEditPending ? t('contribute.steps.summary.alerts.unsaved.action.edit') : t('contribute.steps.summary.alerts.unsaved.action.contribute') }
                    </button>
                </div>
            , /* warning: unsaved contribution - hide it when save was successful */
            hadUnsavedData === false || hadUnsavedEdit === true || isSavedSuccessfully === true ? null :
                <div key="2" className="alert alert-info" role="alert">
                    <h4>{ t('contribute.steps.summary.alerts.unsaved.contribute.title') }</h4>
                    <p>
                        { t('contribute.steps.summary.alerts.unsaved.contribute.message', { updated: updated } ) }
                        { isEditRequest ? t('contribute.steps.summary.alerts.unsaved.contribute.edit') : t('contribute.steps.summary.alerts.unsaved.contribute.contribute') }
                    </p>
                    <button type="button" className="btn btn-sm btn-outline-secondary align-self-end align-self-center" onClick={ this.doClearData }>
                        { isEditPending ? t('contribute.steps.summary.alerts.unsaved.action.edit') : t('contribute.steps.summary.alerts.unsaved.action.contribute') }
                    </button>
                </div>
            , /* validation: unsuccessful */
            hasValidationErrors === false ? null :
                <div key="3" className="alert alert-danger" role="alert">
                    <strong>{t('contribute.steps.summary.alerts.validation.unsuccessful.title')} </strong>
                    <span>{t('contribute.steps.summary.alerts.validation.unsuccessful.message')}</span>
                    { /* error: author is actor but no actor is author */ }
                    { isShowingAuthorIsActorButNoActorIsAuthorError === false ? null :
                        <dl className="d-flex mb-0">
                            <dt className="text-nowrap mr-1">{t('contribute.steps.summary.alerts.validation.authorIsActorButNoActorIsAuthor.title')}</dt>
                            <dd className="flex-grow-1">{t('contribute.steps.summary.alerts.validation.authorIsActorButNoActorIsAuthor.message')}</dd>
                        </dl> }
                </div>
            , /* validation: successful */
            hasValidationErrors === true || isSavedSuccessfully === true ? null :
                <div key="4" className={ isShowingNoCiWarning === true ? 'alert alert-warning' : 'alert alert-info' } role="alert">
                    <strong>{t('contribute.steps.summary.alerts.validation.successful.title')} </strong>
                    <span>{t('contribute.steps.summary.alerts.validation.successful.message')}</span>
                    { /* warning: experience is no critical incident */ }
                    { isShowingNoCiWarning === false ? null :
                        <dl className="d-flex mb-0">
                            <dt className="text-nowrap mr-1">{t('contribute.steps.summary.alerts.validation.noci.title')}</dt>
                            <dd className="flex-grow-1">{t('contribute.steps.summary.alerts.validation.noci.message')}</dd>
                        </dl> }
                </div>
            , /* confirm: clear data */
            isConfirmingClearData === false ? null : 
                <div key="5" className="alert alert-warning d-flex" role="alert">
                    <span className="flex-fill">
                        <strong>{ t('contribute.steps.summary.alerts.clear.title') } </strong>
                        { isEditRequest ? isEditPending ? t('contribute.steps.summary.alerts.clear.pending')
                            : t('contribute.steps.summary.alerts.clear.editing') : t('contribute.steps.summary.alerts.clear.message') }
                    </span>
                    <button type="button" className="btn btn-sm btn-outline-danger mx-2 align-self-end align-self-center" onClick={ this.doClearData }>{ t('contribute.steps.summary.alerts.clear.yes') }</button>
                    <button type="button" className="btn btn-sm btn-outline-secondary align-self-end align-self-center" onClick={ this.abortClearData }>{ t('contribute.steps.summary.alerts.clear.no') }</button>
                </div>
            , /* info: saved successfully */
            isSaveInProgress === true || isSavedSuccessfully === false ? null : 
                <div key="6" className="alert alert-success d-flex" role="alert">
                    <span className="flex-fill"><strong>{t('contribute.steps.summary.alerts.saved.title')} </strong>
                        { t('contribute.steps.summary.alerts.saved.message') + ' ' }
                        { isEditPending === true ? t('contribute.steps.summary.alerts.saved.continueEdit') : t('contribute.steps.summary.alerts.saved.continueContribute') }
                    </span>
                    { isEditRequest === true && isEditPending === false
                        // note: the only case we need a link is when we go from edit to contribute mode
                        ? <Link4Author role="button" className='btn btn-sm btn-outline-secondary ml-2 align-self-end align-self-center' 
                                to='/contribute' >{ t('contribute.steps.summary.alerts.saved.continue') }</Link4Author>
                        : <button type="button" className="btn btn-sm btn-outline-secondary ml-2 align-self-end align-self-center" 
                                onClick={ this.doClearData }>{ t('contribute.steps.summary.alerts.saved.continue') }</button> }
                </div>
            ]
        return messages
    }

    render() {
        const { t, navigation, contribution, authorIsActor, categorization, isSaveEnabled } = this.props
        const localStepNavigationButtons = [
            <button key="0" type="button" className="btn btn-secondary" onClick={ this.confirmClearData }>{t('contribute.navigation.action.clear')}</button>,
            <button key="1" type="submit" className="btn btn-secondary" disabled={!isSaveEnabled}>{t('contribute.navigation.action.save')}</button>
        ]
        // check helpers for view parts
        const isNotTranscribed = contribution.textStory.isTranscribed === true ? false : true
        const hasNoDeprecatedExpLanguages = Array.isArray( contribution.experience.languages ) && contribution.experience.languages.length > 0 ? false : true
        const hasNoCollection = !contribution.experience.collection || !contribution.experience.collection.id ? true : false
        const author = authorIsActor === true ? contribution.actors.find( it => it.isAuthor === true ) : contribution.author
        const isContributorAuthor = contribution.author.isContributor === true
        const messages = this.messages()
        // scroll to top if any message is shown
        if ( messages.some( it => it !== null ) ) navigation.scrollToTop()
        return (
            <div>
                { /* show all messages */ }
                { messages }
                { /* the summary page content */ }
                <div className="card">
                    <h6 className="card-header">{ contribution.experience.title }</h6>
                    <div className="card-body">
                        <p className="card-text">{ contribution.textStory.story }</p>
                    </div>
                    <ul className="list-group list-group-flush">
                        { /* experience: origin   text story: language, kind, is transcribed, perspective */ }
                        <li className="list-group-item">
                            <dl className="row mb-0">
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.textStoryLanguages') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.textStory.languages ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.perspective') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.textStory.perspective, categorization.narrativePerspective() ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.origin') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.experience.origin, categorization.dataOrigin() ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.kind') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.textStory.kind, categorization.dataKind() ) }</dd>
                                { isNotTranscribed ? null : <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.transcribed') }</span></dt> }
                                { isNotTranscribed ? null : <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ t('contribute.steps.summary.label.isTranscribed') }</dd> }
                            </dl>
                        </li>
                        { /* experience: deprecated languages, contact domains, hotspots, communication domains, interaction media, self attributions */ }
                        <li className="list-group-item">
                            <dl className="row mb-0">
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.contactdomains') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.experience.contactDomains ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.communicationdomains') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.experience.communicationDomains, categorization.communicationDomain() ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.media') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.experience.media, categorization.interactionMedium() ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.hotspots') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.experience.hotspots ) }</dd>
                                { /* deprecated spoken languages on experience instead of actors */ }
                                { hasNoDeprecatedExpLanguages ? null : <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.explanguages') }</span></dt> }
                                { hasNoDeprecatedExpLanguages ? null : <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.experience.languages ) }</dd> }
                            </dl>
                        </li>
                        { hasNoCollection ? null : /* collection: title, name of owner, affiliation? */
                        <li className="list-group-item">
                            <dl className="row mb-0">
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.collection') }</span></dt>
                                <dd className="col col-sm-3 text-left">TODO: Titel der Sammlung, von ...</dd>
                            </dl>
                        </li> }
                        { /* location: place, country, indications */ }
                        <li className="list-group-item">
                            { /* <h6 className="card-title">Ort des Geschehens</h6> */ }
                            <dl className="row mb-0">
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.place') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.location.place, categorization.particularPlace() ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.country') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.location.country, categorization.country() ) }</dd>
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.indications') }</span></dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.location.indications ) }</dd>
                            </dl>
                        </li>
                        { /* actors: type, languages, is author, min + max age, gender, cultural background, system specification, extend of group, is mixed gender, familiarity level */ }
                        <li className="list-group-item">
                            { contribution.actors.map( (it, i, array) => 
                                <dl className={ i === ( array.length -1 ) ? 'row mb-0' : 'row mb-3' } key={i}>
                                    <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.actor') }</span></dt>
                                    <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left"><span>{ buildActorSummary( it, categorization, t ) }</span></dd>
                                    { Array.isArray( it.nationalities ) && it.nationalities.length > 0 ? <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span></span></dt> : null }
                                    { Array.isArray( it.nationalities ) && it.nationalities.length > 0 ? <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( it.nationalities ) }</dd> : null }
                                    { Array.isArray( it.languages ) && it.languages.length > 0 ? <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.languages') }</span></dt> : null }
                                    { Array.isArray( it.languages ) && it.languages.length > 0 ? <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( it.languages ) }</dd> : null }
                                </dl>
                            ) }
                        </li>
                        { /* author: min + max age, gender, cultural background if not one of the actors, reflection */ }
                        <li className="list-group-item">
                            <dl className="row mb-0">
                                <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right">
                                    <span>{ isContributorAuthor ? t('contribute.steps.summary.label.authorIsContributor') : t('contribute.steps.summary.label.author') }</span>
                                </dt>
                                <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">
                                    <span>{ buildAuthorSummary( author, authorIsActor, categorization, t ) }</span>
                                </dd>
                                { authorIsActor === true ? null : 
                                    <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span></span></dt> }
                                { authorIsActor === true ? null : 
                                    <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( author.nationalities ) }</dd> }
                                { isContributorAuthor === false && authService.isAdmin() === false ? null :
                                    <dt className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.reflection') }</span></dt> }
                                { isContributorAuthor === false && authService.isAdmin() === false ? null :
                                    <dd className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">{ buildLabelString( contribution.reflection.keywords ) }</dd> }
                            </dl>
                            { isContributorAuthor === false && authService.isAdmin() === false ? null :
                                <p>{ contribution.reflection.reflection }</p> }
                        </li>
                        { /* experience: rights of use grants, just the 3 static currently supported grants */ }
                        <li className="list-group-item">
                            <PrivacySummary t={t} contribution={contribution} categorization={categorization} scope={RightOfUseScope.CONTRIBUTOR} />
                        </li>
                        { /* administrators: rights of use grants only assignable from admins (publicity) */ }
                        <li className="list-group-item">
                            <PrivacySummary t={t} contribution={contribution} categorization={categorization} scope={RightOfUseScope.ADMIN} />
                        </li>
                    </ul>
                </div>
                <BackNextNavigation t={t} navigation={navigation} localStepNavigationButtons={localStepNavigationButtons} />
            </div>
        )
    }
}

class PrivacySummary extends React.Component {
    render() {
        const { contribution, categorization, scope, t } = this.props
        const items = []
        // note: need for loop here as we are retuning more than one element! (one dt and one dd and we don't want to wrap them in a div or something!)
        categorization.rightOfUse().forEach( (it, i) => {
            // only process the rights of the given scope
            if ( it.scope !== scope ) return
            // the term as the label of the rights
            if ( categorization.isRightOfUse( it, RightOfUse.PRIVACY_POLICY ) ) {
                items.push( <dt key="0" className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.privacy') }</span></dt> )
            } else if ( categorization.isRightOfUse( it, RightOfUse.PUBLICITY ) ) {
                // the label for admin rights
                items.push( <dt key="0" className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"><span>{ t('contribute.steps.summary.label.publicity') }</span></dt> )
            } else {
                // just an empty definition term for correct rendering
                items.push( <dt key={ items.length } className="col col-6 col-md-5 col-lg-4 col-xl-3 p-0 text-right"></dt> )
            }
            // the definition definition
            const isActive = contribution.rightsOfUse.grants.some( (grant) => grant.id === it.id )
            const label = ( isActive ? '' : t('contribute.steps.summary.privacy.not') + ' ' ) + t('contribute.steps.summary.privacy.' + i)
            items.push(
                <dd key={ items.length } className="col col-6 col-md-7 col-lg-8 col-xl-9 pr-0 text-left">
                    <FontAwesomeIcon icon={ [ 'far', isActive ? 'check-circle' : 'times-circle' ] } />
                    <span className="pl-1">{ label }</span>
                </dd>
            )
        } )
        return <dl className="row mb-0">{ items }</dl>
    }
}

export default AuthHoc( LocalizableHoc()(StepSummary), { require: UserRole.AUTHOR } )
