import PropTypes from 'prop-types';

const UserManagementTypes = {
    t:PropTypes.func.isRequired
};
const TableTypes = {
    t:PropTypes.func.isRequired,
    name:PropTypes.string.isRequired,
    data:PropTypes.array
};

const AddUserTypes = {
    t:PropTypes.func.isRequired,
    connector:PropTypes.object.isRequired
};


const PermissionBodyTypes = {
    t:PropTypes.func.isRequired,
    permissions:PropTypes.array.isRequired
};
const TempPermissionRowTypes ={
    value:PropTypes.object.isRequired 
};
const PermissionRowTypes = {
    // key:PropTypes.element.isRequired,
     value:PropTypes.object.isRequired
 };
 const HeaderRowTypes = {
    row:PropTypes.array,
    addRow:PropTypes.func
};
const PermissionListTypes = {
    t:PropTypes.func.isRequired,
    connector:PropTypes.object.isRequired,
};
const PermissionMappingTableTypes = {
    t:PropTypes.func.isRequired,
    name:PropTypes.string.isRequired,
    data:PropTypes.array
};
const IdmUserListTypes = {
    t:PropTypes.func.isRequired,
    connector:PropTypes.object.isRequired
};
const IdmQueryTypes = {
    t:PropTypes.func.isRequired,
    connector:PropTypes.object.isRequired
};
const LocalUserListTypes ={
    t:PropTypes.func.isRequired,
    connector:PropTypes.object.isRequired
};
const LocalUserTableTypes = {
    t:PropTypes.func.isRequired,
    name:PropTypes.string.isRequired,
    data:PropTypes.array
};
const LocalUserBodyTypes = {
    t:PropTypes.func.isRequired,
    localUsers:PropTypes.array.isRequired
};
const LocalUserRowTypes = {
    value:PropTypes.object.isRequired
};
const UserRowTypes = {
    value:PropTypes.object.isRequired,
    //key:PropTypes.element.isRequired,
    update:PropTypes.func.isRequired
};
const UserBodyTypes = {
    t:PropTypes.func.isRequired,
    users:PropTypes.array.isRequired,
    onUserChanged:PropTypes.func.isRequired
};
const UserTableTypes = {
    t:PropTypes.func.isRequired,
    data:PropTypes.array,
    name:PropTypes.string.isRequired,
    onDataTableChanged:PropTypes.func.isRequired
};


const RoleArray = ['GUEST', 'AUTHOR', 'RESEARCHER', 'ADMIN'];

export{
    UserManagementTypes,
    TableTypes,
    AddUserTypes,
    PermissionBodyTypes,
    PermissionRowTypes,
    TempPermissionRowTypes,
    PermissionListTypes,
    HeaderRowTypes,
    PermissionMappingTableTypes,
    IdmUserListTypes,
    IdmQueryTypes,
    LocalUserListTypes,
    LocalUserTableTypes,
    LocalUserBodyTypes,
    LocalUserRowTypes,
    UserTableTypes,
    UserBodyTypes,
    UserRowTypes,
    RoleArray
};
