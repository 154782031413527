/**
 * Fetcher.js
 * @description Provides several rest request function to communicate (data fetching) with the backend system.
 * @since 1.3.0
 */

// language + translation service
import { languageService } from './LanguageService'

/**
 * Default header configuration for cis-backend related rest requests.
 * note: the 'Accept-Language' header is added in exported function buildRequest()
 */
var defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};


/** The possible stats of the loader. */
export const ConnectorState = {
    initialized: 0,
    pending: 1,
    cancelled: 2,
    response: 3,
    error: 4,
    json: 5
};

/**
 * A wrapper to fetch data from the backend rest api. It deals with the async fetch promises and allows to cancel fetches, 
 * to check if fetch is done or still pending and provides the data fetched or the error that occured.
 * @see https://stackoverflow.com/questions/49906437/how-to-cancel-a-fetch-on-componentwillunmount
 * @see https://stackoverflow.com/questions/36294109/how-to-check-if-a-promise-is-pending
 * @see https://www.robinwieruch.de/react-fetching-data/
 */
export class Connector {
    constructor() {
        this.state = {
            value: ConnectorState.initialized
        };
    }

    /** 
     * Performs the given request and calls the given callback on connector state changes.
     * @param {Function} request a function which returns a fetch promise when called
     * @param {Function} callback a function which is called on state changes
     */
    send( request, callback ) {
        // defined here to have a 'private' function to avoid redundant code
        const doStateChange = (state, json) => {
            if ( this.state.value === ConnectorState.cancelled ) return
            this.state = state
            callback( json )
        }
        this.state = { value: ConnectorState.pending }
        // TODO: do we need a callback for state switching from initialized to loading?
        request().then(
            response => {
                doStateChange( { value: ConnectorState.response, response: response }, undefined )
                // there is no api to check whether there is a response body available or not, thus we have to rely on appropriately set headers
                // usually content type is appropriately set, in case content length is set to 0 don't parse the body
                const contentType = response.headers.get('Content-Type')
                const contentLength = response.headers.get('Content-Length')
                const hasBody = ( contentType !== null && contentType.includes('json') )
                    || !( (contentType === null && contentLength === null ) || ( contentLength !== null && Number.parseInt(contentLength) === 0 ) )
                if ( this.state.value !== ConnectorState.cancelled && hasBody ) {
                    doStateChange( { value: ConnectorState.pending, response: response }, undefined )
                    response.json().then(
                        json => doStateChange( { value: ConnectorState.json, response: response }, json ),
                        error => doStateChange( { value: ConnectorState.error, response: response, error: error }, undefined )
                    )
                }
            },
            error => doStateChange( { value: ConnectorState.error, error: error }, undefined )
        )
    }

    /** Aborts the loading process and ensures that the callback no longer gets called. */
    cancel = () => this.state.value = ConnectorState.cancelled

    /** Returns true if the loader is in loading state, otherwise false. */
    isPending = () => this.state.value === ConnectorState.pending

    /** Returns true if an error occured during data loading, otherwise false. Note that error might occur during json parsing thus check if a response is present too! */
    hasError = () => this.state.value === ConnectorState.error

    /** Returns true if the loader received a response to its request, otherwise false. */
    hasResponse = () => this.state.response !== undefined && this.state.response !== null

    /** Returns true if the loader sucessfully parsed json data from the response body and invoked the callback function with the json data as argument, otherwise false. */
    hasJson = () => this.state.value === ConnectorState.json

    /** Returns true if the response status was 200. */
    is200 = () => this.hasResponse() && this.state.response.status === 200

    /** Returns true if the response status is in the 2xx range. */
    isOk = () => this.hasResponse() && this.state.response.ok

    /** Returns the response status code or 0 if no response was received. */
    code = () => this.hasResponse() ? this.state.response.status : 0

    /** Returns the received response. */
    response = () => this.state.response

    /** Returns the occured error. */
    error = () => this.state.error
}

/**
 * Returns a request as function object to be used for the connector.
 * @param {String} url the api endpoint to request
 * @param {String} method the http request method
 * @param {Boolean} includeCredentials true if credentials (cookies) are send to the server
 * @param {Object} body to be send as request body encoded as json string
 * @returns {Function} to be used as parameter of the connector send method
 */
export function buildRequest( url, method, includeCredentials, body ) {
    let language = languageService.language()
    if ( language !== undefined && defaultHeaders['Accept-Language'] !== language ) {
        defaultHeaders['Accept-Language'] = language
    }
    const init = { method: method, headers: defaultHeaders }
    if ( includeCredentials === true ) Object.assign( init, { credentials: 'include' } )
    if ( body !== undefined ) Object.assign( init, { body: JSON.stringify( body ) } )
    return () => {
      return fetch( url, init )
    }
}


/**
 * request a rest data (json) object by sending a post rest request
 * @deprecated use Connector instead!
 * @param {*} url  the backend endpoint url
 * @param {*} payload the payload object for the rest request
 * @returns {Promise} the promise object containing the json data
 */
export function fetchDataByPost(url, payload) {
  return fetch(url, {
    method: 'POST',
    headers: defaultHeaders,
    credentials: 'include',
    body: JSON.stringify(payload)
  }).then(response => response.json()).then(data => { return data })
    .catch(error => {
      return Promise.reject(Error(error.message))
    })
}

/**
 * request a rest response object by sending a post rest request
 * @param {*} url the backend endpoint url
 * @param {*} payload the payload object for the rest request
 * @returns {Promise} the promise object containing the rest response
 */
export function fetchResponseByPost(url, payload) {
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: defaultHeaders,
    body: JSON.stringify(payload)
  }).then(response => { return response })
    .catch(error => {
      return Promise.reject(Error(error.message))
    })
}

export function fetchDataByGet(url)
{
  return fetch(url,{
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  })
  .then(status)
  .then(json)
  .catch(error =>{console.info('Request failed ', error);return error;});
}

function status(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(new Error(response.statusText))
  }
}
function json(response) {
  return response.json()
}


// async function fetchOHLC(yUrl){
//   try{
//     var r=JSON.parse(await fetch(yUrl))
//     alert(JSON.stringify(r.query));
//     return {t:r.created,o:r.open,h:r.high,l:r.low,c:r.close};
//   }catch(e){console.log(e);}
// }
