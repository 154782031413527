/**
 * Aggregator.js
 * @description provides several function to extract and aggregate detail information of intercultural experience
 * @version 1.5.0
 * @since 1.3.0
 */

import { ActorTypes, FamiliarityLevel } from "../domain/CategorizationData";


/**
 * Builds a string representation for the given data used as label in the gui. In case of an array all elements are concatted by comma blank.
 * @param {*} data a reference item dto or an array of reference item dto's
 * @param {Object[]} lookup an array with objects having an id and a label property used to convert the reference item into a textual representation
 * @returns {string} a single line string representing the given data or an empty string
 */
export function buildLabelString( data, lookup ) {
    var result = ''
    var separator = ', '
    if ( data === undefined || data === null ) {
      return result
    }
    // check for array
    if ( Array.isArray( data ) ) {
      data.forEach( function( it, i ) {
        if ( i > 0 ) result = result.concat( separator )
        // recursive call to reuse undefined and null checks
        result = result.concat( buildLabelString( it, lookup ) )
      } )
    // check for reference (localized) item dto
    } else if ( data.id !== undefined || data.label !== undefined ) {
      // the label property of the reference localized item takes precedence
      if ( data.label !== undefined ) {
        result = buildLabelString( data.label )
      // lookup the label
      } else if ( Array.isArray( lookup ) ) {
        result = buildLabelString( lookup.find( (it) => it.id === data.id ) )
      // fallback to default string conversion of the id
      } else {
        result = buildLabelString( data.id )
      }
    } else {
      result = String( data )
    }
    return result
}

/**
 * Builds a string representation for the author of the intercultural experience with its age, gender and nationalities.
 * TODO: in case it's possible align with buildAuthorSummary
 * @param {*} data the author object
 * @returns {string} the author string
 */
export function buildAuthorString( data, t ) {
  var result = ''
  if ( data === undefined || data === null ) {
    return result
  }
  result = buildAgeString( data.minAge, data.maxAge, t )
  if ( data.gender !== null ) result = result.concat( ', ', data.gender.label )
  var nationalities = buildLabelString( data.nationalities )
  if ( nationalities.trim().length > 0 ) result = result.concat(' (', nationalities, ')' )

  return result
}

/**
 * Builds a string representation for the author of the intercultural experience without its nationalities.
 * @returns {string} a one line string representation the author
 */
export function buildAuthorSummary( author, authorIsActor, categorization, t ) {
    if ( author === undefined || author === null ) return ''
    const result = concat(
      ', ',
      buildGenderString( author.gender, false, categorization, t ),
      buildAgeString( author.minAge, author.maxAge, t ),
      authorIsActor === true ? t('aggregator.author.isActor') : ''
    )
    return result
}

/**
 * Builds a string representation for the given actor without its nationalities and languages.
 * @returns {string} a one line string representation the actor
 */
export function buildActorSummary( actor, categorization, t ) {
    if ( actor === undefined || actor.type === undefined || actor.type.id === undefined ) return ''
    if ( categorization.isActorType( actor.type, ActorTypes.System ) ) return buildLabelString( actor.systemSpecification )
    if ( categorization.isActorType( actor.type, ActorTypes.Human ) ) {
      const result = concat(
        ', ',
        buildGenderString( actor.gender, actor.isMixedGender, categorization, t ),
        buildAgeString( actor.minAge, actor.maxAge, t ),
        actor.isAuthor === true ? t('aggregator.actor.isAuthor') : ''
      )
      return result
    }
    if ( categorization.isActorType( actor.type, ActorTypes.Group ) ) {
      const result = concat(
        ', ', 
        buildActorTypeString( actor, categorization, t ),
        buildGenderString( actor.gender, actor.isMixedGender, categorization, t ),
        buildAgeString( actor.minAge, actor.maxAge, t, true )
      )
      return result
    }
    // in case actor type is unknown
    return ''
}

/**
 * Builds a string representation of the type of the given actor. For group actors familiarity and extend of group is included.
 */
function buildActorTypeString( actor, categorization, t ) {
  if ( actor === undefined || actor.type === undefined || actor.type.id === undefined ) return undefined
  const actorType = categorization.actorType().find( (it) => it.id === actor.type.id )
  if ( categorization.isActorType( actor.type, ActorTypes.System ) ) return t('aggregator.actor.system')
  if ( categorization.isActorType( actor.type, ActorTypes.Group ) ) {
    // map the 2 familiarity values of the categorization to the needed readable text part
    const familiarityValues = categorization.familiarityLevel().map( (it) => {
        return { id: it.id, label: it.specification === FamiliarityLevel.Familiar ? t('aggregator.actor.familiar') : t('aggregator.actor.unfamiliar') }
    } )
    return concat(
      ' ',
      buildLabelString( actor.familiarityLevel, familiarityValues ),
      t('aggregator.actor.group'),
      !actor.extendOfGroup ? '' : ' ' + t('aggregator.actor.groupOf', { count: actor.extendOfGroup } )
    )
  }
  // for humans or unknown type just return the label provided by the categorization data
  return actorType.label
}

/** Builds a one line string representatino of the given human actor including its the first most distinct nationality. */
export function buildActorHumanDistinctableString( actor, actors, categorization, t ) {
  if ( actor === undefined || actor.type === undefined || actor.type.id === undefined ) return ''
  if ( false === categorization.isActorType( actor.type, ActorTypes.Human ) ) return ''
  const result = concat(
    ', ',
    buildGenderString( actor.gender, actor.isMixedGender, categorization, t ),
    buildAgeString( actor.minAge, actor.maxAge, t ),
    buildArrayString(
      buildDistinctableReferenceItems( actor.nationalities, actors.map( it => it.nationalities ) )
    )
  )
  return result
}

/** Returns a one line string representing the given array with the given number of items.  */
function buildArrayString( array, numberOfItems ) {
  if ( !array || Array.isArray( array ) === false || array.length === 0 ) return ''
  if ( numberOfItems === undefined || numberOfItems === null || numberOfItems < 0 ) numberOfItems = array.length
  const result = array.reduce( (acc, it, i) => {
    if ( i < numberOfItems ) {
      return concat( ', ', acc, it.label )
    } else if ( i === numberOfItems ) {
      return acc + '...'
    } else {
      return acc
    }
  }, '' )
  return result
}

/** Returns a copy of the given items array with its items sorted by the count of occurrences in the array of arrays. */
function buildDistinctableReferenceItems( items, arrayOfArrays ) {
  if ( Array.isArray( items ) === false ) return []
  // items should be sorted in order: first item which doesn't occur in haystack arrays, last items which occur in almost all arrays
  const result = Array.from( items )
  if ( Array.isArray( arrayOfArrays ) === false || Array.isArray( arrayOfArrays[0] ) === false ) return result
  result.sort( (a, b) => countOccurrences(a, arrayOfArrays) - countOccurrences(b, arrayOfArrays) )
  return result
}

/** Returns the count of occurrences of item in the array of arrays. Note: item as well as the items in the array of arrays are reference items and must have a label property! */
function countOccurrences( item, arrayOfArrays ) {
  // map each array to an int value representing the count of occurrences then sum up all count of occurrences
  const result = arrayOfArrays.map(
          // note: array is sometimes undefined, see #134
          (array) => Array.isArray(array) === false ? 0 : array.reduce( (acc, it) => 
            item.label.localeCompare( it.label, undefined, { localeMatcher: 'best fit', usage: 'search', sensitivity: 'base' } ) === 0
                ? acc +1
                : acc
          , 0 )
      // reduce the array by simply sum up all count of occurrences
      ).reduce( (acc, it) => acc + it , 0 )
  return result
}


/**
 * Builds a one line string representation for the given actor.
 * @param {} actor            the actor to represent
 * @param {*} categorization  the categorization object
 * @param {*} t               the function to localize content
 */
export function buildActorString( actor, categorization, t, actors ) {
  if ( actor === undefined || actor.type === undefined || actor.type.id === undefined ) return undefined
  if ( categorization.isActorType( actor.type, ActorTypes.System ) ) {
    const result = concat(
      ' | ',
      t('aggregator.actor.system'),
      buildLabelString( actor.systemSpecification )
    )
    return result
  }
  if ( categorization.isActorType( actor.type, ActorTypes.Human ) ) {
    // ensure that actors is an array
    if ( Array.isArray( actors ) === false ) actors = []
    const result = concat(
      ' | ',
      actor.isAuthor === true ? t('aggregator.actor.author') : '',
      concat(
        ', ',
        buildGenderString( actor.gender, actor.isMixedGender, categorization, t ),
        buildAgeString( actor.minAge, actor.maxAge, t ),
        buildArrayString(
          buildDistinctableReferenceItems( actor.nationalities, actors.filter( it => categorization.isActorType( it.type, ActorTypes.Human ) ).map( it => it.nationalities ) )
        )
      )
    )
    return result
  }
  if ( categorization.isActorType( actor.type, ActorTypes.Group ) ) {
    const result = concat(
      ', ', 
      buildActorTypeString( actor, categorization, t ),
      buildGenderString( actor.gender, actor.isMixedGender, categorization, t ),
      buildAgeString( actor.minAge, actor.maxAge, t, true )
    )
    return result
  }
  // in case actor type is unknown
  return undefined
}


/**
 * Builds a string representation for the actors of the intercultural experience with their language and
 * age, gender, nationalities or
 * system specification or
 * familiarity level, extend of group, is mixed gender.
 * TODO: display each actor in one row/line, not all in one line! align with buildActorSummary if possible and remove this function
 * @deprecated try to use buildActorSummary in combination with buildActorTypeString or any buildActor function above
 * @param {*} data the actors array
 * @returns {string} the actors string
 */
export function buildActorsString( data, t ) {
  var result = ''
  var separator = '; '
  if ( data === undefined || data === null ) {
    return result
  }
  for ( var i = 0; i < data.length; i++ ) {
    if ( i > 0 ) result = result.concat( separator )
    var actor = data[i];
    var type = actor.type.specification;
    if ( type === ActorTypes.Human ) {
      result = result.concat( buildAuthorString( actor, t ) )
    } else if ( type === ActorTypes.System ) {
      result = result.concat( actor.systemSpecification.label )
    } else if ( type === ActorTypes.Group ) {
      // TODO: is mixed gender, extend of group
      result = result.concat( buildAuthorString( actor, t ), ', ', buildLabelString( actor.familiarityLevel ) )
    }
    result = result.concat( ', (', buildLabelString( actor.languages ), ')' )
  }
  return result
}

/**
 * Returns a string representation for the given location information (particular place, country, location indications).
 * @param {*} data location information, see location dto of backend
 * @returns {string} single line string representation of the given location information
 */
export function buildLocationString( data ) {
  var result = ''
  var separator = ', '
  if ( data === undefined || data === null ) {
    return result
  }
  result = result.concat( buildLabelString( data.country ) )
  if ( result.length > 0 ) result = result.concat( separator )
  result = result.concat( buildLabelString( data.place ) )
  if ( result.length > 0 ) result = result.concat( separator )
  result = result.concat( buildLabelString( data.indications ) )
  return result
}

/**
 * Returns a string representation for the given collection.
 * @param {*} data collection the experience belongs to
 * @returns {string} single line string representation of the given collection information
 */
export function buildCollectionString( data ) {
  var result = ''
  if ( data === undefined || data === null ) {
    return result
  }
  result = data.title
  return result
}

/** Returns a string representation of the given date primitive value (getTime()). */
export function buildDateString( date ) {
  if ( !date || !Number.isInteger( date ) ) return ''
  const obj = new Date( date )
  if ( Number.isNaN( obj ) ) return ''
  return `${obj.getFullYear()}-${String(obj.getMonth() +1).padStart(2, 0)}-${String(obj.getDate()).padStart(2, 0)} ${String(obj.getHours()).padStart(2, 0)}:${String(obj.getMinutes()).padStart(2, 0)}`
}

/** Returns a string with all given and not empty parts concatenated by the given separator. */
function concat( separator, ...parts ) {
  const result = parts.reduce( (acc, it) => {
    const part = it.trim()
    return part.length === 0 ? acc : acc.length === 0 ? part : acc.concat( separator, part )
  }, '' )
  return result
}

export function buildTimeIntervallString(after, before, t)
{
  let result="";
  switch(true)
  {
    case (after==="" && before===""):
      break;
    case (after==="" && before!==""):
        result = t('overview.filter.date.before') + " " + before
      break;
    case (after!=="" && before===""):
        result = t('overview.filter.date.since') + " " + after
      break;
    case (after===before):
        result = t('overview.filter.date.exact') + " " + after
      break;
    default:
      result = t('overview.filter.date.between') + " " + after + " " + before
  }
  return result;
}
/**
 * Returns a string representation of the given age range.
 * @param {*} minAge the lower bound of the age or null
 * @param {*} maxAge the upper bound of the age or null
 */
export function buildAgeString( minAge, maxAge, t, isGroup = false ) {
  if ( minAge !== undefined && minAge !== null ) {
    if ( maxAge !== undefined && maxAge !== null ) {
      if ( minAge === maxAge ) {
        return t('aggregator.age.exact', { age: minAge } )
      } else {
        if ( isGroup === true ) {
          return t('aggregator.age.minMaxAgeGroup', { minAge: minAge, maxAge: maxAge } )
        } else {
          return t('aggregator.age.minMaxAge', { minAge: minAge, maxAge: maxAge } )
        }
      }
    } else {
      return t('aggregator.age.minAge', { minAge: minAge } )
    }
  } else {
    if ( maxAge !== undefined && maxAge !== null ) {
      return t('aggregator.age.maxAge', { maxAge: maxAge } )
    } else {
      return t('aggregator.age.unknown')
    }
  }
}

/**
 * Returns a string representation of the given gender.
 */
function buildGenderString( gender, isMixedGender, categorization, t ) {
  const label = buildLabelString( gender, categorization.gender() )
  const result = isMixedGender === true ? t('aggregator.gender.mixed') : label.trim() === '' ? t('aggregator.gender.unknown') : simplifyGender( label, t )
  return result
}

/** Simplifies the gender binary / genderqueer. */
function simplifyGender( gender, t ) {
  if ( String(gender).includes('genderqueer') ) return t('aggregator.gender.genderqueer')
  return gender
}
