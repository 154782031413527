/**
 * basic react module dependencies
 */
import React from 'react'
import { Container, Col, Row } from 'reactstrap'

// language + translation service
import { LocalizableHoc } from '../services/LanguageService'

/**
 * the component provides the functionality to show general impress related information.
 * 
 * @version 0.1.0
 * @since 1.2.0
 */
export class Impress extends React.Component {

  /**
   * Main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t } = this.props

    return (
      <div className='cisComponent'>
        <div className="container-fluid">
          <div className="cisComponentHeader">
            <div className="row">
              <div className="col">
                <h1>{t('impress.header')}</h1>
              </div>
            </div>
          </div>
          <div className="cisComponentBody">
            <div className="row">
              <div className="col">
                <div className='component-main'>
                  <div className="row">
                    <div className="col">
                      <p>{t('impress.description')}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col-12 col-sm-auto col-md-6 col-lg-6 col-xl-4">
                          <h3 className="cis-header">{t('impress.adress')}</h3>
                          <p>
                            {t('impress.adressWHZ')}<br />
                            Dr.-Friedrichs-Ring 2A<br />
                            08056 Zwickau
                        </p>
                        </div>
                        <div className="col-12 col-sm-auto col-md-6 col-lg-6 col-xl-8">
                          <h3 className="cis-header">{t('impress.representAuthorization')}</h3>
                          <p>
                            <a href="https://www.fh-zwickau.de/hochschule/rektorat/" target="_blank" rel="noopener noreferrer">{t('impress.actingPresident')}</a><br />
                            {t('impress.phone')} +49 375 536-1001<br />
                            {t('impress.email')} <a href="mailto:rektor@fh-zwickau.de">rektor(at)fh-zwickau.de</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>{t('impress.publicCorporation')}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="cisParagraphHeader">
                        <h3 className="cis-header">{t('impress.contentResponsibility')}</h3>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-auto col-md-6 col-lg-6 col-xl-4">
                          <div className="cisParagraphBody">
                            <p>Prof. Dr. phil. Doris Fetscher<br />
                              {t('impress.phone')} +49 375 536-3501<br />
                              {t('impress.email')} <a href="mailto:doris.fetscher@fh-zwickau.de">Doris.Fetscher@fh-zwickau.de</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="row">
                        <div className="col-12 col-sm-auto col-md-6 col-lg-6 col-xl-4">
                          <h5>{t('impress.visitingAdress.header')}</h5>
                          <p>
                            {t('impress.visitingAdress.whz')}<br />
                            {t('impress.visitingAdress.adress')}<br />
                            08066 Zwickau
                        </p>
                        </div>
                        <div className="col-12 col-sm-auto col-md-6 col-lg-6 col-xl-8">
                          <h5>{t('impress.postalAdress.header')}</h5>
                          <p>
                            {t('impress.postalAdress.whz')}<br />
                            {t('impress.postalAdress.adress')}<br />
                            08012 Zwickau
                        </p>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="cisParagraphHeader">
                    <h3 className="cis-header">{t('impress.liability.header')}</h3>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="cisParagraphBody">
                        <p>{t('impress.liability.description')}</p>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-auto col-md-6 col-lg-6 col-xl-4">
                          <ul>
                            <li>{t('impress.liability.listEntry1')}</li>
                            <li>{t('impress.liability.listEntry2')}</li>
                            <li>{t('impress.liability.listEntry3')}</li>
                            <li>{t('impress.liability.listEntry4')}</li>
                          </ul>
                        </div>
                        <div className="col-12 col-sm-auto col-md-6 col-lg-6 col-xl-8">
                          {t('impress.contact')}<br />
                          {t('impress.email')} <a href="mailto:nils@fh-zwickau.de">nils@fh-zwickau.de</a>
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="cisParagraphHeader">
                        <h3>{t('impress.copyright.header')}</h3>
                      </div>
                      <div className="cisParagraphBody">
                        <p>{t('impress.copyright.text')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LocalizableHoc()(Impress)