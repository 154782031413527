import React from 'react'

import { Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'
import { UserRole } from '../../domain/UserRole'
import { RightOfUseScope, RightOfUse } from '../../domain/CategorizationData'
import { Link4Guest } from '../Link'

import BackNextNavigation from './NavigationBackNext'

// helper functions
import { buildReferenceItemDto, buildNumberValue } from '../../domain/DataTransferObjects'


class StepPrivacyPolicy extends React.Component {
    constructor( props ) {
        super( props )
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange( event ) {
        const target = event.target
        // note: the unchecked state (i. e. the square font awesome icon) is rendered using a path element inside an svg element,
        // thus on click on the path element we have no dataset id and must use the dataset id from the parent (the svg element)
        const id = target.dataset.id !== undefined ? target.dataset.id : target.parentElement.dataset.id
        const value = buildNumberValue( id )
        let isFound = false
        const { rightsOfUse } = this.props.contribution
        const grants = rightsOfUse.grants.filter( (it) => {
            if ( it.id === value ) {
                isFound = true
                return false
            }
            return true
        } )
        if ( isFound === false ) grants.push( buildReferenceItemDto( value ) )
        const result = Object.assign( {}, rightsOfUse, { grants: grants } )
        this.props.callbacks.onRightsOfUseChange( result )
    }

    render() {
        const { t, navigation, categorization, wasValidated, authService } = this.props
        const { rightsOfUse } = this.props.contribution
        const assignable = categorization.rightOfUse()
        // note: alter navigation props to set hasNextStep to false in case the user does not accept privacy policy
        // note: disabled because: we store all input in local storage, thus there is no real need to send unfinished data to the backend
        //       thus we move the save button to the end and the user is able to navigate freely within the form including privacy policy
        // const isPrivacyPolicyAccepted = rightsOfUse.grants.some( (grant) => grant.id === categorization.rightOfUse()[0].id )
        // Object.assign( navigation, { hasNextStep: isPrivacyPolicyAccepted === true ? navigation.hasNextStep : () => false } )
        return (
            <div>
                <div className="card">
                    <h6 className="card-header">{ t('contribute.steps.privacy.legal.header') }</h6>
                    <div className="card-body">
                    <p>
                        <Trans i18nKey="contribute.steps.privacy.legal.hint">
                            <Link4Guest to='/privacy' />
                        </Trans>
                    </p>
                    { assignable.map( (it, i) => it.scope !== RightOfUseScope.CONTRIBUTOR ? null : 
                            <RightOfUseComponent key={it.id} t={t} grants={rightsOfUse.grants} rightOfUse={it} index={i} 
                                    categorization={categorization} wasValidated={wasValidated} handleInputChange={this.handleInputChange} />
                    ) }
                    </div>
                </div>
                { authService.isAdmin() === false ? null : 
                    <div className="card mt-3">
                        <h6 className="card-header">{ t('contribute.steps.privacy.publicity.header') }</h6>
                        <div className="card-body">
                        { assignable.map( (it, i) => it.scope !== RightOfUseScope.ADMIN ? null : 
                                <RightOfUseComponent key={it.id} t={t} grants={rightsOfUse.grants} rightOfUse={it} index={i} 
                                        categorization={categorization} wasValidated={wasValidated} handleInputChange={this.handleInputChange} />
                        ) }
                        </div>
                    </div> }
                <BackNextNavigation t={t} navigation={navigation} />
            </div>
        )
    }
}

class RightOfUseComponent extends React.Component {
    render() {
        const { t, grants, rightOfUse, index, wasValidated, categorization, handleInputChange } = this.props
        const isActive = grants.some( (grant) => grant.id === rightOfUse.id )
        const isPrivacyPolicy = categorization.isRightOfUse( rightOfUse, RightOfUse.PRIVACY_POLICY )
        const showInvalidFeedbackPrivacyPolicy = wasValidated === true && isPrivacyPolicy === true && isActive === false ? true : false
        const buttonCss = isPrivacyPolicy && showInvalidFeedbackPrivacyPolicy && isActive === false
                ? 'align-self-start btn btn-outline-danger'
                : 'align-self-start btn ' + (isActive ? 'btn-outline-success' : 'btn-outline-secondary')
        const labelCss = isPrivacyPolicy && showInvalidFeedbackPrivacyPolicy ? ( isActive === false ? 'text-danger' : 'text-success' ) : ''
        return (
            <div className="form-group d-flex">
                <button type="button" className={ buttonCss } id={ 'rightOfUse' + rightOfUse.id } data-id={rightOfUse.id} onClick={handleInputChange} aria-pressed={isActive}>
                    <FontAwesomeIcon data-id={rightOfUse.id} icon={ isActive ? "check" : "square" } style={ isActive ? {} : {color: 'transparent'} } />
                </button>
                <div className="ml-2">
                    <label className={ 'my-1 ' + labelCss } style={{fontSize: '1.25rem'}} htmlFor={'rightOfUse' + rightOfUse.id} data-id={rightOfUse.id}>{ rightOfUse.label }</label>
                    { isPrivacyPolicy ? <PrivacyPolicyAcceptance t={t} /> : null }
                    { index === 1 ? <p className="mb-0">{ t('contribute.steps.privacy.legal.items.1') }</p> : null }
                    { index === 3 ? <p className="mb-0">{ t('contribute.steps.privacy.legal.items.3') }</p> : null }
                </div>
            </div>
        )
    }
}

class PrivacyPolicyAcceptance extends React.Component {
    render() {
        const { t } = this.props
        return <ul className="mb-0" style={{paddingInlineStart: '2rem'}}>
                <li>{ t('contribute.steps.privacy.legal.items.0.purpose') }</li>
                <li>{ t('contribute.steps.privacy.legal.items.0.rights') }</li>
            </ul>
    }
}

export default AuthHoc( LocalizableHoc()(StepPrivacyPolicy), { require: UserRole.AUTHOR } )
