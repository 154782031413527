/**
 * basic react module dependencies
 */
import React from 'react'

/**
 * The component provides the functionality to render a detail table row, if content is available
 * 
 * @version 0.1.0
 * @since 1.2.3
 */
export default class InterculturalExperienceDetailRow extends React.Component {

  /**
   * default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props)
    this.state = {
      name: props.name === null ? '' : props.name,
      value: props.value === null ? '' : props.value,
    }
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const jsx = (
      <tr>
        <td>{this.state.name}</td>
        <td>{this.state.value}</td>
      </tr>
    );

    return this.state.value !== '' ? jsx : null
  }
}