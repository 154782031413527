import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'

import { UserRole } from '../../domain/UserRole'
import { buildReferenceItemDto } from '../../domain/DataTransferObjects'

// navigation + steps components
import BackNextNavigation from './NavigationBackNext'
import MultiValueInput from './MultiValueInput'


class StepInteraction extends React.Component {
    constructor( props ) {
        super( props )
        this.handleInputChange = this.handleInputChange.bind( this )
    }

    handleInputChange( event ) {
        const target = event.target
        const experience = {}
        // note: handle the callback of the multi value input for hotspots
        if ( Array.isArray( event ) === true ) {
            Object.assign( experience, this.props.contribution.experience, { 'hotspots': event } )
            this.props.callbacks.onExperienceChange( experience )
            return
        }

        // handle communication domains + interaction media uniformly
        if ( target === undefined ) return
        const propname = target.name
        if ( event.type === 'change' ) {
            const value = buildReferenceItemDto( target.value )
            let values
            if ( target.checked === true ) {
                values = this.props.contribution.experience[propname].concat( value )
            } else {
                values = this.props.contribution.experience[propname].filter( (it, i) => it.id !== value.id )
            }
            Object.assign( experience, this.props.contribution.experience, { [propname]: values } )
        }
        this.props.callbacks.onExperienceChange( experience )
    }

    render() {
        const { t, navigation, categorization } = this.props
        const { experience } = this.props.contribution
        // extract editable properties of this step
        const { communicationDomains, hotspots, media } = experience
        return (
            <div>
                <div className="card">
                    <h6 className="card-header">{ t('contribute.steps.interaction.header') }</h6>
                    <div className="card-body">
                        <fieldset className="form-group">
                            <div className="row">
                                <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.interaction.label.communicationdomains') }</legend>
                                <div className="col-md-9">
                                    { categorization.communicationDomain().map( (it, i) => 
                                        <div key={it.id} className=" form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id={ 'communicationDomain' + it.id } name="communicationDomains" 
                                                    value={ it.id } checked={communicationDomains.some( (cd) => cd.id === it.id )} onChange={this.handleInputChange} />
                                            <label className="form-check-label" htmlFor={ 'communicationDomain' + it.id }>{ it.label }</label>
                                        </div>
                                    ) }
                                </div>
                            </div>
                        </fieldset>
                        <div className="row form-group">
                            <legend className="col-md-3 col-form-label pt-0">{ t('contribute.steps.interaction.label.media') }</legend>
                            <div className="col-md-9">
                                { categorization.interactionMedium().map( (it, i) => 
                                    <div key={it.id} className=" form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" id={ 'interactionMedia' + it.id } name="media" 
                                                value={ it.id } checked={media.some( (im) => im.id === it.id )} onChange={this.handleInputChange} />
                                        <label className="form-check-label" htmlFor={ 'interactionMedia' + it.id }>{ it.label }</label>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-3">
                    <h6 className="card-header">{ t('contribute.steps.hotspot.header') }</h6>
                    <div className="card-body">
                        <div className="row form-group">
                            <label className="col-md-3 col-form-label" htmlFor="hotspots">{ t('contribute.steps.hotspot.label.hotspots') }</label>
                            <div className="col-md-9">
                                <MultiValueInput values={hotspots} datalist={categorization.hotspot()} id="hotspots" isRequired={false} 
                                        placeholder={t('contribute.steps.hotspot.placeholder.hotspots')} buttonText={t('contribute.steps.hotspot.action.conclude')} 
                                        ariaRemoveLabel={t('contribute.steps.hotspot.aria.remove')} ariaToggleSuggestions={t('contribute.steps.hotspot.aria.toggle')}
                                        hintText={t('contribute.steps.hotspot.hint.hotspot')}
                                        onValuesChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <BackNextNavigation t={t} navigation={navigation} />
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(StepInteraction), { require: UserRole.AUTHOR } )
