
/** basic react module dependencies */
import React from 'react'

import $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'
import { UserRole } from '../../domain/UserRole'
// navigation + steps components
import StepBasics from './StepBasics'
import StepTitleAndStory from './StepTitleAndStory'
import StepLocation from './StepLocation'
import StepAuthor from './StepAuthor'
import StepActors from './StepActors'
import StepContactDomain from './StepContactDomain'
import StepInteraction from './StepInteraction'
import StepPrivacyPolicy from './StepPrivacyPolicy'
import StepSummary from './StepSummary'

import ConnectorComponent from './ConnectorComponent'

import { Connector, buildRequest } from '../../services/Fetcher'
import { endpoints } from '../../config/endpoints'
import { CategorizationData, ActorTypes } from '../../domain/CategorizationData'
import { MediaType } from '../../domain/MediaType'
import { isAuthorActor, updateContribution, buildContribution, isEmpty, isEmptyDto } from '../../domain/DataTransferObjects'


const StepEnum = {
    privacy: 0,
    basics: 1,
    story: 2,
    location: 3,
    actors: 4,
    contactDomain: 5,
    interaction: 6,
    author: 7,
    checkAndSave: 8
}

export const EditFeedbackEnum = {
    saved: 'saved',
    error: 'error',
    dirty: 'dirty',
    required: 'required'
}

const LocalStorageKey = 'contribution'

/**
 * Provides a wizard with forms to contribute experiences.
 * @since 1.5
 */
export class Contribute extends React.Component {

    constructor(props) {
        super(props)

        // init contribution with empty data or local storage
        const contribution = this.hasUnsavedData() ? this.load() : buildContribution()

        // initialize state
        this.state = {
            connector: new Connector(),
            categorization: undefined,

            // note: we store all data of the experience to be contributed or edited in local storage and sync on save the local storage with the backend
            contribution: contribution,

            // TODO: replace by hasUnsavedData and maybe hadUnsavedEdit
            // flag to indicate unsaved data in local storage and edit experience request as well as the experience to be edited
            hasUnsavedDataEditConflict: this.hasUnsavedData() && this.isEditRequest(),
            hadUnsavedData: this.hasUnsavedData(),
            hadUnsavedEdit: !contribution.experience.id ? false : true,
            pendingEditId: undefined,

            wasValidated: false,
            isSaveEnabled: false,
            isSaveInProgress: false,
            saveProgress: 0,

            // stores errors in case categorization or experience to be edit could not be fetched from backend
            fetchError: [],
            // stores any html message for the use parsed from server responses on error or failure
            serverResponseMessage: null,
            
            // display the summary step as initial step in case data was found in the local storage
            currentStep: this.hasUnsavedData() || this.isEditRequest() ? StepEnum.checkAndSave : StepEnum.privacy,
            // track edit process of steps to give the user feedback which step has changed data, saved data or errorneous data
            stepsFeedback: {},
            
            // note: ui only property as of now only text is supported!
            mediaType: MediaType.Text,
            // ui only property for author step used to switch group of input fields that should be kept when switching between steps
            authorIsActor: isAuthorActor( contribution.actors, contribution.author )
        }

        this.formElement = null

        this.isCurrentStepLeavable = this.isCurrentStepLeavable.bind(this)

        this.onStepChange = this.onStepChange.bind(this)

        this.onExperienceChange = this.onExperienceChange.bind(this)
        this.onTextStoryChange = this.onTextStoryChange.bind(this)
        this.onLocationChange = this.onLocationChange.bind(this)
        this.onActorsChange = this.onActorsChange.bind(this)
        this.onAuthorIsActorChange = this.onAuthorIsActorChange.bind(this)
        this.onAuthorChange = this.onAuthorChange.bind(this)
        this.onReflectionChange = this.onReflectionChange.bind(this)
        this.onRightsOfUseChange = this.onRightsOfUseChange.bind(this)
        
        this.handleClearData = this.handleClearData.bind(this)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.doValidateData = this.doValidateData.bind(this)
        this.doSaveData = this.doSaveData.bind(this)
        this.nextSaveAction = this.nextSaveAction.bind(this)
        this.parseResponseError = this.parseResponseError.bind(this)

        this.isEditRequest = this.isEditRequest.bind(this)
        this.isEditPending = this.isEditPending.bind(this)
        this.fetchCategorization = this.fetchCategorization.bind(this)
        this.fetchExperience = this.fetchExperience.bind(this)
        this.deriveState = this.deriveState.bind(this)
        this.load = this.load.bind(this)
        this.save = this.save.bind(this)
        this.clear = this.clear.bind(this)
        this.hasUnsavedData = this.hasUnsavedData.bind(this)

        this.callbacks = {
            onExperienceChange: this.onExperienceChange,
            onTextStoryChange: this.onTextStoryChange,
            onLocationChange: this.onLocationChange,
            onActorsChange: this.onActorsChange,
            onAuthorIsActorChange: this.onAuthorIsActorChange,
            onAuthorChange: this.onAuthorChange,
            onReflectionChange: this.onReflectionChange,
            onRightsOfUseChange: this.onRightsOfUseChange,

            handleClearData: this.handleClearData,
        }
    }

    /** Returns true if an experience should be edited, otherwise false */
    isEditRequest() {
        const { experienceId } = this.props.match.params
        const result = experienceId !== undefined && Number.isNaN( Number.parseInt( experienceId ) ) === false
        return result
    }

    /** Returns true if an edit is pending, otherwise false. */
    isEditPending() {
        return this.state.pendingEditId !== undefined && this.state.pendingEditId !== null
    }

    /** Fetch data for categorization and editing from server. */
    componentDidMount() {
        // TODO: called both async functions after each other causing us trouble to display all errors that might happen in a consistent way!
        this.fetchCategorization()
        this.fetchExperience()
    }

    /** Abort data fetching from backend or saving to backend. */
    componentWillUnmount() {
        if ( this.state.connector !== null ) this.state.connector.cancel()
    }

    /** Fetch the categorization data from the backend. */
    fetchCategorization() {
        this.state.connector.send(
            buildRequest( endpoints.categorization.url(), endpoints.categorization.method(), true ),
            ( json ) => {
                // async callback function to save the categorization data as part of the state
                const { connector, fetchError } = this.state
                if ( connector.hasJson() && connector.is200() ) {
                    // update state to render active contribute step and perform validation if necessary or possible
                    this.setState( this.deriveState( { categorization: new CategorizationData( json ) } ) )
                } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                    // render occured errors
                    this.setState( { fetchError: fetchError.concat( { code: connector.code(), isCategorization: true } ) } )
                }
            }
        )
    }

    /** Fetch the experience to be edited from the backend. */
    fetchExperience() {
        const experienceId = Number.parseInt( this.props.match.params.experienceId )
        if ( experienceId === undefined || experienceId === null || Number.isNaN( experienceId ) ) return
        this.state.connector.send(
            buildRequest( endpoints.experiences.url( experienceId, true ), endpoints.experiences.method( experienceId, true ), true ),
            ( json ) => {
                // async callback function to save the experience for edit purpose
                const { connector, hasUnsavedDataEditConflict, fetchError } = this.state
                if ( connector.hasJson() && connector.is200() ) {
                    if ( hasUnsavedDataEditConflict === true ) {
                        this.setState( { pendingEditId: experienceId } )
                    } else {
                        this.setState( this.deriveState( { contribution: buildContribution( json ), currentStep: StepEnum.checkAndSave, stepsFeedback: {}, pendingEditId: undefined } ) )
                    }
                } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                    // render occured errors
                    this.setState( { fetchError: fetchError.concat( { code: connector.code(), isExperience: true } ) } )
                }
            }
        )
    }

    deriveState( state ) {
        if ( state === undefined || state === null ) state = {}
        // trigger validation when render step check and save
        const currentStep = state.currentStep ? state.currentStep : this.state.currentStep
        if ( currentStep === StepEnum.checkAndSave ) {
            const contribution = state.contribution ? state.contribution : this.state.contribution
            const categorization = state.categorization ? state.categorization : this.state.categorization
            const stepsFeedback = state.stepsFeedback ? state.stepsFeedback : this.state.stepsFeedback
            const validation = this.doValidateData( contribution, categorization, stepsFeedback )
            Object.assign( state, validation )
        }
        // derive contribute state props
        if ( state.contribution ) {
            state.authorIsActor = isAuthorActor( state.contribution.actors, state.contribution.author )
            state.saveProgress = 0
        }
        return state
    }

    /** Returns the current contribution from local storage or it returns an empty contribution. */
    load() {
        let contribution = buildContribution()
        let current = localStorage.getItem( LocalStorageKey )
        if ( current !== undefined && current !== null ) {
            try {
                // try to use the unsaved data in local storage and update it to the current structure
                contribution = JSON.parse( current )
                contribution = updateContribution( contribution )
            } catch (e) {
                // nothing to do here, just use the freshly baked contribution
            }
        }
        return contribution
    }

    /** Saves the given contribution in the local storage of the webbrowser. */
    save( contribution ) {
        contribution['updated'] = new Date().getTime()
        let serialized = JSON.stringify( contribution )
        localStorage.setItem( LocalStorageKey, serialized )
    }

    /** Returns true if the local storage contains a contribution, otherwise false. */
    hasUnsavedData() {
        return localStorage.getItem( LocalStorageKey ) !== null
    }

    /** Removes the contribution from local storage. */
    clear() {
        localStorage.removeItem( LocalStorageKey )
    }

    /**
     * Removes all data, i. e. empties local storage and replaces the contribution by an empty one, by the pending edit or by the currently edited unchanged fetched from backend.
     * - in case of a pending edit: clear unsaved data in local storage and start editing -> same url, button action
     * - in case of editing: clear unsaved data in local storage and start editing again -> same url, button action
     * - in case of contributing: clear unsaved data in local storage and start contributing again -> same url, button action
     */
    handleClearData() {
        // clear data in local storage
        this.clear()
        // clear component state based on an empty contribution
        this.setState( {
            contribution: buildContribution(), authorIsActor: undefined, currentStep: StepEnum.privacy, 
            wasValidated: false, isSaveEnabled: false, stepsFeedback: {}, 
            pendingEditId: undefined, hasUnsavedDataEditConflict: false, hadUnsavedData: false, hadUnsavedEdit: false,
            saveProgress: 0, serverResponseMessage: null,
        } )
        // in case of editing or pending edit, start editing again
        if ( this.isEditPending() || this.isEditRequest() ) {
            this.fetchExperience()
        }
    }

    /** Perform async calls to backend to save the contribution. If the save was successful the local storage is cleared. */
    handleFormSubmit( event ) {
        event.preventDefault()
        event.stopPropagation()
        // scroll to top (unfortunately we cannot reuse the function of the step wizard here)
        window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } )
        // trigger validation of input data
        const result = this.doValidateData( this.state.contribution, this.state.categorization, this.state.stepsFeedback )
        if ( result.isSaveEnabled === false ) {
            // note: this should not happen because validation is always performed before showing step check and save
            this.setState( result )
            return
        }
        // double validation check succeded, let's save the contribution and always start at the very beginning (see nextSaveAction TODO)
        this.setState( { saveProgress: 0 }, this.doSaveData )
    }

    /** Saves all data transfer objects on the backend one by one. The next save action callbacks ensure this method is called after their successful save. */
    doSaveData() {
        const action = this.nextSaveAction()
        if ( action !== undefined ) {
            // deactivate save button
            this.setState( { isSaveEnabled: false, isSaveInProgress: true, serverResponseMessage: null } )
            this.state.connector.send( action.request, action.callback )
        } else {
            // clear contribution in local storage when save was successful (note: we assume a not empty steps feedback object!)
            const isSavedSuccessfully = Object.entries( this.state.stepsFeedback ).some( ( [key, value] ) => value !== EditFeedbackEnum.saved ) === false
            if ( isSavedSuccessfully ) this.clear()
            // activate save button + reset save progress, disable the unsaved data edit conflict
            this.setState( { isSaveEnabled: true, isSaveInProgress: false, saveProgress: 0, hasUnsavedDataEditConflict: isSavedSuccessfully ? false : this.state.hasUnsavedDataEditConflict } )
        }
    }

    /** Returns the next save action with a data transfer object according the current save progress and the dependency between the data tranfer objects. */
    nextSaveAction() {
        let { saveProgress } = this.state
        // experience
        const { experience } = this.state.contribution
        if ( saveProgress === 0 ) return {
            request: buildRequest( endpoints.experiences.url( experience.id ), endpoints.experiences.method( experience.id ), true, experience ),
            callback: ( json ) => {
                const method = endpoints.experiences.method( experience.id )
                const { connector } = this.state
                if ( ( method === 'POST' || method === 'PUT' ) && connector.hasJson() && connector.is200() ) {
                    // replace experience object with response result
                    const contribution = Object.assign( {}, this.state.contribution, { experience: json } )
                    // assign the experience id to all other data transfer objects
                    if ( method === 'POST' ) {
                        contribution.textStory.experienceId = json.id
                        contribution.location.experienceId = json.id
                        contribution.author.experienceId = json.id
                        contribution.reflection.experienceId = json.id
                        contribution.rightsOfUse.experienceId = json.id
                    }
                    // mark all steps with experience data as saved
                    const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, {
                            [StepEnum.basics]: EditFeedbackEnum.saved, [StepEnum.story]: EditFeedbackEnum.saved, [StepEnum.contactDomain]: EditFeedbackEnum.saved,
                            [StepEnum.interaction]: EditFeedbackEnum.saved
                    } )
                    this.save( contribution )
                    // ensure all data will be saved + the save button will be enabled again
                    this.setState( { contribution: contribution, stepsFeedback: stepsFeedback, saveProgress: saveProgress +1 }, this.doSaveData )
                } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                    // TODO: currently we have no finer control over the error that occured so mark all steps including experience data as errorneous
                    const message = this.parseResponseError( json )
                    // mark all steps with experience data as errorneous
                    const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, {
                            [StepEnum.basics]: EditFeedbackEnum.error, [StepEnum.story]: EditFeedbackEnum.error, [StepEnum.contactDomain]: EditFeedbackEnum.error,
                            [StepEnum.interaction]: EditFeedbackEnum.error
                    } )
                    // render occured error
                    this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                }
            }
        }
        // text story
        if ( saveProgress === 1 ) {
            const { textStory } = this.state.contribution
            return {
                request: buildRequest( endpoints.textStory.url( textStory.experienceId ), endpoints.textStory.method(), true, textStory ),
                callback: ( json ) => {
                    const { connector } = this.state
                    if ( connector.hasJson() && connector.is200() ) {
                        // replace text story object with response result
                        const contribution = Object.assign( {}, this.state.contribution, { textStory: json } )
                        // mark all steps with text story data as saved
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, {
                                [StepEnum.basics]: EditFeedbackEnum.saved, [StepEnum.story]: EditFeedbackEnum.saved
                        } )
                        this.save( contribution )
                        // ensure all data will be saved + the save button will be enabled again
                        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback, saveProgress: saveProgress +1 }, this.doSaveData )
                    } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                        // TODO: currently we have no finer control over the error that occured so mark all steps including text story data as errorneous
                        const message = this.parseResponseError( json )
                        // mark all steps with text story data as errorneous
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, {
                                [StepEnum.basics]: EditFeedbackEnum.error, [StepEnum.story]: EditFeedbackEnum.error
                        } )
                        this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                    }
                }
            }
        }
        // location
        if ( saveProgress === 2 ) {
            const { location } = this.state.contribution
            return {
                request: buildRequest( endpoints.location.url( location.experienceId ), endpoints.location.method(), true, location ),
                callback: ( json ) => {
                    const { connector } = this.state
                    if ( connector.hasJson() && connector.is200() ) {
                        // replace location object with response result
                        const contribution = Object.assign( {}, this.state.contribution, { location: json } )
                        // mark all steps with location data as saved
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.location]: EditFeedbackEnum.saved } )
                        this.save( contribution )
                        // ensure all data will be saved + the save button will be enabled again
                        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback, saveProgress: saveProgress +1 }, this.doSaveData )
                    } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                        const message = this.parseResponseError( json )
                        // mark all steps with location data as errorneous
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.location]: EditFeedbackEnum.error } )
                        this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                    }
                }
            }
        }
        // right of use
        if ( saveProgress === 3 ) {
            const { rightsOfUse } = this.state.contribution
            return {
                request: buildRequest( endpoints.rightsOfUse.url( rightsOfUse.experienceId ), endpoints.rightsOfUse.method(), true, rightsOfUse ),
                callback: ( json ) => {
                    const { connector } = this.state
                    if ( connector.hasJson() && connector.is200() ) {
                        // replace rights of use object with response result
                        const contribution = Object.assign( {}, this.state.contribution, { rightsOfUse: json } )
                        // mark all steps with rights of use data as saved
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.privacy]: EditFeedbackEnum.saved } )
                        this.save( contribution )
                        // ensure all data will be saved + the save button will be enabled again
                        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback, saveProgress: saveProgress +1 }, this.doSaveData )
                    } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                        const message = this.parseResponseError( json )
                        // mark all steps with rights of use data as errorneous
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.privacy]: EditFeedbackEnum.error } )
                        this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                    }
                }
            }
        }
        // author
        if ( saveProgress === 4 ) {
            const { author, authorIsActor } = this.state.contribution
            return {
                request: buildRequest( endpoints.author.url( author.experienceId ), endpoints.author.method(), true, author ),
                callback: ( json ) => {
                    const { connector } = this.state
                    if ( connector.hasJson() && connector.is200() ) {
                        // replace author object with response result
                        // TODO: this is just a workaround: because the actors are saved last the returned author might has a different isActor value as the backend 
                        //  is at this time unable to determine it correctly (in case isActor is true but no actor is saved yet)
                        const author = Object.assign( {}, json, { isActor: authorIsActor } )
                        const contribution = Object.assign( {}, this.state.contribution, { author: author } )
                        // mark all steps with author data as saved
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.author]: EditFeedbackEnum.saved } )
                        this.save( contribution )
                        // ensure all data will be saved + the save button will be enabled again
                        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback, saveProgress: saveProgress +1 }, this.doSaveData )
                    } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                        const message = this.parseResponseError( json )
                        // mark all steps with author data as errorneous
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.author]: EditFeedbackEnum.error } )
                        this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                    }
                }
            }
        }
        // reflection: is optional and if contributor is author of experience or admin, it should not be empty!
        if ( saveProgress === 5 ) {
            const { author, reflection } = this.state.contribution
            if ( ( author.isContributor !== true && this.props.authService.isAdmin() === false ) || ( isEmpty( reflection.reflection ) && isEmpty( reflection.keywords ) ) ) {
                // simply continue with actors without saving reflection
                ++saveProgress
            } else return {
                request: buildRequest( endpoints.reflection.url( reflection.experienceId ), endpoints.reflection.method(), true, reflection ),
                callback: ( json ) => {
                    const { connector } = this.state
                    if ( connector.hasJson() && connector.is200() ) {
                        // replace reflection object with response result
                        const contribution = Object.assign( {}, this.state.contribution, { reflection: json } )
                        // mark all steps with author data as saved
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.author]: EditFeedbackEnum.saved } )
                        this.save( contribution )
                        // ensure all data will be saved + the save button will be enabled again
                        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback, saveProgress: saveProgress +1 }, this.doSaveData )
                    } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                        const message = this.parseResponseError( json )
                        // mark all steps with author data as errorneous
                        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.author]: EditFeedbackEnum.error } )
                        this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                    }
                }
            }
        }
        // actors
        if ( saveProgress >= 6 ) {
            // add actors: determine index of next actor and grab it
            const { actors } = this.state.contribution
            const index = saveProgress >= 6 && saveProgress -6 < actors.length ? saveProgress -6 : actors.length
            if ( index < actors.length ) {
                const actor = actors[ saveProgress -6 ]
                    return {
                    request: buildRequest( endpoints.actors.url( experience.id, actor.id ), endpoints.actors.method( actor.id ), true, actor ),
                    callback: ( json ) => {
                        const { connector } = this.state
                        if ( connector.hasJson() && connector.is200() ) {
                            // create or update actor was successful, replace the actor by the response result
                            const updated = actors.map( (it, i) => i === index ? json : it )
                            const contribution = Object.assign( {}, this.state.contribution, { actors: updated } )
                            this.save( contribution )
                            // note: step feedback update is done when all actors are synced, see below
                            // ensure all data will be saved + step feedback is given + the save button will be enabled again
                            this.setState( { contribution: contribution, saveProgress: saveProgress +1 }, this.doSaveData )
                        } else if ( connector.hasError() || ( connector.hasResponse() && !connector.is200() ) ) {
                            const message = this.parseResponseError( json )
                            // mark just the actor step data as errorneous, it wouldn't help the user to mark the author step errorneous in case the actor is the author
                            const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.actors]: EditFeedbackEnum.error } )
                            this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                        }
                    }
                }
            }
            // remove actors
            const { removableActors } = this.state.contribution
            const hasRemovableActors = removableActors.length > 0
            if ( hasRemovableActors === true ) {
                // note: we always remove the first item in the array till its empty!
                const actorId = removableActors[0]
                return {
                    request: buildRequest( endpoints.actors.url( experience.id, actorId ), 'DELETE', true, undefined ),
                    callback: (json) => {
                        const { connector } = this.state
                        if ( connector.hasResponse() && connector.is200() ) {
                            const updated = removableActors.splice( 1 )
                            const contribution = Object.assign( {}, this.state.contribution, { removableActors: updated } )
                            this.save( contribution )
                            // note: step feedback update is done when all actors are synced, i. e. added and removed, see below
                            // ensure all data will be saved + step feedback is given + the save button will be enabled again
                            this.setState( { contribution: contribution }, this.doSaveData )
                        } else if ( connector.hasError() || connector.hasResponse() ) {
                            const message = this.parseResponseError( json )
                            // mark just the actor step data as errorneous, it wouldn't help the user to mark the author step errorneous in case the actor is the author
                            // TODO: this is NOT helpful for the user, as she can't change there anything about removed users!
                            const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.actors]: EditFeedbackEnum.error } )
                            this.setState( { isSaveEnabled: true, isSaveInProgress: false, stepsFeedback: stepsFeedback, serverResponseMessage: message } )
                        }
                    }
                }
            }
            // step feedback update for actors
            if ( actors.length > 0 && index === actors.length && hasRemovableActors === false ) {
                // mark all steps with actor data as errorneous
                const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, 
                        { [StepEnum.actors]: EditFeedbackEnum.saved },
                        isAuthorActor === false ? {} : { [StepEnum.author]: EditFeedbackEnum.saved }
                )
                this.setState( { stepsFeedback: stepsFeedback } )
            }
        }
        return undefined
    }

    /** Returns an html error message description to be displayed below an error alert constructed from the server response or null. */
    parseResponseError( json ) {
        const { t } = this.props
        const { connector } = this.state
        if ( json === undefined || json === null || connector.isOk() === true || connector.hasJson() === false ) {
            return null
        }
        // note: the json returned from backend validtors is as follows: key specifies dto and property, value is localized error message
        let lastTerm = null
        const entries = []
        Object.entries( json ).forEach( ( [key, value] ) => {
            // TODO: check what is returned in case of reference (localized) item dtos
            const term = String(key).startsWith( 'experience' ) ? t('contribute.responseBodyMapping.experience') : 
                    String(key).startsWith( 'textStory' ) ? t('contribute.responseBodyMapping.textStory') : 
                    String(key).startsWith( 'actor' ) ? t('contribute.responseBodyMapping.actor') : 
                    String(key).startsWith( 'location' ) ? t('contribute.responseBodyMapping.location') : 
                    String(key).startsWith( 'author' ) ? t('contribute.responseBodyMapping.author') :
                    String(key).startsWith( 'rightOfUse' ) ? t('contribute.responseBodyMapping.rightOfUse') : ""
            if ( lastTerm !== term ) {
                entries.push( <dt key={term} className="">{ term }</dt> )
                lastTerm = term
            }
            entries.push( <dd key={value} className="mb-0" style={ { paddingLeft: "3rem"} }>{ value }</dd> )
        } )
        if ( entries.length === 0 ) return null
        return <dl className="">{ entries }</dl>
    }

    /** Returns true if the form is valid, otherwise false. */
    // isFormValid( isCheckingRequiredFields ) {
    //     // TODO: validation is only triggered when the user tries to save her experience! -> do we need form validation at all?
    //     // TODO: here we need more control over the currently active step! especially steps with required multi value input to check if there are any values!
    //     const isFormValid = this.formElement.checkValidity()
    //     const state = { wasValidated: isFormValid === false ? true : false }
    //     // TODO: check the privacy policy here is a little hack, we need a more robust design for doing field level validation!
    //     let isPrivacyPolicyAccepted = true
    //     if ( isCheckingRequiredFields === true ) {
    //         const privacy_accept = this.state.categorization.rightOfUse()[0]
    //         isPrivacyPolicyAccepted = this.state.rightsOfUse.grants.some( (it) => it.id === privacy_accept.id )
    //         state.wasValidated = ( isFormValid && isPrivacyPolicyAccepted ) === true ? false : true
    //         if ( isPrivacyPolicyAccepted === false ) state.stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [StepEnum.privacy]: EditFeedbackEnum.required } )
    //     }
    //     this.setState( state )
    //     return isCheckingRequiredFields === true ? isFormValid && isPrivacyPolicyAccepted : isFormValid
    // }

    /** Validates the input data and if valid enables the save button to store input data in backend. */
    doValidateData( contribution, categorization, stepsFeedbackOld ) {
        const stepsFeedback = stepsFeedbackOld === undefined || stepsFeedbackOld === null ? {} : Object.assign( {}, stepsFeedbackOld )
        if ( contribution === undefined || contribution === null || categorization === undefined || categorization === null ) return stepsFeedback

        const { experience, textStory, rightsOfUse } = contribution

        // admin: origin + title are necessary + privacy policy must be accepted
        if ( isEmptyDto( experience, [], ['origin'] ) ) {
            stepsFeedback[ StepEnum.basics ] = EditFeedbackEnum.required
        }
        if ( isEmptyDto( experience, ['title'] ) ) {
            stepsFeedback[ StepEnum.story ] = EditFeedbackEnum.required
        }
        if ( categorization.isPrivacyPolicyAccepted( rightsOfUse.grants ) === false ) {
            stepsFeedback[ StepEnum.privacy ] = EditFeedbackEnum.required
        }

        // author + researcher: check mandatory fields
        if ( !this.props.authService.isAdmin() ) {
            const { location, actors, author } = contribution
            // step basics: origin is already checked above
            // note: author is contributor should never be empty/null/undefined as it is initialized with false
            if ( isEmptyDto( textStory, [], ['kind'], ['languages'] ) || isEmpty( author.isContributor ) ) {
                stepsFeedback[ StepEnum.basics ] = EditFeedbackEnum.required
            }
            // step title + story: title is already checked above
            if ( isEmptyDto( textStory, ['story'], ['perspective'] ) ) {
                stepsFeedback[ StepEnum.story ] = EditFeedbackEnum.required
            }
            // step location
            const isCountryRequiredAndEmpty = categorization.isCountryRequired( location.place ) && isEmpty( location, [], [], ['country'] )
            if ( isEmptyDto( location, [], ['place'] ) || isCountryRequiredAndEmpty ) {
                stepsFeedback[ StepEnum.location ] = EditFeedbackEnum.required
            }
            // step actors
            if ( actors.length === 0 || actors.some( (it) =>
                    isEmptyDto ( it.type, ['id'] )
                    || ( categorization.isActorType( it.type, ActorTypes.Human ) && isEmptyDto( it, ['minAge', 'maxAge'], ['gender'], ['nationalities', 'languages'] ) )
                    || ( categorization.isActorType( it.type, ActorTypes.System ) && isEmptyDto( it, [], [], ['systemSpecification', 'languages'] ) )
                    || ( categorization.isActorType( it.type, ActorTypes.Group ) && isEmptyDto( it, ['minAge', 'maxAge', 'extendOfGroup'], ['familiarityLevel'], ['nationalities', 'languages'] ) )
                    || ( categorization.isActorType( it.type, ActorTypes.Group ) && !( it.isMixedGender === true ) && isEmpty( it.gender, ['id'] ) )
                ) ) {
                stepsFeedback[ StepEnum.actors ] = EditFeedbackEnum.required
            }
            // step contact domain
            if ( isEmptyDto( experience, [], [], ['contactDomains']) ) {
                stepsFeedback[ StepEnum.contactDomain ] = EditFeedbackEnum.required
            }
            // step author
            const contributorIsAuthor = author.isContributor
            const authorIsActor = isAuthorActor( actors, author )
            if ( isEmpty( authorIsActor )
                    || ( contributorIsAuthor === true && authorIsActor === false && isEmptyDto( author, ['minAge', 'maxAge'], ['gender'], ['nationalities'] ) ) 
                    || ( authorIsActor === true && actors.some( it => it.isAuthor === true ) === false ) ) {
                stepsFeedback[ StepEnum.author ] = EditFeedbackEnum.required
            }
        }
        return {
            stepsFeedback: stepsFeedback,
            isSaveEnabled: Object.values( stepsFeedback ).some( (it) => it === EditFeedbackEnum.required ) ? false : true,
            wasValidated: true
        }
    }

    /** Callback function from navigation to check if the currently active step could be left or not. */
    isCurrentStepLeavable() {
        // note: the user should be able to freely navigate in between all steps
        return true
    }

    /** Callback function from the step wizard navigation to obtain the current step. */
    onStepChange( lastStep, activeStep ) {
        // triggers validation automatically when rendering the check and save step
        this.setState( this.deriveState( { currentStep: activeStep } ) )
    }

    /** Callback function to update the experience. */
    onExperienceChange( experience ) {
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: EditFeedbackEnum.dirty } )
        const contribution = Object.assign( {}, this.state.contribution, { experience: experience } )
        this.save( contribution )
        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback } )
    }

    /** Callback function to update the text story. */
    onTextStoryChange( textStory ) {
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: EditFeedbackEnum.dirty } )
        const contribution = Object.assign( {}, this.state.contribution, { textStory: textStory } )
        this.save( contribution )
        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback } )
    }

    /** Callback function to update the location. */
    onLocationChange( location ) {
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: EditFeedbackEnum.dirty } )
        const contribution = Object.assign( {}, this.state.contribution, { location: location } )
        this.save( contribution )
        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback } )
    }

    /** Callback function update the actors. */
    onActorsChange( actors, removedActorIndex ) {
        // keep id of persistent actors which should be removed
        let isAuthor = false
        const removableActors = this.state.contribution.removableActors.slice()
        if ( this.state.contribution.actors.length > actors.length ) {
            const actor = this.state.contribution.actors[removedActorIndex]
            const id = actor !== undefined ? actor.id : undefined
            if ( id !== undefined ) removableActors.push( id )
            if ( actor !== undefined && actor.isAuthor === true ) isAuthor = true
        }
        // mark the current edit step as dirty
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: EditFeedbackEnum.dirty } )
        // in case removed actor was author, mark author step as dirty too
        if ( isAuthor === true ) Object.assign( stepsFeedback, { [StepEnum.author]: EditFeedbackEnum.dirty } )
        const contribution = Object.assign( {}, this.state.contribution, { actors: actors, removableActors: removableActors } )
        this.save( contribution )
        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback } )
    }

    /** Callback function to save the ui only property when switching between steps. */
    onAuthorIsActorChange( authorIsActor, actors, author ) {
        // note: we do NOT reset author information when switching author is actor on to prevent data loss when switching unintended
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: EditFeedbackEnum.dirty } )
        const contribution = Object.assign( {}, this.state.contribution, { actors: actors }, { author: author } )
        this.save( contribution )
        this.setState( { contribution: contribution, authorIsActor: authorIsActor, stepsFeedback: stepsFeedback } )
    }

    /** Callback function to update the author. */
    onAuthorChange( author ) {
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: EditFeedbackEnum.dirty } )
        const contribution = Object.assign( {}, this.state.contribution, { author: author } )
        this.save( contribution )
        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback } )
    }

    /** Callback function to update the authors reflection. */
    onReflectionChange( reflection ) {
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: EditFeedbackEnum.dirty } )
        const contribution = Object.assign( {}, this.state.contribution, { reflection: reflection } )
        this.save( contribution )
        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback } )
    }

    /** Callback function to update the rights of use. */
    onRightsOfUseChange( rightsOfUse ) {
        const privacy_accept = this.state.categorization.rightOfUse()[0]
        const isPrivacyPolicyAccepted = rightsOfUse.grants.some( (it) => it.id === privacy_accept.id )
        const stepsFeedback = Object.assign( {}, this.state.stepsFeedback, { [this.state.currentStep]: isPrivacyPolicyAccepted ? EditFeedbackEnum.dirty : EditFeedbackEnum.required } )
        const contribution = Object.assign( {}, this.state.contribution, { rightsOfUse: rightsOfUse } )
        this.save( contribution )
        this.setState( { contribution: contribution, stepsFeedback: stepsFeedback } )
    }

    render() {
        const { t, authService } = this.props
        const {
            connector, categorization, contribution, mediaType, authorIsActor, 
            wasValidated, currentStep, stepsFeedback, isSaveEnabled, serverResponseMessage, 
            hadUnsavedData, hadUnsavedEdit, fetchError, isSaveInProgress
        } = this.state
        const stepProps = {
            t: t,
            authService: authService,
            categorization: categorization,
            contribution: contribution,
            callbacks: this.callbacks,
            wasValidated: wasValidated
        }
        // TODO: we need a better notification concept
        // const fetchErrorMessages = <ul className="list-group">{ fetchError.map( (it, i) =>
        //     <li key={i} className="list-group-item list-group-item-danger"><strong>{it.code} </strong> {it.isCategorization === true ? 'Categorization' : 'Experience' }</li>
        // ) }</ul>
        // TODO: this is just a hacky solution, better would be to refactor connector component to be able to pass the error message to be displayed
        const fetchErrorMessages = fetchError.map( (it, i) =>
            <div key={i} className=""><strong>{t('connector.connection.errorWithCode', { code: it.code } )} </strong>
                { it.isCategorization === true ? t('contribute.notification.categorization') : 
                    it.code === 404 ? t('contribute.notification.experience.404') : t('contribute.notification.experience.any') }
            </div>
        )
        const messageDetails = fetchError.length > 0 ? fetchErrorMessages : serverResponseMessage
        const formCss = wasValidated === true ? "was-validated" : ""
        return (
            <div className='cisComponent'>
                <div className="container-fluid">
                    <div className="cisComponentHeader">
                        <div className="row">
                            <div className="col">
                                <h1>{t('contribute.header')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="cisComponentBody">
                        <ConnectorComponent connector={connector} details={messageDetails} isPending={isSaveInProgress} isRenderChildren={ (connector) => categorization !== undefined && fetchError.length === 0 }>
                            <form ref={ (form) => this.formElement = form } onSubmit={ this.handleFormSubmit } noValidate className={formCss}>
                                <StepWizard t={t} currentStep={currentStep} isActiveStepLeavable={this.isCurrentStepLeavable} onStepChange={this.onStepChange} stepsFeedback={stepsFeedback}>
                                    <StepPrivacyPolicy title='contribute.navigation.steps.privacy' {...stepProps} />
                                    <StepBasics title='contribute.navigation.steps.basics' mediaType={mediaType} {...stepProps} />
                                    <StepTitleAndStory title='contribute.navigation.steps.titleAndStory' {...stepProps} />
                                    <StepLocation title='contribute.navigation.steps.location' {...stepProps} />
                                    <StepActors title='contribute.navigation.steps.actors' {...stepProps} />
                                    <StepContactDomain title='contribute.navigation.steps.contactdomain' {...stepProps} />
                                    <StepInteraction title='contribute.navigation.steps.interaction' {...stepProps} />
                                    <StepAuthor title='contribute.navigation.steps.author' authorIsActor={authorIsActor} {...stepProps} />
                                    <StepSummary title='contribute.navigation.steps.summary'
                                            isEditPending={this.isEditPending()} isEditRequest={this.isEditRequest()} hadUnsavedData={hadUnsavedData} hadUnsavedEdit={hadUnsavedEdit}
                                            authorIsActor={authorIsActor} {...stepProps} 
                                            isSaveEnabled={isSaveEnabled} isSaveInProgress={isSaveInProgress} stepsFeedback={stepsFeedback} />
                                </StepWizard>
                            </form>
                        </ConnectorComponent>
                    </div>
                </div>
            </div>
        )
    }
}

export class StepWizard extends React.Component {
    constructor( props ) {
        super( props )
        const currentStep = this.props.currentStep < this.props.children.length ? this.props.currentStep : 0
        this.state = {
            activeStep: currentStep,
            steps: this.steps(),
            scrollToTop: false
        }

        this.doScrollToTop = this.doScrollToTop.bind( this )
        this.steps = this.steps.bind( this )
        this.goToStep = this.goToStep.bind( this )
        this.previousStep = this.previousStep.bind( this )
        this.nextStep = this.nextStep.bind( this )
        this.hasPreviousStep = this.hasPreviousStep.bind( this )
        this.hasNextStep = this.hasNextStep.bind( this )
    }

    /** In case the contribute component changes the current step after construction, adjust the state. */
    static getDerivedStateFromProps( props, state ) {
        if ( props.currentStep === state.activeStep ) return null
        return Object.assign( {}, state, { activeStep: props.currentStep } )
    }

    componentDidUpdate() {
        if ( this.state.scrollToTop === true ) {
            this.doScrollToTop()
            this.setState( { scrollToTop: false } )
        }
    }

    doScrollToTop() {
        // just use the function of the window object!
        window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } )
    }

    steps() {
        const { t } = this.props
        return React.Children.map( this.props.children, ( child, i ) => {
            return {
                id: "step" + i,
                title: t(child.props.title)
            }
        } )
    }

    goToStep( index ) {
        if ( !Number.isInteger( index ) ) return
        if ( index < 0 && index >= this.props.children.length ) return
        if ( index === this.state.activeStep ) return
        if ( this.props.isActiveStepLeavable() === false ) return
        const activeStep = this.state.activeStep
        this.setState( {
            activeStep: index,
            steps: this.steps(),
            scrollToTop: true
        } )
        this.props.onStepChange( activeStep, index )
    }

    previousStep() {
        this.goToStep( this.state.activeStep -1 )
    }

    nextStep() {
        this.goToStep( this.state.activeStep +1 )
    }

    hasPreviousStep() {
        return this.state.activeStep > 0
    }

    hasNextStep() {
        return this.state.activeStep < this.props.children.length -1
    }

    render() {
        const { t, stepsFeedback } = this.props
        const navigationProps = {
            goToStep: this.goToStep,
            previousStep: this.previousStep,
            nextStep: this.nextStep,
            hasPreviousStep: this.hasPreviousStep,
            hasNextStep: this.hasNextStep,
            scrollToTop: this.doScrollToTop,
            currentStep: this.state.activeStep,
            steps: this.state.steps
        }
        // note: instead of rendering all steps and hide all but the active, we just render the active step
        const activeStepIndex = this.state.activeStep
        const activeStep = React.cloneElement( this.props.children[ activeStepIndex ], {navigation: navigationProps} )
        return (
            <div className="row">
                <div className="col col-12 col-md-3 pb-3">
                    <DirectStepNavigation t={t} navigation={navigationProps} stepsFeedback={stepsFeedback} />
                </div>
                <div className="col col-12 col-md-9">
                    { activeStep }
                </div>
            </div>
        )
    }
}

class DirectStepNavigation extends React.Component {
    componentDidMount() {
        // TODO: decide to use a react popper or tooltip library or build an own react component or externalize these functions?
        // use jquery to turn on popper tooltips from bootstrap
        $('[data-toggle="tooltip"]').tooltip()
    }

    componentWillUnmount() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip('dispose')
        // use jquery to turn on popper tooltips from bootstrap
        $('[data-toggle="tooltip"]').tooltip()
    }

    render() {
        const { t, navigation, stepsFeedback } = this.props
        const entries = this.props.navigation.steps.map( ( step, i ) => {
            let buttonCss = "btn btn-light btn-block" + ( navigation.currentStep === i ? " active" : "")
            let settings = stepsFeedback[i] === EditFeedbackEnum.dirty
                    ? { css: "float-right text-primary", icon: <FontAwesomeIcon icon={ ['far', 'edit'] } />, tooltip: t('contribute.navigation.tooltips.edited') }
                    : stepsFeedback[i] === EditFeedbackEnum.error
                    ? { css: "float-right text-danger", icon: <FontAwesomeIcon icon="exclamation-triangle" />, tooltip: t('contribute.navigation.tooltips.error') }
                    : stepsFeedback[i] === EditFeedbackEnum.saved
                    ? { css: "float-right text-success", icon: <FontAwesomeIcon icon={ ['far', 'check-square'] } />, tooltip: t('contribute.navigation.tooltips.saved') }
                    : stepsFeedback[i] === EditFeedbackEnum.required
                    ? { css: "float-right text-danger", icon: <FontAwesomeIcon icon={ ['far', 'hand-point-left'] } />, tooltip: t('contribute.navigation.tooltips.required') }
                    : undefined
            // note: firefox somehow don't show the tooltip when attached to the icon thus we attach them to the button
            let icon = settings === undefined ? null : <span className={settings.css}>{settings.icon}</span>
            return <button key={i} className={buttonCss} type="button" onClick={ () => navigation.goToStep(i) } aria-expanded="false" aria-controls={step.id}
                        title={settings === undefined ? null : settings.tooltip} data-toggle="tooltip" data-placement="right">
                    <span>{ step.title }&nbsp;</span>
                    { icon }
                </button>
        } )
        return (
            <div className="btn-toolbar" role="toolbar" aria-label={ t('contribute.navigation.aria.btntoolbar.vertical') }>
                {entries}
            </div>
        )
    }
}

export default AuthHoc( LocalizableHoc()(Contribute), { require: UserRole.AUTHOR } )
