/**
 * basic react module dependencies
 */
import React from 'react'
import { Redirect } from 'react-router-dom'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'
import { AuthHoc } from '../../services/AuthService'
import {UserRole} from '../../domain/UserRole'

/**
 * custom cis related module dependencies
 */
import InterculturalExperienceList from './InterculturalExperienceList'


/**
 * the component provides the functionality to contain various data related components for managing intercultural experiences
 * 
 * @version 0.1.0
 * @since 1.2.0
 */
export class DataManagement extends React.Component {

  /**
   * Default constructor for the component
   * 
   * @param {*} props properties from the parent component
   */
  constructor(props) {
    super(props)
    this.state = {
      user: {
        role: ''
      }
    }
  }

  /**
   * updates the user object in the component state (after authorization [login/logout])
   * 
   * @param {*} user the (updated) user object, which arrives from the parent component
   * @public
   */
  updateUser(user) {
    if (user !== undefined) {
      if (user.role !== this.state.user.role) {
        this.setState({ user: user })
      }
    }
  }


  /**
   * checks if the current user is authorized to see the component content
   * @public
   */
  isUserAuthorized() {
    const userRole = this.state.user.role
    if (userRole === 'GUEST' || userRole === '') {
      return true
    }
    else {
      return false
    }
  }

  /**
   * automatically invoked before the component receives new props
   * 
   * @param {*} newProps new properties from a parent component
   */
  componentWillReceiveProps(newProps) {
    this.updateUser(newProps.user)
  }

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {

    const { t } = this.props

    if (!this.isUserAuthorized()) {
      return (
        <Redirect to='/' />
      )
    }

    return (
      <div className='component-main'>
        <div className='component-sub'>
          <h1>{t('dataManagement.header')}</h1>
          <hr />
          <InterculturalExperienceList currentUser={this.state.currentUser} />
        </div>
      </div>
    )
  }
}

export default AuthHoc( LocalizableHoc()(DataManagement), { require: UserRole.ADMIN } )
