import React from 'react'

// services: authentication + authorization, language + translation service
import { LocalizableHoc } from '../../services/LanguageService'


class BackNextNavigation extends React.Component {
    button = ( isActivated, clickAction, localizationKey ) => {
        const { t } = this.props
        if ( isActivated === true ) {
            return <button type="button" className="btn btn-secondary" onClick={ () => clickAction() }>{ t(localizationKey) }</button>
        } else {
            return <button type="button" className="btn btn-secondary" disabled onClick={ () => clickAction() }>{ t(localizationKey) }</button>
        }
    }

    render() {
        const { t, navigation, localStepNavigationButtons } = this.props
        return (
            <div className="btn-toolbar justify-content-between mt-2" role="toolbar" aria-label={ t('contribute.navigation.aria.btntoolbar.horizontal') }>
                <div className="btn-group" role="group" aria-label={ t('contribute.navigation.aria.btngroup.local') }>
                    { localStepNavigationButtons === undefined ? null : localStepNavigationButtons }
                </div>
                <div className="btn-group" role="group" aria-label={ t('contribute.navigation.aria.btngroup.backnext') }>
                    { this.button( navigation.hasPreviousStep(), navigation.previousStep, 'contribute.navigation.action.back' ) }
                    { this.button( navigation.hasNextStep(), navigation.nextStep, 'contribute.navigation.action.next' ) }
                </div>
            </div>
        )
    }
}

export default LocalizableHoc()(BackNextNavigation)
