/**
 * basic react module dependencies
 */
import React from 'react'

// language + translation service
import { LocalizableHoc } from '../services/LanguageService'

/**
 * the component provides the functionality to show project and system related contact information.
 * 
 * @version 1.0.0
 * @since 1.2.0
 */
export class Contact extends React.Component {

  /**
   * main function to render the component elements (into the parent component or root DOM)
   */
  render() {
    const { t } = this.props

    return (
      <div className="cis-contact">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <h4>{t('contact.header')}</h4>
              <a href="mailto:nils@fh-zwickau.de">nils@fh-zwickau.de</a>
            </div>
            <div className="col-8">
              <h4><small>Prof. Dr. phil. Doris Fetscher ({t('contact.supervisor')})</small></h4>
              <a href="mailto:Doris.Fetscher@fh-zwickau.de">Doris.Fetscher@fh-zwickau.de</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LocalizableHoc()(Contact)