/**
 * Link.js
 * @description Provides components wrapping the link component of the react router dom library with the auth hoc to display links only if the user has sufficient permissions.
 * @since 2.0
 */

import { Link } from 'react-router-dom'

// services: authentication + authorization
import { AuthHoc } from '../services/AuthService'
import { UserRole } from '../domain/UserRole'

// note: the link component seams to pass all unknown properties as custom properties to the dom element, thus we have to disable the injection of the auth service

/** A component wrapping the link component to render links for users with guest permissions. */
export const Link4Guest = AuthHoc( Link, { require: UserRole.GUEST, injectAuthService: false, hasEmptyReplacement: true } )

/** A component wrapping the link component to render links for users with author permissions, for guest users nothing is rendered. */
export const Link4Author = AuthHoc( Link, { require: UserRole.AUTHOR, injectAuthService: false, hasEmptyReplacement: true } )

/** A component wrapping the link component to render links for users with admin permissions, for guest and authors nothing is rendered.  */
export const Link4Admin = AuthHoc( Link, { require: UserRole.ADMIN, injectAuthService: false, hasEmptyReplacement: true } )
